<script>
import H1Component from "@/components/ui/H1Component.vue";
import DividerComponent from "@/components/ui/DividerComponent.vue";
import TableComponent from "@/components/ui/TableComponent.vue";
import AddButtonComponent from "@/components/ui/AddButtonComponent.vue";
import FaqModal from "@/components/faq/modals/FaqModal.vue";
export default {
  data() {
    return {
      isLoading: true,
      editObject: null,
      isShowFaqModal: false,
      columns: [
        {
          label: 'Вопрос',
          key: 'question',
        },
        {
          label: 'Ответ',
          width: 3,
          styles: {
            'font-size': '12px'
          },
          key: 'answer',
        },
        {
          label: '',
          key: 'actions'
        }
      ],
      faqs: [],
    }
  },
  methods: {
    async goDelete(item) {
      const id = item.id;

      const confirmed = await this.$root.$confirm("Вы уверены, что хотите удалить пункт FAQ?");

      if (!confirmed) return;

      this.$axios.delete('faqs/' + id).then(() => {

        const index = this.faqs.findIndex(item => item.id == id);

        if (index > -1)  {
          this.faqs.splice(index, 1);
        }

        this.$root.$emit('success', {
          title: "Уведомление",
          text: "FAQ пункт успешно удален"
        });
      }).catch(errors => {
        if (Array.isArray(errors)) {
          for (const item in errors) {
            this.$root.$emit('error', {
              title: "Ошибка",
              text: errors[item]
            });
          }
        } else {
          this.$root.$emit('error', {
            title: "Ошибка",
            text: "Возникла ошибка, попробуйте позже."
          });
        }
      })
    },
    fetch() {
      this.$axios.get('faqs').then(response => {
        this.faqs = response.data;
      }).finally(() => {
        setTimeout(() => {
          this.isLoading = false;
        }, 100)
      })
    }
  },
  mounted() {
    this.fetch();
  },
  components: {
    AddButtonComponent,
    FaqModal,
    TableComponent, DividerComponent, H1Component
  }
}
</script>

<template>
  <div class="page users">
    <div class="top">
      <H1Component text="FAQ" />

      <AddButtonComponent @click="isShowFaqModal = true" text="Добавить" />
    </div>

    <DividerComponent />

    <div class="tbl">
      <TableComponent
          :columns="columns"
          :items="faqs"
          :deletable="true"
          :editable="true"
          :isLoading="isLoading"
          @edit="editObject = $event, isShowFaqModal = true"
          @delete="goDelete"
      />
    </div>
    <DividerComponent />

    <FaqModal :object="editObject" @update="isShowFaqModal = false, editObject = null, fetch()" @close="isShowFaqModal = false, editObject = null" v-if="isShowFaqModal" />
  </div>
</template>

<style scoped lang="scss">
.tbl {
  width: 100%;
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  ::v-deep {
    .table__body {
      min-width: 800px;
    }
  }
}
.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
}
.top {
  display: flex;
  justify-content: space-between;
  align-items: center;

}
.filters {
  display: flex;
  grid-gap: 16px;
}
</style>