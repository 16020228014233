<script>
import InputComponent from "@/components/ui/InputComponent.vue";

export default {
  data() {
    return {
      form: {
        name: null,
        slug: null,
        region: null,
        tzid: null,
        population: null,
        lat: null,
        lon: null,
        namecase: {
          nominative: null,
          genitive: null,
          dative: null,
          accusative: null,
          prepositional: null,
          locative: null,
          ablative: null,
        }
      }
    }
  },
  methods: {
    save() {
      this.$axios.post('cities', this.form).then((response) => {
        this.$root.$emit('error', {
          title: "Уведомление",
          text: "Город успешно добавлен."
        });

        this.$emit('add', response.data.data);
        this.$emit('close')
      }).catch(errors => {
        if (Array.isArray(errors)) {
          for (const item in errors) {
            this.$root.$emit('error', {
              title: "Ошибка",
              text: errors[item]
            });
          }
        } else {
          this.$root.$emit('error', {
            title: "Ошибка",
            text: "Возникла ошибка, попробуйте позже."
          });
        }
      })
    }
  },
  computed: {
    isFilled() {
      const form = this.form;

      // Проверка всех верхних уровней
      const upperLevelNotNull = form.name !== null &&
          form.slug !== null &&
          form.region !== null &&
          form.tzid !== null &&
          form.population !== null;

      // Проверка значений внутри объекта namecase
      const namecaseNotNull = form.namecase.nominative !== null &&
          form.namecase.genitive !== null &&
          form.namecase.dative !== null &&
          form.namecase.accusative !== null &&
          form.namecase.prepositional !== null &&
          form.namecase.locative !== null &&
          form.namecase.ablative !== null;

      return upperLevelNotNull && namecaseNotNull;
    }
  },
  components: {
    CollapseComponent,
    InputComponent,
    DividerComponent,
  }
}
import DividerComponent from "@/components/ui/DividerComponent.vue";
import CollapseComponent from "@/components/ui/CollapseComponent.vue";
</script>

<template>
  <form @submit.prevent.stop="save" class="city-modal">
    <div class="modal-title">
      Добавить город
    </div>

    <DividerComponent/>

    <InputComponent :required="true" v-model="form.name" label="Наименование" placeholder="Введите название города" />

    <DividerComponent size="16"/>

    <InputComponent :required="true" v-model="form.slug" label="Slug (для url)" placeholder="Введите slug латинскими буквами" />

    <DividerComponent size="16"/>

    <InputComponent :required="true" v-model="form.region" label="Регион" placeholder="Введите регион" />

    <DividerComponent size="16"/>

    <InputComponent :required="true" v-model="form.tzid" label="Timezone" placeholder="Укажите timezone" />

    <DividerComponent size="16"/>

    <InputComponent type="number" :required="true" v-model="form.population" label="Население" placeholder="Укажите население" />

    <DividerComponent size="16"/>

    <div class="group">
      <InputComponent :required="true" v-model="form.lat" pattern="\[[+-]?[0-9]*\.?[0-9]+,[+-]?[0-9]*\.?[0-9]+]" type="text" label="Координаты (широта)" placeholder="Укажите широту" />
      <InputComponent :required="true" v-model="form.lon" pattern="\[[+-]?[0-9]*\.?[0-9]+,[+-]?[0-9]*\.?[0-9]+]" type="text" label="Координаты (долгота)" placeholder="Укажите долготу" />
    </div>

    <DividerComponent/>

    <CollapseComponent title="Наименование в различных падежах">
      <div class="group">
        <InputComponent :required="true" v-model="form.namecase.nominative" label="Именительный падеж" placeholder="Именительный падеж" />
        <InputComponent :required="true" v-model="form.namecase.genitive" label="Родительный падеж" placeholder="Родительный падеж" />
      </div>
      <DividerComponent size="16"/>
      <div class="group">
        <InputComponent :required="true" v-model="form.namecase.dative" label="Дательный падеж" placeholder="Дательный падеж" />
        <InputComponent :required="true" v-model="form.namecase.accusative" label="Винительный падеж" placeholder="Винительный падеж" />
      </div>
      <DividerComponent size="16"/>
      <div class="group">
        <InputComponent :required="true" v-model="form.namecase.prepositional" label="Предложный падеж" placeholder="Предложный падеж" />
        <InputComponent :required="true" v-model="form.namecase.locative" label="Местный падеж" placeholder="Местный падеж" />
      </div>
      <DividerComponent size="16"/>
      <div class="group">
        <InputComponent :required="true" v-model="form.namecase.ablative" label="откуда? или с кого? с чего?" placeholder="Предложный падеж" />
      </div>

    </CollapseComponent>

    <DividerComponent/>

    <button :disabled="!isFilled" :class="{'disabled': !isFilled}" class="default-hover">
      Добавить
    </button>
  </form>
</template>

<style scoped lang="scss">
  .city-modal {
    width: 600px;
    max-width: 100%;
    overflow: hidden;
    .group {
      overflow: hidden;
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, min(max(1fr)));
      grid-gap: 8px;
      @media (max-width: 768px) {
        grid-template-columns: repeat(1, 1fr);
      }
      ::v-deep {
        @media (max-width: 768px) {
          .input {
            label {
              font-size: 12px !important;
            }
          }
        }
      }
    }

    button {
      border-radius: 16px;
      background: $accent;
      height: 52px;
      width: 100%;
      color: #fff;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      &.disabled {
        opacity: 0.6;
        cursor: default;
      }
    }
  }
</style>