<script>
import TableComponent from "@/components/ui/TableComponent.vue";
import PaginationComponent from "@/components/ui/PaginationComponent.vue";
import DividerComponent from "@/components/ui/DividerComponent.vue";

export default {
  props: {
    company: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      status: 'on_review',
      sort: {
        id: 'latest',
        name: 'Новым'
      },
      search: null,

      vacancies: [],
      page: 1,
      pages: 1,
    }
  },
  watch: {
    status() {
      this.page = 1;
      this.pages = 1;
      this.fetch()
    },
    search() {
      this.page = 1;
      this.pages = 1;
      this.fetch()
    },
    sort() {
      this.page = 1;
      this.pages = 1;
      this.fetch()
    },
  },
  methods: {
    setSort(sort) {
      this.sort = sort;
      this.fetch();
    },
    setPage(page) {
      this.page = page;
      this.fetch();
    },
    updateStatus(id, status) {
      this.$axios.patch('vacancy/' + id + '/status', {
        status
      }).then(() => {
        const index = this.vacancies.findIndex(item => item.id == id);
        if (index > -1) {
          this.vacancies[index].status = status;
        }

        this.$root.$emit('success', {
          title: "Уведомление",
          text: "Статус вакансии обновлен."
        });
      }).catch(errors => {
        if (Array.isArray(errors)) {
          for (const item in errors) {
            this.$root.$emit('error', {
              title: "Ошибка",
              text: errors[item]
            });
          }
        } else {
          this.$root.$emit('error', {
            title: "Ошибка",
            text: "Возникла ошибка, попробуйте позже."
          });
        }
      })
    },
    fetch() {
      this.$axios.get(`manager/${this.company.id}/vacancies`, {
        params: {
          page: this.page,
          per_page: 10,
          status: this.status,
          search: this.search,
          sort: this.sort.id,
        }
      }).then(response => {
        this.vacancies = response.data.data;
        this.pages = response.data.meta.last_page;
        this.page = response.data.meta.current_page;
      })
    }
  },
  components: {DividerComponent, PaginationComponent, TableComponent},
  computed: {
    columns () {
      const base = [
        {
          label: 'Название организации',
          key: 'company_name',
        },
        {
          label: 'Название',
          key: 'name',
          width: 2,
        },
        {
          label: 'Статус',
          key: 'status',
          formatter: (item) => {
            if (item === 'on_review') {
              return 'На модерации'
            } else if (item === 'active') {
              return 'Активна'
            } else if (item === 'draft') {
              return 'В черновике';
            } else if (item === 'archive') {
              return 'В архиве';
            } else if (item === 'declined') {
              return 'Отклонена'
            }

            return '';
          },
          classFormatter: (item) => {
            if (item === 'declined') {
              return 'danger';
            } else if (item === 'active') {
              return 'success';
            }

            return '';
          }
        },
        {
          label: 'Дата создания',
          key: 'created_date'
        },
        {
          label: 'Просмотры',
          key: 'views'
        },
        {
          label: 'Отклики',
          key: 'applies_count'
        },
        {
          label: 'Продвижение',
          key: 'has_boost',
          type: 'boolean',
        }
      ];

      if (this.status === 'on_review') {
        base.push(
            {
              label: '',
              key: 'actions',
              width: 2,
            }
        )
      }

      return base;
    }
  },
  mounted() {
    this.fetch()
  }
}
</script>

<template>
  <div class="company-vacancies">
    <div class="tabs">
      <div class="tab" :class="{'active': status === 'active'}" @click="status = 'active'">Активные</div>
      <div class="tab" :class="{'active': status === 'on_review'}" @click="status = 'on_review'">На модерации</div>
      <div class="tab" :class="{'active': status === 'draft'}" @click="status = 'draft'">В черновике</div>
      <div class="tab" :class="{'active': status === 'declined'}" @click="status = 'declined'">Отклоненные</div>
      <div class="tab" :class="{'active': status === 'archive'}" @click="status = 'archive'">В архиве</div>
    </div>

    <DividerComponent />

    <div class="tbl">
      <TableComponent
          :columns="columns"
          :items="vacancies"
          :to="(item) => $router.push(`/vacancies/${item.id}`)"
      >

        <template #item_actions="{item}" >
          <div class="controls" v-if="item.status === 'on_review'">
            <button class="decline default-hover" @click.prevent.stop="updateStatus(item.id, 'declined')">Отказать</button>
            <button class="approve default-hover" @click.prevent.stop="updateStatus(item.id, 'active')">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M21.5589 10.7401L20.1989 9.16006C19.9389 8.86006 19.7289 8.30006 19.7289 7.90006V6.20006C19.7289 5.14006 18.8589 4.27006 17.7989 4.27006H16.0989C15.7089 4.27006 15.1389 4.06006 14.8389 3.80006L13.2589 2.44006C12.5689 1.85006 11.4389 1.85006 10.7389 2.44006L9.16891 3.81006C8.86891 4.06006 8.29891 4.27006 7.90891 4.27006H6.17891C5.11891 4.27006 4.24891 5.14006 4.24891 6.20006V7.91006C4.24891 8.30006 4.03891 8.86006 3.78891 9.16006L2.43891 10.7501C1.85891 11.4401 1.85891 12.5601 2.43891 13.2501L3.78891 14.8401C4.03891 15.1401 4.24891 15.7001 4.24891 16.0901V17.8001C4.24891 18.8601 5.11891 19.7301 6.17891 19.7301H7.90891C8.29891 19.7301 8.86891 19.9401 9.16891 20.2001L10.7489 21.5601C11.4389 22.1501 12.5689 22.1501 13.2689 21.5601L14.8489 20.2001C15.1489 19.9401 15.7089 19.7301 16.1089 19.7301H17.8089C18.8689 19.7301 19.7389 18.8601 19.7389 17.8001V16.1001C19.7389 15.7101 19.9489 15.1401 20.2089 14.8401L21.5689 13.2601C22.1489 12.5701 22.1489 11.4301 21.5589 10.7401ZM16.1589 10.1101L11.3289 14.9401C11.1889 15.0801 10.9989 15.1601 10.7989 15.1601C10.5989 15.1601 10.4089 15.0801 10.2689 14.9401L7.84891 12.5201C7.55891 12.2301 7.55891 11.7501 7.84891 11.4601C8.13891 11.1701 8.61891 11.1701 8.90891 11.4601L10.7989 13.3501L15.0989 9.05006C15.3889 8.76006 15.8689 8.76006 16.1589 9.05006C16.4489 9.34006 16.4489 9.82006 16.1589 10.1101Z" fill="white"/>
              </svg>
              Одобрить
            </button>
          </div>
        </template>

      </TableComponent>
    </div>

    <DividerComponent />

    <PaginationComponent @change="setPage" :pages="pages" :page="page" />
  </div>
</template>

<style scoped lang="scss">
.tbl {
  width: 100%;
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  ::v-deep {
    .table__body {
      min-width: 900px;
    }
  }
}
.filters {
  display: flex;
  grid-gap: 16px;
}
.controls {
  justify-content: flex-end;
  display: flex;
  grid-gap: 8px;
  flex: 2 !important;
  button {
    display: flex;
    align-items: center;
    height: 44px;
    padding: 0 16px;
    border-radius: 16px;
    color: #fff;
    grid-gap: 8px;
    &.decline {
      background: #DF2F44;
    }
    &.approve {
      background: #74CD63;
    }
  }
}

.tabs {
  display: flex;
  grid-gap: 8px;
  flex-wrap: wrap;
  margin-bottom: 32px;
  .tab {
    padding: 10px 16px;
    border-radius: 8px;
    background: #F1F3F9;
    color: #7C7F8A;
    font-size: 14px;
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    transition: 0.2s;
    &.active, &:hover {
      color: #fff;
      background: $accent;
    }
  }
}
</style>