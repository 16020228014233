<script>
import H1Component from "@/components/ui/H1Component.vue";
import DividerComponent from "@/components/ui/DividerComponent.vue";
import InputComponent from "@/components/ui/InputComponent.vue";
import SelectComponent from "@/components/ui/SelectComponent.vue";
import TableComponent from "@/components/ui/TableComponent.vue";
import PaginationComponent from "@/components/ui/PaginationComponent.vue";

export default {
  data() {
    return {
      isLoading: true,
      columns: [
        {
          label: 'Компания',
          key: 'company',
          formatter: (value) => value.name,
          styles: {
            color: '#8511CC',
          }
        },
        {
          label: 'Соискатель',
          key: 'user',
          formatter: (value) => value.name,
          styles: {
            color: '#8511CC',
          }
        },
        {
          label: 'Текст',
          key: 'text',
          width: 3,
          styles: {
            'font-size': '12px',
            'padding-right': '40px',
            'display': 'block'
          }
        },
        {
          label: 'Оценка',
          key: 'stars'
        },
        {
          label: 'Создан',
          key: 'created_at',
          formatter: (value) => (new Date(value * 1000)).toLocaleString('ru-RU', { timeZone: 'Europe/Samara' }),
        },
      ],
      reviews: [],
      page: 1,
      pages: 1,
      sort: {
        id: 'latest',
        name: 'Новым'
      },
      search: null,
    }
  },
  watch: {
    search() {
      this.page = 1;
      this.pages = 1;
      this.fetch()
    },
    sort() {
      this.page = 1;
      this.pages = 1;
      this.fetch()
    },
  },
  methods: {
    async publish(id)  {
      const confirmed = await this.$root.$confirm("Вы уверены, что хотите опубликовать отзыв?");

      if  (!confirmed) return;

      this.$axios.patch('reviews/' + id).then(() => {

        const index = this.reviews.findIndex(item => item.id == id);

        if (index > -1)  {
          this.reviews[index].status = 'published';
        }

        this.$root.$emit('success', {
          title: "Уведомление",
          text: "Отзыв успешно опубликован"
        });
      }).catch(errors => {
        if (Array.isArray(errors)) {
          for (const item in errors) {
            this.$root.$emit('error', {
              title: "Ошибка",
              text: errors[item]
            });
          }
        } else {
          this.$root.$emit('error', {
            title: "Ошибка",
            text: "Возникла ошибка, попробуйте позже."
          });
        }
      })
    },
    async goDelete(id) {
      const confirmed = await this.$root.$confirm("Вы уверены, что хотите удалить отзыв?");

      if (!confirmed) return;

      this.$axios.delete('reviews/' + id).then(() => {

        const index = this.reviews.findIndex(item => item.id == id);

        if (index > -1)  {
          this.reviews.splice(index, 1);
        }

        this.$root.$emit('success', {
          title: "Уведомление",
          text: "Отзыв успешно удален"
        });
      }).catch(errors => {
        if (Array.isArray(errors)) {
          for (const item in errors) {
            this.$root.$emit('error', {
              title: "Ошибка",
              text: errors[item]
            });
          }
        } else {
          this.$root.$emit('error', {
            title: "Ошибка",
            text: "Возникла ошибка, попробуйте позже."
          });
        }
      })
    },
    setSort(sort) {
      this.sort = sort;
      this.fetch();
    },
    setPage(page) {
      this.page = page;
      this.fetch();
    },
    fetch() {
      this.$axios.get('reviews', {
        params: {
          page: this.page,
          per_page: 20,
          search: this.search,
          sort: this.sort.id,
        }
      }).then(response => {
        this.reviews = response.data.data;
        this.pages = response.data.meta.last_page;
        this.page = response.data.meta.current_page;
      }).finally(() => {
        setTimeout(() => {
          this.isLoading = false;
        }, 100)
      })
    }
  },
  mounted() {
    this.fetch();
  },
  components: {
    PaginationComponent,
    TableComponent, SelectComponent, InputComponent, DividerComponent, H1Component
  }
}
</script>

<template>
  <div class="page users">
    <H1Component text="Отзывы" />

    <DividerComponent />

    <div class="filters">
      <InputComponent
          v-model="search"
          placeholder="Поиск по тексту отзыва"
          :icon="require('@/assets/icons/input-default.svg')"
      />

      <SelectComponent
          additionalLabel="Сортировать по:"
          :value="sort"
          @select="setSort"
          :options="[
              {
                id: 'latest',
                name: 'Новым'
              },
              {
                id: 'oldest',
                name: 'Старым',
              },
          ]"
      />
    </div>

    <DividerComponent />

    <div class="tbl">
      <TableComponent
          :columns="columns"
          :items="reviews"
          :is-loading="isLoading"
      >
        <template #actions="{item}">
          <div class="actions">
            <button v-if="item.status === 'pending'" class="publish default-hover" @click="publish(item.id)">Опубликовать</button>
            <button v-if="item.status === 'published'" disabled class="published default-hover">Опубликован</button>
            <button class="delete default-hover" @click="goDelete(item.id)">Удалить</button>
          </div>
        </template>
      </TableComponent>
    </div>

    <DividerComponent />

    <PaginationComponent @change="setPage" :pages="pages" :page="page" />

  </div>
</template>

<style scoped lang="scss">
.tbl {
  width: 100%;
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  ::v-deep {
    .table__body {
      min-width: 700px;
    }
  }
}
.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
}
.filters {
  display: flex;
  grid-gap: 16px;
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    display: grid;
    grid-gap: 8px;
  }
}
.actions {
  display: flex;
  justify-content: flex-end;
  grid-gap: 16px;
  padding: 12px;
}
.publish {
  width: 200px;
  background: $accent;
  height: 44px;
  border-radius: 12px;
  color: #fff;
  font-size: 14px;
}
.published {
  width: 200px;
  background: $success;
  cursor: default;
  opacity: 0.7;
  height: 44px;
  border-radius: 12px;
  color: #fff;
  font-size: 14px;
}
.delete {
  width: 200px;
  background: $danger;
  height: 44px;
  border-radius: 12px;
  color: #fff;
  font-size: 14px;
}
</style>