<script>
import DividerComponent from "@/components/ui/DividerComponent.vue";

export default {
  name: "SettingsContacts",
  components: {DividerComponent}
}
</script>

<template>
  <div class="settings-contacts">
    <h2>Контактная информация</h2>
    <DividerComponent size="16" />
    <div class="grid">
      <div>
        <span>Email:</span>
        <strong>{{ $root.settings?.contacts_email ?? '-' }}</strong>
      </div>
      <div>
        <span>Viber:</span>
        <strong>{{ $root.settings?.contacts_viber ?? '-' }}</strong>
      </div>

      <div>
        <span>Telegram:</span>
        <strong>{{ $root.settings?.contacts_telegram ?? '-' }}</strong>
      </div>

      <div>
        <span>VK:</span>
        <strong>{{ $root.settings?.contacts_vk ?? '-' }}</strong>
      </div>

    </div>
    <DividerComponent size="16" />
    <button class="edit default-hover" @click="$router.push(`/settings/contacts`)">Редактировать</button>
  </div>
</template>

<style scoped lang="scss">
.settings-contacts {
  width: 1000px;
  max-width: 100%;
}
.edit {
  height: 52px;
  width: 100%;
  border-radius: 16px;
  background: #F1F3F9;
  font-size: 16px;
  font-weight: 500;
  @media (max-width: 768px) {
    font-size: 14px;
    border-radius: 10px;
    height: 44px;
  }
}
h2 {
  color: var(--Text-Main-Text-Color, #2F2B2E);
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media (max-width: 768px) {
    font-size: 22px;
  }
}
.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 8px;
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
  div {
    display: flex;
    align-items: center;
    span {
      color: #7C7F8A;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-right: 4px;
      display: block;
      @media (max-width: 768px) {
        font-size: 14px;
      }
    }
    strong {
      color: #2F2B2E;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      @media (max-width: 768px) {
        font-size: 14px;
      }
    }
  }
}
</style>