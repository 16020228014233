<script>
import H1Component from "@/components/ui/H1Component.vue";
import DividerComponent from "@/components/ui/DividerComponent.vue";
import StatisticBlockComponent from "@/components/global/StatisticBlockComponent.vue";
import InputComponent from "@/components/ui/InputComponent.vue";
import SelectComponent from "@/components/ui/SelectComponent.vue";
import TableComponent from "@/components/ui/TableComponent.vue";
import PaginationComponent from "@/components/ui/PaginationComponent.vue";

export default {
  data() {
    return {
      isLoading: true,
      columns: [
        {
          label: 'Название',
          key: 'name',
        },
        {
          label: 'Баланс',
          key: 'balance',
          formatter: (item) => item + ' ₽'
        },
        {
          label: 'Email',
          key: 'email',
        },
        {
          label: 'Номер телефона',
          key: 'phone',
        },
        {
          label: 'Website',
          key: 'website',
        },
        {
          label: 'Рейтинг',
          key: 'rating',
        },
        {
          label: 'Тип',
          key: 'type',
          formatter: (item) => item?.name ?? '-',
        },
        {
          label: 'Telegram',
          key: 'telegram',
        },
        {
          label: 'Дата регистрации',
          key: 'registered_date'
        },
      ],
      users: [],
      page: 1,
      pages: 1,
      sort: {
        id: 'latest',
        name: 'Новым'
      },
      search: null,
    }
  },
  watch: {
    search() {
      this.page = 1;
      this.pages = 1;
      this.fetch()
    },
    sort() {
      this.page = 1;
      this.pages = 1;
      this.fetch()
    },
  },
  methods: {
    setSort(sort) {
      this.sort = sort;
      this.fetch();
    },
    setPage(page) {
      this.page = page;
      this.fetch();
    },
    fetch() {
      this.$axios.get('companies', {
        params: {
          page: this.page,
          per_page: 10,
          search: this.search,
          sort: this.sort.id,
        }
      }).then(response => {
        this.users = response.data.data;
        this.pages = response.data.meta.last_page;
        this.page = response.data.meta.current_page;
      }).finally(() => {
        setTimeout(() => {
          this.isLoading = false;
        }, 100)
      })
    }
  },
  mounted() {
    this.fetch();
  },
  components: {
    PaginationComponent,
    TableComponent, SelectComponent, InputComponent, StatisticBlockComponent, DividerComponent, H1Component
  }
}
</script>

<template>
  <div class="page users">
    <H1Component text="Компании" />

    <DividerComponent />

    <div class="grid">
      <StatisticBlockComponent
          :icon="require('@/assets/icons/stat-vacancies.svg')"
          title="Всего компаний"
          value="10 833"
          stat="+15% в этом месяце"
      />

    </div>

    <DividerComponent />

    <div class="filters">
      <InputComponent
          v-model="search"
          placeholder="Поиск по email"
          :icon="require('@/assets/icons/input-default.svg')"
      />

      <SelectComponent
          additionalLabel="Сортировать по:"
          :value="sort"
          @select="setSort"
          :options="[
              {
                id: 'latest',
                name: 'Новым'
              },
              {
                id: 'oldest',
                name: 'Старым',
              },
          ]"
      />
    </div>

    <DividerComponent />

    <div class="tbl">
      <TableComponent
          :columns="columns"
          :is-loading="isLoading"
          :items="users"
          :to="(item) => $router.push('/entity/' + item.id)"
      />
    </div>

    <DividerComponent />

    <PaginationComponent @change="setPage" :pages="pages" :page="page" />

  </div>
</template>

<style scoped lang="scss">
.tbl {
  width: 100%;
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  ::v-deep {
    .table__body {
      min-width: 1000px;
    }
  }
}
.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 8px;
  }
}
.filters {
  display: flex;
  grid-gap: 16px;
  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 8px;
  }
}
</style>