<template>
  <div class="dashboard">
    <H1Component text="Главная" />

    <DividerComponent />

    <div class="grid">
      <StatisticBlockComponent
          :stat="`${dashboard.total_users.diffDay} за сегодня`"
          :value="dashboard.total_users.value"
          title="Всего пользователей"
          :statColor="dashboard.total_reviews.diffDay > 0 ? 'success' : 'danger'"
          :icon="require('@/assets/icons/stat-vacancies.svg')"
      />

      <StatisticBlockComponent
          :stat="`${dashboard.total_reviews.review} ожидают модерации`"
          :value="dashboard.total_reviews.value"
          title="Всего отзывов"
          :statColor="dashboard.total_reviews.value === 0 ? 'success' : 'danger'"
          :icon="require('@/assets/icons/stat-vacancies.svg')"
      />

      <StatisticBlockComponent
          :stat="`${dashboard.total_tickets.review} не закрытых`"
          :value="dashboard.total_tickets.value"
          title="Всего обращений"
          :statColor="dashboard.total_reviews.value === 0 ? 'success' : 'danger'"
          :icon="require('@/assets/icons/stat-vacancies.svg')"
      />

      <StatisticBlockComponent
          :stat="`+${dashboard.total_vacancies.diffMonth} в этом месяце`"
          :value="dashboard.total_vacancies.value"
          title="Всего вакансий"
          statColor="success"
          :icon="require('@/assets/icons/stat-vacancies.svg')"
      />

      <StatisticBlockComponent
          :stat="`+${dashboard.total_companies.diffMonth} в этом месяце`"
          :value="dashboard.total_companies.value"
          title="Всего компаний"
          :icon="require('@/assets/icons/stat-companies.svg')"
      />

      <StatisticBlockComponent
          :stat="`+${dashboard.total_resumes.diffMonth} в этом месяце`"
          :value="dashboard.total_resumes.value"
          title="Всего резюме"
          statColor="success"
          :icon="require('@/assets/icons/stat-resumes.svg')"
      />

    </div>
  </div>
</template>

<script>

import H1Component from "@/components/ui/H1Component.vue";
import DividerComponent from "@/components/ui/DividerComponent.vue";
import StatisticBlockComponent from "@/components/global/StatisticBlockComponent.vue";

export default {
  data() {
    return {
      dashboard: {
        total_vacancies: {
          value: 0,
          diffMonth: 0,
        },
        total_companies: {
          value: 0,
          diffMonth: 0,
        },
        total_resumes: {
          value: 0,
          diffMonth: 0,
        },
      }
    }
  },
  methods: {
    fetch() {
      this.$axios.get('dashboard').then(response => {
        this.dashboard = response.data;
      })
    }
  },
  mounted() {
    this.fetch();
  },
  name: 'HomeView',
  components: {
    StatisticBlockComponent,
    DividerComponent,
    H1Component
  }
}
</script>
<style scoped lang="scss">
.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
  @media (max-width: 1500px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 992px) {
    grid-gap: 8px;
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
