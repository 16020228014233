import axios from 'axios';
import extractErrors from "@/utils/extractErrors";

const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
});

// Добавляем интерцептор для запросов
axiosInstance.interceptors.request.use(
    config => {
        // Получаем токен из localStorage
        const token = localStorage.getItem('token');

        // Если токен существует, добавляем его в заголовок Authorization
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

// Добавляем интерцептор для ответов
axiosInstance.interceptors.response.use(response => {
        return response;
    },
    error => {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }

        let errors = [];

        if (error?.response?.data?.errors) {
            errors = extractErrors(error?.response?.data?.errors);
        } else {
            errors = [error?.response?.data.message ?? 'Неизвестная ошибка.'];
        }

        throw errors;
    }
);

export default axiosInstance;
