<template>
  <div id="app">
    <div class="wrapper" v-if="isInit">
      <div class="logout default-hover" @click="logout">
        <svg width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15 16.5V19C15 20.1046 14.1046 21 13 21H6C4.89543 21 4 20.1046 4 19V5C4 3.89543 4.89543 3 6 3H13C14.1046 3 15 3.89543 15 5V8.0625M11 12H21M21 12L18.5 9.5M21 12L18.5 14.5" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      <div class="mobile-burger" v-if="token">
        <div class="icon" @click="$root.$emit('toggleSidebar')">
          <img src="@/assets/burger.svg" alt="">
        </div>
      </div>
      <SidebarComponent v-if="$route.name !== 'login'" />
      <div class="content">
        <router-view />
      </div>
    </div>
    <NotificationsWrapper/>
    <ConfirmModalComponent ref="confirmModal" />
  </div>
</template>
<style scoped lang="scss">
.wrapper {
  display: flex;
  overflow: hidden;
  @media (max-width: 1024px) {
    padding-top: 60px;
  }
  .content {
    flex-grow: 1;
    overflow: hidden;
    padding: 64px 130px;
    @media (max-width: 1700px) {
      padding: 34px 30px;
    }
    @media (max-width: 992px) {
      padding: 26px 10px;
    }
  }
  .logout {
    position: fixed;
    cursor: pointer;
    width: 80px;
    height: 80px;
    background: #fff;
    top: 0;
    z-index: 11;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
    @media (max-width: 1024px) {
      height: 70px;
    }
  }
  .mobile-burger {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 70px;
    align-items: center;
    z-index: 10;
    background: #fff;
    width: 100%;
    padding: 0 16px;
    box-shadow: 0px 0px 5px 5px rgba(0,0,0, 0.1);
    .icon {
      width: 50px;
      height: 50px;
      img {
        width: 50px;
      }
    }

    @media (max-width: 1024px) {
      display: flex;
    }
  }
}
</style>
<script>
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      isInit: false,
    }
  },
  components: {
    ConfirmModalComponent,
    SidebarComponent,
    NotificationsWrapper
  },
  computed: {
    ...mapGetters({
      token: "token"
    })
  },
  methods: {
    logout() {
      localStorage.removeItem('token');
      this.$router.push('/login');
    },
    fetchSettings() {
      this.$axios.get('settings').then(response => {
        this.$root.settings = response.data;

        this.isInit = true;
      })
    }
  },
  mounted() {
    this.$root.$on('reloadSettings', () => {
      this.fetchSettings()
    })
    this.$root.$confirm = (message) => {
      return this.$refs.confirmModal.open(message);
    };

    this.fetchSettings();
  }
}
import ConfirmModalComponent from "@/components/global/ConfirmModalComponent.vue";
import SidebarComponent from "@/components/global/SidebarComponent.vue";
import NotificationsWrapper from "@/components/Notifications/NotificationsWrapper.vue";
</script>