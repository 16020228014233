<script>
import InputComponent from "@/components/ui/InputComponent.vue";
import DividerComponent from "@/components/ui/DividerComponent.vue";
export default {
  data() {
    return {
      isLoading: false,
      hours: 24,
    }
  },
  computed: {
    period() {
      return `${this.$root.settings.once_duration_top} минут`;
    },
    cost() {
      if (!this.$root.settings?.once_price_top) return 0;

      return this.$root.settings.once_price_top * this.ids.length;
    },
    periods () {
      const first = (new Date()).toLocaleString('ru-RU', { timeZone: 'Europe/Samara' });
      const second = new Date((new Date()).getTime() + this.$root.settings.once_duration_top * 60 * 1000);

      return `с ${first} по ${(new Date(second)).toLocaleString('ru-RU', { timeZone: 'Europe/Samara' })}`;
    }
  },
  methods: {
    pay () {
      this.isLoading = true;


      this.$axios.post('manager/vacancy/boost', {
        ids: this.ids,
        type: 'top',
      }).then(() => {

        this.$root.$emit('success', {
          title: "Уведомление",
          text: "Покупка успешно совершена"
        });

        this.$emit('close');
      }).catch(errors => {
        if (Array.isArray(errors)) {
          for (const item in errors) {
            this.$root.$emit('error', {
              title: "Ошибка",
              text: errors[item]
            });
          }
        } else {
          this.$root.$emit('error', {
            title: "Ошибка",
            text: "Возникла ошибка, попробуйте позже."
          });
        }
      }).finally(() => {
        this.isLoading = false;
      })
    }
  },
  components: {DividerComponent, InputComponent},
  props: {
    ids: {
      type: Array,
      required: true,
    }
  }
}
</script>

<template>
  <div class="buy-top">
    <div class="modal-title">
      {{ `Поднятие ${ids.length < 2 ? 'вакансии' : 'вакансий' + '(' + ids.length + ')'}` }}
    </div>
    <DividerComponent />
    <div class="price">
      <InputComponent :disabled="true" label="Срок и цена" v-model="period" />
      <div class="cost">
        {{ cost }} ₽
      </div>
    </div>

    <div class="periods">
      Период действия <em>{{ periods }}</em>
    </div>

    <div class="actions">
      <button class="back default-hover" @click="$emit('back')">Назад</button>
      <button class="pay default-hover" :class="{'loading': isLoading}" @click="pay" :disabled="isLoading">Оплатить</button>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .buy-top {
    .actions {
      margin-top: 32px;
      display: flex;
      grid-gap: 8px;
      button {
        height: 52px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 16px;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        @media (max-width: 768px) {
          font-size: 16px;
          height: 44px;
        }
      }
      .back {
        flex: 1;
        background: #F1F3F9;
        color: #2F2B2E
      }
      .pay {
        flex: 2;
        color: #fff;
        background: $accent;
      }
    }
    .periods {
      color: #B4B8C6;
      font-size: 14px;
      font-style: normal;
      margin-top: 8px;
      font-weight: 400;
      line-height: 120%; /* 14px */
      &.danger {
        color: $danger;
      }
      em {
        font-size: 14px;
        color: #7C7F8A;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 14px */
      }
    }
    .price {
      position: relative;
      .cost {
        position: absolute;
        right: 16px;
        height: 52px;
        bottom: 0;
        display: flex;
        align-items: center;
        color: #7C7F8A;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        @media (max-width: 768px) {
          height: 44px;
          font-size: 14px;
        }
      }
    }
    span {
      color: #7C7F8A;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
</style>