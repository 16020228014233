var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page users"},[_c('H1Component',{attrs:{"text":"Компании"}}),_c('DividerComponent'),_c('div',{staticClass:"grid"},[_c('StatisticBlockComponent',{attrs:{"icon":require('@/assets/icons/stat-vacancies.svg'),"title":"Всего компаний","value":"10 833","stat":"+15% в этом месяце"}})],1),_c('DividerComponent'),_c('div',{staticClass:"filters"},[_c('InputComponent',{attrs:{"placeholder":"Поиск по email","icon":require('@/assets/icons/input-default.svg')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('SelectComponent',{attrs:{"additionalLabel":"Сортировать по:","value":_vm.sort,"options":[
            {
              id: 'latest',
              name: 'Новым'
            },
            {
              id: 'oldest',
              name: 'Старым',
            },
        ]},on:{"select":_vm.setSort}})],1),_c('DividerComponent'),_c('div',{staticClass:"tbl"},[_c('TableComponent',{attrs:{"columns":_vm.columns,"is-loading":_vm.isLoading,"items":_vm.users,"to":(item) => _vm.$router.push('/entity/' + item.id)}})],1),_c('DividerComponent'),_c('PaginationComponent',{attrs:{"pages":_vm.pages,"page":_vm.page},on:{"change":_vm.setPage}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }