<script>
export default {
  props: {
    text: {
      type: String,
      default: "Text"
    }
  }
}
</script>

<template>
  <button @click.prevent.stop="$emit('click', $event)" class="default-hover">
    <img src="@/assets/icons/add.svg" alt="">
    {{ text }}
  </button>
</template>

<style scoped lang="scss">
  button {
    height: 52px;
    padding: 0 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 8px;
    color: #2F2B2E;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 16px;
    background: #F1F3F9;
    @media (max-width: 768px) {
      padding: 0 16px;
      height: 44px;
      font-size: 16px;
      border-radius: 10px;
    }
  }
</style>