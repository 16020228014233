<script>
import TableComponent from "@/components/ui/TableComponent.vue";

export default {
  components: {TableComponent},
  props: {
    company: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      columns: [
        {
          label: 'Id',
          key: 'id',
        },
        {
          label: "Тема",
          key: 'subject'
        },
        {
          label: 'Статус',
          key: 'status',
          formatter: (item) => {
            if (item === 'active') {
              return 'Активен'
            } else {
              return 'Закрыт'
            }
          },
          classFormatter: (item) => {
            if (item === 'active') {
              return 'success';
            } else {
              return 'danger';
            }
          },
        },
        {
          label: "Дата",
          key: "created_at",
          formatter: (value) => (new Date(value * 1000)).toLocaleString('ru-RU', { timeZone: 'Europe/Samara' }),
        },
      ],
      tickets: [],
    }
  },
  methods: {
    setPage(page) {
      this.page = page;
      this.fetch();
    },
    fetch() {
      this.$axios.get(`manager/${this.company.id}/tickets`).then(response => {
        this.tickets = response.data;
      })
    }
  },
  mounted() {
    this.fetch();
  }
}
</script>

<template>
  <div class="tickets">
    <div class="tbl">
      <TableComponent
          :columns="columns"
          :items="tickets"
          :to="(item) => $router.push(`/support/${item.id}`)"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.tbl {
  width: 100%;
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  ::v-deep {
    .table__body {
      min-width: 600px;
    }
  }
}
</style>