<script>
import DividerComponent from "@/components/ui/DividerComponent.vue";
import SelectDropdown from "@/components/ui/SelectDropdown.vue";

export default {
  data() {
    return {
      config: null,
    }
  },
  methods: {
    fetch() {
      this.$axios.get('config').then(response => {
        this.config = response.data;
      })
    },
    save(data) {
      this.$axios.post('config', data)
          .then(() => {
            this.$root.$emit('reloadSettings');

            this.$root.$emit('success', {
              title: "Уведомление",
              text: "Успешно обновлено.",
            });
          })
          .catch(() => {
            this.$root.$emit('error', { title: "Ошибка", text: "Unknown error." });
          })
          .finally(() => {
            this.$emit('reload');
          });
    }
  },
  mounted() {
    this.fetch();
  },
  name: "SettingsSeo",
  components: {SelectDropdown, DividerComponent}
}
</script>

<template>
  <div class="settings-seo" v-if="config">
    <h2>SEO</h2>

    <DividerComponent />

    <SelectDropdown
        :options="config.templates"
        :label="config.main_default_seo_template_id.field.label"
        v-if="config.main_default_seo_template_id"
        @selected="config.main_default_seo_template_id.field.value = $event"
        :value="config.main_default_seo_template_id.field.value"
    />

    <DividerComponent size="16" />

    <SelectDropdown
        :options="config.templates"
        :label="config.vacancy_default_seo_template_id.field.label"
        v-if="config.vacancy_default_seo_template_id"
        @selected="config.vacancy_default_seo_template_id.field.value = $event"
        :value="config.vacancy_default_seo_template_id.field.value"
    />

    <DividerComponent size="16" />

    <SelectDropdown
        :options="config.templates"
        :label="config.vacancies_default_seo_template_id.field.label"
        v-if="config.vacancies_default_seo_template_id"
        @selected="config.vacancies_default_seo_template_id.field.value = $event"
        :value="config.vacancies_default_seo_template_id.field.value"
    />

    <DividerComponent size="16" />

    <SelectDropdown
        :options="config.templates"
        :label="config.catalog_default_seo_template_id.field.label"
        v-if="config.catalog_default_seo_template_id"
        @selected="config.catalog_default_seo_template_id.field.value = $event"
        :value="config.catalog_default_seo_template_id.field.value"
    />

    <DividerComponent size="16" />

    <SelectDropdown
        :options="config.templates"
        :label="config.company_default_seo_template_id.field.label"
        v-if="config.company_default_seo_template_id"
        @selected="config.company_default_seo_template_id.field.value = $event"
        :value="config.company_default_seo_template_id.field.value"
    />

    <DividerComponent />

    <button class="default-hover" @click="save({
        vacancy_default_seo_template_id: config.vacancy_default_seo_template_id.field.value !== null &&
                typeof config.vacancy_default_seo_template_id.field.value ===
                  'object'
                  ? config.vacancy_default_seo_template_id.field.value.id
                  : config.vacancy_default_seo_template_id.field.value,

        main_default_seo_template_id: config.main_default_seo_template_id.field.value !== null &&
                typeof config.main_default_seo_template_id.field.value ===
                  'object'
                  ? config.main_default_seo_template_id.field.value.id
                  : config.main_default_seo_template_id.field.value,
        vacancies_default_seo_template_id: config.vacancies_default_seo_template_id.field.value !== null &&
                typeof config.vacancies_default_seo_template_id.field.value ===
                  'object'
                  ? config.vacancies_default_seo_template_id.field.value.id
                  : config.vacancies_default_seo_template_id.field.value,
        catalog_default_seo_template_id: config.catalog_default_seo_template_id.field.value !== null &&
                typeof config.catalog_default_seo_template_id.field.value ===
                  'object'
                  ? config.catalog_default_seo_template_id.field.value.id
                  : config.catalog_default_seo_template_id.field.value,
        company_default_seo_template_id: config.company_default_seo_template_id.field.value !== null &&
                typeof config.company_default_seo_template_id.field.value ===
                  'object'
                  ? config.company_default_seo_template_id.field.value.id
                  : config.company_default_seo_template_id.field.value,
    })">Сохранить</button>
  </div>
</template>

<style scoped lang="scss">
h2 {
  color: var(--Text-Main-Text-Color, #2F2B2E);
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media (max-width: 768px) {
    font-size: 20px;
  }
}

button {
  width: 100%;
  height: 52px;
  border-radius: 18px;
  background: $accent;
  color: #fff;
  font-weight: 500px;
  font-size: 16px;
}
</style>