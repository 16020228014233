<script>
import {agePrefix} from "@/helpers/agePrefix.js";
import DividerComponent from "@/components/ui/DividerComponent.vue";
export default {
  props: {
    item: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      isLoading: false,
      agePrefix,
      isOpen: false,
      isNowOpen: false,
      localItem: null,
    }
  },
  methods: {
    startConversation() {
      this.isLoading = true;
      this.$axios.post('vacancy/' + this.item.vacancy.id + '/applies/' + this.item.id).then(() => {
        this.$bus.$emit('success', {
          title: "Уведомление",
          text: "Отклик одобрен."
        });
        this.localItem.status = 'approved';
        this.isNowOpen = true;
      }).finally(() => {
        setTimeout(() => {
          this.isLoading = false;
        }, 200);
      })
    }
  },
  mounted() {
    this.localItem = JSON.parse(JSON.stringify(this.item));
  },
  components: {DividerComponent}
}
</script>

<template>
  <div class="apply-item" v-if="localItem">
    <div class="top" @click="isOpen = !isOpen">
      <div class="left">
        <strong>{{ localItem.user.information.name }}</strong>
        <span>{{ agePrefix(localItem.user.information.age) }}</span>
      </div>
      <div class="right">
        <div class="price">
          {{ localItem.user.resume.salary_from }} - {{ localItem.user.resume.salary_to }} ₽
        </div>
        <div class="arrow" :class="{'rotate': isOpen}">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
            <path d="M17 10.5L12 15.5L7 10.5" stroke="#7C7F8A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
      </div>
    </div>
    <div class="body" v-show="isOpen">
      <div class="form-group">
        <label for="">Возраст:</label>
        <span>{{ agePrefix(localItem.user.information.age) }}</span>
      </div>
      <div class="form-group">
        <label for="">Пол:</label>
        <span v-if="localItem.user.information.sex === 'female'">Женский</span>
        <span v-else-if="localItem.user.information.sex === 'male'">Мужской</span>
      </div>
      <div class="form-group">
        <label for="">Гражданство:</label>
        <span>{{ localItem.user.information.residency.name }}</span>
      </div>

      <DividerComponent />

      <label for="">О себе:</label>
      <span v-html="localItem.user.resume.about"></span>

      <div v-if="localItem.status === 'approved'" class="contacts">
        <div class="form-group">
          <label for="">Номер телефона:</label>
          <span>{{ localItem.user.information.phone }}</span>
        </div>
        <div class="form-group">
          <label for="">Telegram:</label>
          <span>{{ localItem.user.information.telegram }}</span>
        </div>
      </div>

      <button class="see default-hover" :class="{'loading': isLoading}" :disabled="isLoading" @click="startConversation" v-if="localItem.status === 'created'">Начать общение</button>
      <button class="see disabled" v-if="localItem.status === 'approved'" disabled="disabled">
        Работодатель открыл контакты:
        <template v-if="isNowOpen">Только что</template>
        <template v-else>{{ (new Date(localItem.approved_at * 1000)).toLocaleString('ru-RU', { timeZone: 'Europe/Samara' }) }}</template>
      </button>

      <template v-if="localItem.review">
        <DividerComponent />
        <label for="">Отзыв соискателя:</label>
        <div class="review" v-if="localItem.review">
          <div class="message">
            <div class="message-top">
              <span>Оценка: {{ parseFloat(localItem.review.stars).toFixed(1) }}</span>
              <div class="status published" :class="[localItem.review.status]">
                <template v-if="localItem.status === 'created'">На модерации</template>
                <template v-else>Опубликован</template>
              </div>
            </div>
            <p>
              {{ localItem.review.text }}
            </p>
          </div>
        </div>
      </template>

    </div>
  </div>
</template>

<style scoped lang="scss">
.apply-item {
  border-radius: 32px;
  background: #F1F3F9;
  padding: 32px;
  .contacts {
    margin-top: 32px;
    padding: 16px;
    background: rgba(255,255,255, 0.4);
    border-radius: 16px;
    border: 2px dashed $accent;
  }
  .review {
    .message {
      background: #fff;
      color: #0D0D0D;
      border-radius: 16px;
      padding: 12px 16px;
      margin-top: 16px;
      .message-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        span {
          color: #2F2B2E;
          font-size: 16px;
          font-style: normal;
        }
        .status {
          font-size: 14px;
          color: #7C7F8A;
          &.published {
            color: #74CD63
          }
        }
      }
      p {
        margin-top: 12px;
        display: block;
        font-size: 14px;
        word-break: break-word;
        font-style: normal;
      }
    }
    .group + .group {
      margin-top: 12px;
    }
  }
  .badge {
    background: $accent;
    color: #fff;
    padding: 12px 16px;
    border-radius: 16px;
    margin-top: 20px;
  }
  .note {
    margin-top: 4px;
    color: #7C7F8A;
    font-size: 14px;
    &.accent {
      color: $accent;
      text-align: center;
      width: 100%;
      font-weight: 500;
      margin-top: 16px;
    }
  }
  .top {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      strong {
        color: $accent;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        display: block;
        line-height: normal;
      }
      span {
        color: #B4B8C6;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 14px */
      }
    }
    .right {
      display: flex;
      align-items: center;
      .price {
        margin-right: 16px;
        color: #2F2B2E;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      .arrow {
        display: flex;
        align-items: center;
        transition: 0.2s;
        &.rotate {
          transform: rotate(180deg);
        }
      }
    }
  }
  .body {
    padding-top: 32px;
    .see {
      height: 52px;
      margin-top: 32px;
      width: 100%;
      border-radius: 16px;
      background: $accent;
      font-size: 16px;
      color: #fff;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .form-group + .form-group {
      margin-top: 8px;
    }
    label {
      display: block;
      color: #7C7F8A;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 8px;
    }
    span {
      color: #2F2B2E;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      word-break: break-word;
    }
    .form-group {
      display: flex;
      grid-gap: 8px;
      label {
        margin-bottom: 0;
      }
    }
  }
}
</style>