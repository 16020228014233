<script>
export default {
  props: {
    text: {
      type: String,
      default: "H1 Text"
    }
  }
}
</script>

<template>
  <h1 class="h1">{{ text }}</h1>
</template>

<style scoped lang="scss">
  h1.h1 {
    color: $accent;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @media (max-width: 768px) {
      font-size: 26px;
    }
  }
</style>