<script>
import H1Component from "@/components/ui/H1Component.vue";
import DividerComponent from "@/components/ui/DividerComponent.vue";
import CompanyAnket from "@/components/company/CompanyAnket.vue";
import CompanyVacancies from "@/components/company/CompanyVacancies.vue";
import CompanyBuyes from "@/components/company/CompanyBuyes.vue";
import CompanyTickets from "@/components/company/CompanyTickets.vue";
import CompanyReviews from "@/components/company/CompanyReviews.vue";

export default {
  data() {
    return {
      tab: 'anket',
    }
  },
  props: {
    company: {
      type: Object,
      required: true,
    }
  },
  name: "CompanyDashboard",
  components: {
    CompanyReviews,
    CompanyTickets, CompanyBuyes, CompanyVacancies, CompanyAnket, DividerComponent, H1Component}
}
</script>

<template>
  <div class="company-dashboard">
    <H1Component :text="company.name" />

    <DividerComponent />

    <div class="tabs">
      <div class="tab" :class="{'active': tab === 'anket'}" @click="tab = 'anket'">Анкета</div>
      <div class="tab" :class="{'active': tab === 'vacancies'}" @click="tab = 'vacancies'">Вакансии</div>
      <div class="tab" :class="{'active': tab === 'buys'}" @click="tab = 'buys'">Покупки</div>
      <div class="tab" :class="{'active': tab === 'tickets'}" @click="tab = 'tickets'">Обращения</div>
      <div class="tab" :class="{'active': tab === 'reviews'}" @click="tab = 'reviews'">Отзывы</div>
    </div>

    <DividerComponent />

    <CompanyAnket :balance="company.balance" @reload="$emit('reload')" :user="company.user" v-if="tab === 'anket'" />
    <CompanyVacancies :company="company" v-if="tab === 'vacancies'" />
    <CompanyBuyes :company="company" v-if="tab === 'buys'" />
    <CompanyTickets :company="company" v-if="tab === 'tickets'" />
    <CompanyReviews :company="company" v-if="tab === 'reviews'" />

  </div>
</template>

<style scoped lang="scss">
.tabs {
  display: flex;
  grid-gap: 8px;
  margin-bottom: 32px;
  flex-wrap: wrap;
  .tab {
    padding: 10px 16px;
    border-radius: 8px;
    background: #F1F3F9;
    color: #7C7F8A;
    font-size: 14px;
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    transition: 0.2s;
    &.active, &:hover {
      color: #fff;
      background: $accent;
    }
  }
}
</style>