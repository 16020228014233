<template>
  <div class="file-upload-container">
    <h3>Файлы</h3>
    <div class="upload-box">
      <label class="file-label" @click="triggerFileInput">
        <div class="icon">
          <img src="@/assets/icons/upload-file.svg" alt="">
        </div>
      </label>
      <input type="file" multiple ref="fileInput" @change="handleFileUpload" accept=".docx,.pptx" class="file-input" />
      <button type="button" class="upload-button" @click="triggerFileInput">Добавить</button>
    </div>
    <p class="file-info">Файлы до 5MB с форматами .docx, .pptx</p>

    <div v-if="selectedFiles.length" class="file-details">
      <h2>Прикрепленные файлы ({{ selectedFiles.length }}):</h2>
      <p v-for="(item, i) in selectedFiles" :key="i">{{ item.name }} ({{ (item.size / 1024).toFixed(2) }} КБ)</p>
    </div>

    <div v-if="existingFiles.length" class="file-details">
      <h2>Ранее прикрепленные файлы ({{ existingFiles.length }}):</h2>
      <div class="ex-file" v-for="(item, i) in existingFiles" :key="i">
        <a class="default-hover" :href="item.url" target="_blank">{{ item.name }} ({{(item.size / 1024).toFixed(2)}} КБ)</a>
        <button class="default-hover" type="button" @click="$emit('delete-file', item.id)">Удалить</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    existingFiles: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      fileInput: null,
      selectedFiles: [],
    };
  },
  methods: {
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileUpload(event) {
      const files = event.target.files;
      for (const item of files) {
        const file = item;

        if (file instanceof File) {
          if (file && file.size <= 5 * 1024 * 1024 && /\.(docx|pptx|pdf)$/i.test(file.name)) {
            this.selectedFiles.push(file);
          } else {
            this.$root.$emit('success', {
              title: "Ошибка",
              text: "Один или несколько файлов не соответствуют допустимым форматам."
            });
            this.selectedFiles = [];
          }
        }
      }

      this.$emit('file-selected', this.selectedFiles);
    },
    deleteFile(file) {
      const index = this.selectedFiles.indexOf(file);
      if (index !== -1) {
        this.selectedFiles.splice(index, 1);
      }
      this.$emit('delete-file', file);
    },
  },
};
</script>

<style scoped lang="scss">
.file-upload-container {
  .file-details + .file-details {
    margin-top: 16px;
  }
}
.file-details {
  margin-top: 16px;
  .ex-file + .ex-file {
    margin-top: 8px;
  }
  h2 {
    color: #0D0D0D;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 8px;
    line-height: normal;
  }
  p,a {
    width: 100%;
    color: $accent;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .ex-file {
    display: flex;
    justify-content: space-between;
    align-items: center;
    a {
      text-decoration: underline;
      color: #74CD63;
    }

    button {
      text-decoration: none !important;
      border-radius: 8px;
      width: 80px;
      height: 30px;
      background: $danger;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff
    }
  }
  p + p {
    margin-top: 12px;
  }
  a + a {
    margin-top: 12px;
  }
}
h3 {
  color: #2F2B2E;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 4px;
  display: block;
}

.upload-box {
  display: flex;
  align-items: center;
  grid-gap: 16px;
}

.file-label {
  cursor: pointer;
  border-radius: 16px;
  background: #F1F3F9;
  width: 96px;
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: center
}

.file-input {
  display: none;
}

.upload-button {
  padding: 8px 16px;
  border: none;
  cursor: pointer;
  color: #7C7F8A;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 14px */
  height: 30px;
  width: 98px;
  border-radius: 8px;
  background: #F1F3F9;
}

.file-info {
  margin-top: 4px;
  color: #7C7F8A;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 14px */
}
</style>
