<script>
import H1Component from "@/components/ui/H1Component.vue";
import DividerComponent from "@/components/ui/DividerComponent.vue";
import InputComponent from "@/components/ui/InputComponent.vue";

export default {
  data() {
    return {
      email: null,
      vk: null,
      telegram: null,
      viber: null,
    }
  },
  components: {InputComponent, DividerComponent, H1Component},
  methods: {
    save() {
      this.$axios.post('config', {
        contacts_viber: this.viber,
        contacts_vk: this.vk,
        contacts_email: this.email,
        contacts_telegram: this.telegram,
      })
          .then(() => {
            this.$root.$emit('reloadSettings');

            this.$root.$emit('success', {
              title: "Уведомление",
              text: "Успешно обновлено.",
            });
          })
          .catch(() => {
            this.$root.$emit('error', { title: "Ошибка", text: "Unknown error." });
          })
          .finally(() => {
            this.$emit('reload');
          });
    },
  },
  mounted() {
    this.email = this.$root.settings.contacts_email;
    this.vk = this.$root.settings.contacts_vk;
    this.telegram = this.$root.settings.contacts_telegram;
    this.viber = this.$root.settings.contacts_viber;
  }
}
</script>

<template>
  <div class="edit-contacts">
    <H1Component text="Настройки" />

    <DividerComponent />

    <div class="top">
      <h2>Контактная информация</h2>
      <div class="back" @click="$router.back()">
        Назад
      </div>
    </div>

    <DividerComponent />

    <form @submit.prevent.stop="save">

      <InputComponent v-model="email" label="Email" type="email" placeholder="Email" :required="true" />

      <DividerComponent size="16" />

      <InputComponent v-model="telegram" label="Telegram организации" type="text" placeholder="Telegram организации" :required="true" />

      <DividerComponent size="16" />

      <InputComponent v-model="viber" label="Viber" type="text" placeholder="+7 903 777 88 99" :required="true" />

      <DividerComponent size="16" />

      <InputComponent v-model="vk" label="VK" type="text" placeholder="@spahunter" :required="true" />

      <DividerComponent />

      <button class="default-hover" type="submit">Сохранить</button>
    </form>
  </div>
</template>

<style scoped lang="scss">
.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  h2 {
    color: #2F2B2E;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @media (max-width: 768px) {
      font-size: 22px;
    }
  }
  .back {
    color: #B4B8C6;
    cursor: pointer;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 14px */
  }
}
button {
  width: 100%;
  height: 52px;
  background: $accent;
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  border-radius: 16px;
}
.edit-contacts {
  width: 1000px;
  max-width: 100%;
}
</style>