<script>
export default {

}
</script>

<template>
  <div class="modal">
    <div class="modal__body">
      <slot></slot>

      <div class="close default-hover" @click="$emit('close')">
        <img src="@/assets/icons/close.svg" alt="">
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 14;
    width: 100%;
    height: 100%;
    background: rgba(13, 13, 13, 0.80);
    backdrop-filter: blur(8px);
    display: flex;
    overflow-y: auto;

    align-items: center;
    justify-content: center;
    .modal__body {
      border-radius: 32px;
      background: #fff;
      padding: 32px;
      max-width: 95%;
      min-width: 200px;
      max-height: 100vh;
      overflow-y: auto;
      @media (max-width: 768px) {
        padding-top: 30px;

        padding-bottom: 30px;
      }
      @media (max-width: 768px) {
        padding: 20px 10px;
        border-radius: 12px;
      }
      &::-webkit-scrollbar {
        display: none;
      }
      min-height: 100px;
      position: relative;
      .close {
        position: absolute;
        top: 32px;
        right: 32px;
        width: 24px;
        @media (max-width: 768px) {
          top: 22px;
        }
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          max-width: 100%;
          max-height: 100%
        }
      }
    }
  }
</style>