<script>
import {defineComponent} from "vue";
import BaseModal from "@/components/ui/BaseModal.vue";
import DividerComponent from "@/components/ui/DividerComponent.vue";
import InputComponent from "@/components/ui/InputComponent.vue";

export default defineComponent({
  props: {
    object: {
      type: Object,
    }
  },
  data() {
    return {
      name: null,
    }
  },
  methods: {
    go() {
      if (!this.name) return;

      const data = {
        name: this.name,
      };

      let request;

      if (!this.object) {
        request = this.$axios.post('/categories', data)
      } else {
        request = this.$axios.put('/categories/' + this.object.id, data)
      }

      request.then(() => {
        this.$root.$emit('success', {
          title: "Уведомление",
          text: "Профессия была успешно " + (this.object ? 'отредактирована' : 'создана')
        });
        this.$emit('update');
      }).catch(errors => {
        if (Array.isArray(errors)) {
          for (const item in errors) {
            this.$root.$emit('error', {
              title: "Ошибка",
              text: errors[item]
            });
          }
        } else {
          this.$root.$emit('error', {
            title: "Ошибка",
            text: "Возникла ошибка, попробуйте позже."
          });
        }
      })
    }
  },
  mounted() {
    if (this.object) {
      this.name = this.object.name;
    }
  },
  name: "CategoryModal",
  components: {InputComponent, DividerComponent, BaseModal}
})
</script>

<template>
  <BaseModal @close="$emit('close')">
    <div class="balance">
      <div class="modal-title">
        <template v-if="object">Редактирование</template>
        <template v-else>
          Создание
        </template>
        Профессии
      </div>
      <DividerComponent />

      <InputComponent type="text" label="Название" placeholder="Название" v-model="name" />

      <DividerComponent />

      <button class="default-hover" @click="go" :disabled="!name">Создать</button>

    </div>
  </BaseModal>
</template>

<style scoped lang="scss">
.balance {
  width: 500px;
  max-width: 100%;
  button {
    width: 100%;
    height: 52px;
    background: $accent;
    border-radius: 16px;
    color: #fff;
  }
}
</style>