<script>
import CompanyDashboard from "@/components/company/CompanyDashboard.vue";

export default {
  components: {CompanyDashboard},
  data() {
    return {
      entity: null,
    }
  },
  methods: {
    fetch() {
      this.$axios.get('entity/' + this.$route.params.id).then(response => {
        this.entity = response.data.data;
      })
    }
  },
  mounted() {
    this.fetch();
  }
}
</script>

<template>
  <div class="entity" v-if="entity">
    <CompanyDashboard @reload="fetch" :company="entity" v-if="entity.role === 'manager'" />
  </div>
</template>

<style scoped lang="scss">

</style>