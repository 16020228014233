<script>
import H1Component from "@/components/ui/H1Component.vue";
import DividerComponent from "@/components/ui/DividerComponent.vue";
import SeoTemplates from "@/components/seo/SeoTemplates.vue";
import SeoTexts from "@/components/seo/SeoTexts.vue";
import SeoFilters from "@/components/seo/SeoFilters.vue";

export default {
  data() {
    return {
      tab: 'templates',
      config: null,
    }
  },
  methods: {
    fetch() {
      this.$axios.get('config').then(response => {
        this.config = response.data;
      })
    }
  },
  mounted() {
    this.fetch();
  },
  components: {SeoFilters, SeoTexts, SeoTemplates, DividerComponent, H1Component}

}
</script>

<template>
  <div v-if="config" class="seo-page">
    <H1Component text="SEO" />

    <DividerComponent />

    <div class="tabs">
      <div class="tab" :class="{'active': tab === 'templates'}" @click="tab = 'templates'">Шаблоны</div>
      <div class="tab" :class="{'active': tab === 'texts'}" @click="tab = 'texts'">Текста</div>
      <div class="tab" :class="{'active': tab === 'filters'}" @click="tab = 'filters'">SEO для фильтров</div>
    </div>

    <DividerComponent />

    <SeoTemplates v-if="tab === 'templates'" />
    <SeoTexts @reload="fetch" :config="config" v-if="tab === 'texts'" />

    <SeoFilters v-if="tab === 'filters'" />
  </div>
</template>

<style scoped lang="scss">
.tabs {
  display: flex;
  grid-gap: 8px;
  margin-bottom: 32px;
  @media (max-width: 768px) {
    margin-bottom: 16px;
  }
  .tab {
    padding: 10px 16px;
    border-radius: 8px;
    background: #F1F3F9;
    color: #7C7F8A;
    font-size: 14px;
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    transition: 0.2s;
    &.active, &:hover {
      color: #fff;
      background: $accent;
    }
  }
}
</style>