<script>

export default {
  components: {},
  props: {
    has_vip: {
      type: Boolean,
      default: false,
    },
  }
}
</script>

<template>
  <div class="select-type">
    <div class="modal-title">
      Тип продвижения
    </div>
    <div class="types">
      <button class="default-hover" @click="$emit('select', 'top')">
        Поднятие вакансии
      </button>
      <button class="default-hover" :disabled="has_vip" :class="{'disabled': has_vip}" @click="!has_vip ? $emit('select', 'vip') : ''">
        Выделение цветом
      </button>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .select-type {
    .types {
      margin-top: 32px;
      button {
        height: 52px;
        width: 100%;
        border-radius: 16px;
        background: $accent;
        color: #fff;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        @media (max-width: 768px) {
          font-size: 16px;
        }
        &.disabled {
          opacity: 0.7;
          cursor: default;
        }
      }
      button + button {
        margin-top: 16px;
        @media (max-width: 768px) {
          margin-top: 8px;
        }
      }
    }
  }
</style>