<script>
import AnketProgress from "@/components/company/AnketProgress.vue";
import InputComponent from "@/components/ui/InputComponent.vue";
import DividerComponent from "@/components/ui/DividerComponent.vue";
import SecondTitle from "@/components/ui/second-title.vue";
import CheckboxComponent from "@/components/ui/CheckboxComponent.vue";
import BalanceModal from "@/components/company/modals/BalanceModal.vue";
export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
    balance: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      is_verified: false,
      isShowBalanceModal: false,
      isLoading: false,
      type_id: null,
      legal: false,
      localBalance: 0,
      website: '',
      phone: null,
      email: null,
      name: null,
      telegram: '',
      isInit: false,
    }
  },
  watch: {
    balance() {
      this.localBalance = this.balance;
    },
    is_verified() {
      if (!this.isInit) return;

      this.$axios.post('manager/' + this.$route.params.id + '/toggle-verified', {
        is_verified: this.is_verified ? 1 : 0
      }).then(() => {
        this.$root.$emit('success', {
          title: "Уведомление",
          text: "Информация успешно обновлена."
        });
      }).catch(errors => {
        if (Array.isArray(errors)) {
          for (const item in errors) {
            this.$bus.$emit('error', {
              title: "Ошибка",
              text: errors[item]
            });
          }
        } else {
          this.$bus.$emit('error', {
            title: "Ошибка",
            text: "Возникла ошибка, попробуйте позже."
          });
        }
      })
    }
  },
  methods: {
    init() {
      this.localBalance = this.balance;
      this.type_id = this.user.information.type_id;
      this.name = this.user.information.name;
      this.website = this.user.information.website;
      this.legal = this.user.information.is_legal;
      this.telegram = this.user.information.telegram;
      this.phone = this.user.information.phone;
      this.email = this.user.email;
      this.is_verified = this.user.is_verified ? 1 : 0;

      setTimeout(() => {
        this.isInit = true;
      }, 0)
    },
    save() {
      this.isLoading = true;

      const data = new FormData();
      data.append('name', this.name);
      data.append('type_id', this.type_id);
      data.append('website', this.website ? this.website : '');
      data.append('is_legal', this.legal ? 1 : 0);
      data.append('telegram', this.telegram ? this.telegram : '');
      data.append('_method', 'PUT');

      this.$axios.post('manager/'+ this.$route.params.id +'/information', data, {
        headers: {
          'Content-Type' : 'multipart/form-data'
        }
      }).then(() => {
        this.$root.$emit('success', {
          title: "Уведомление",
          text: "Информация успешно обновлена."
        });
        this.$emit('reload')
      }).catch(errors => {
        if (Array.isArray(errors)) {
          for (const item in errors) {
            this.$root.$emit('error', {
              title: "Ошибка",
              text: errors[item]
            });
          }
        } else {
          this.$root.$emit('error', {
            title: "Ошибка",
            text: "Возникла ошибка, попробуйте позже."
          });
        }
      }).finally(() => {
        setTimeout(() => {
          this.isLoading = false;
        }, 200)
      })
    }
  },
  mounted() {
    this.init()
  },
  components: {BalanceModal, CheckboxComponent, SecondTitle, DividerComponent, InputComponent, AnketProgress}

}
</script>

<template>
  <div class="anket">
    <div class="anket__body">

      <second-title text="Управление" />
      <DividerComponent />

      <CheckboxComponent label="Верифицированная компания" :checked="is_verified" @input="is_verified = $event" v-model="is_verified" />
      <DividerComponent size="16" />

      <div class="flex">
        <InputComponent label="Баланс" readonly v-model="localBalance" />
        <button class="default-hover" @click="isShowBalanceModal = true">Начислить баланс</button>
      </div>

      <DividerComponent />

      <div class="top">
        <second-title text="Информация" />
        <AnketProgress :percentage="user.information.percentage" />
      </div>

      <form @submit.prevent.stop="save" class="form">
        <InputComponent label="Название организации" v-model="name" placeholder="Название организации" :required="true" />

        <DividerComponent size="16" />

        <label for="">Тип организации</label>
        <div class="types" v-if="$root?.settings?.company_types">
          <div class="type" v-for="item in $root.settings.company_types" :key="item.id" :class="{'active': type_id === item.id}" @click="type_id = item.id">{{ item.name }}</div>
        </div>

        <DividerComponent size="16" />

        <InputComponent label="Сайт" v-model="website" type="url" placeholder="https://example.com" />

        <DividerComponent size="16" />

        <label for="">Юридическое лицо</label>
        <div class="types">
          <div class="type" :class="{'active': !!legal}" @click="legal = true">Да</div>
          <div class="type" :class="{'active': !legal}" @click="legal = false">Нет</div>
        </div>

        <DividerComponent size="16" />

        <InputComponent
            v-model="phone"
            :disabled="true"
            masked="+42 ### ###-##-##"
            label="Номер телефона"
            placeholder="+42" />

        <DividerComponent size="16" />

        <InputComponent
            v-model="email"
            :disabled="true"
            label="Email"
            placeholder="Email" />

        <DividerComponent size="16" />

        <InputComponent label="Telegram организации" type="url" v-model="telegram" placeholder="https://t.me/example" />
        <div class="note">
          Телеграм-канал компании
        </div>

        <DividerComponent />

        <div class="actions">
          <button :disabled="!type_id || !name || isLoading" :class="{'loading': isLoading}" class="save default-hover">
            Сохранить изменения
          </button>
        </div>

      </form>
    </div>

    <BalanceModal v-if="isShowBalanceModal" :userId="user.id" @close="isShowBalanceModal = false" @update="isShowBalanceModal = false, $emit('reload')" />
  </div>
</template>

<style scoped lang="scss">
.flex {
  display: flex;
  grid-gap: 16px;
  align-items: flex-end;
  button {
    width: 200px;
    min-width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 52px;
    border-radius: 16px;
    color: #fff;
    background: $accent;
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    display: grid;
    button {
      width: 100%;
      border-radius: 10px;
    }
  }
}
.anket {
  .anket__body {
    .top {
      margin-bottom: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media (max-width: 575px) {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        .progress {
          margin-top: 8px;
        }
      }
    }
    .form {
      .note {
        color: #7C7F8A;
        margin-top: 4px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 14px */
        @media (max-width: 768px) {
          font-size: 12px;
        }
      }
      label {
        color: #2F2B2E;
        display: block;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        @media (max-width: 768px) {
          font-size: 14px;
        }
      }
      .types {
        margin-top: 8px;
        display: flex;
        grid-gap: 8px;
        @media (max-width: 768px) {
          flex-wrap: wrap;
        }
        .type {
          padding: 10px 16px;
          border-radius: 8px;
          background: #F1F3F9;
          cursor: pointer;
          color: #7C7F8A;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          transition: 0.2s;
          line-height: 100%; /* 14px */
          &.active, &:hover {
            color: #fff;
            background: $accent;
          }
        }
      }
      .actions {
        grid-gap: 16px;
        display: flex;
        @media (max-width: 768px) {
          grid-gap: 8px;
        }
        button {
          height: 52px;
          border-radius: 16px;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          @media (max-width: 768px) {
            font-size: 16px;
          }
        }
        .reset {
          flex: 1;
          background: #F1F3F9;
          color: #2F2B2E;
        }
        .save {
          flex: 2;
          color: #fff;
          background: $accent;

        }
      }
    }
  }
}
</style>