<script>
export default {
  components: {},
  props: {
    withImage: {
      type: Boolean,
      default: false,
    },
    nullable: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
    },
    isSearchable: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Выберите'
    },
    useFirst: {
      type: Boolean,
      default: true,
    },
    value: {
      type: Object,
    }
  },
  watch: {
    value() {
      this.close();
    },
    isOpenDropdown() {
      if(!this.isOpenDropdown) {
        this.search = null;
      }
    }
  },
  data() {
    return {
      search: null,
      isOpenDropdown: false,
    }
  },
  computed: {
    getOptions() {
      if (!this.isSearchable || !this.search) {
        return this.options;
      }

      return this.options.filter(item => item.name.toLowerCase().includes(this.search.toLowerCase()))
    },
    defaultSelected() {
      if (!this.useFirst) {
        return this.value;
      } else {
        return this.value || (!this.nullable ? (this.options[0] || null) : null) || null;
      }
    }
  },
  methods: {
    close() {
      this.isOpenDropdown = false;
    }
  },
  mounted() {
    document.addEventListener('click', this.close);
    this.$emit('selected', this.defaultSelected);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.close);
  },
  name: "SelectDropdown",
}
</script>

<template>
  <div class="select-dropdown">
    <div class="select-dropdown__label" v-if="label">
      {{ label }}
    </div>
    <div class="select-dropdown__body">
      <div class="select-dropdown__body-selected" @click.prevent.stop="isOpenDropdown = !isOpenDropdown">
        <span :class="{'placeholder': !defaultSelected}">
            <img v-if="withImage && defaultSelected" :src="`https://flagsapi.com/${defaultSelected.code}/flat/64.png`" alt="">
            {{ defaultSelected ? defaultSelected.name : placeholder }}
        </span>
        <div class="select-dropdown__body-selected-icon" :class="{'rotate': isOpenDropdown}">
          <img src="../../assets/icons/arrow-down.svg" alt="">
        </div>
      </div>
      <transition name="fade">
        <div v-if="isOpenDropdown" class="select-dropdown__body-list">
          <div v-if="isSearchable" class="select-dropdown__body-list_item">
            <input v-model="search" type="text" placeholder="Поиск" @click.prevent.stop>
          </div>
          <div @click.prevent.stop="$emit('selected', item)" class="select-dropdown__body-list_item" v-for="(item) in getOptions" :key="item.id">
            <img v-if="withImage" :src="`https://flagsapi.com/${item.code}/flat/64.png`" alt="">
            {{ item.name }}
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<style scoped lang="scss">
.select-dropdown {
  .select-dropdown__label {
    width: 100%;
    color: #2F2B2E;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 4px;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
  .select-dropdown__body {
    position: relative;
    .select-dropdown__body-selected {
      border-radius: 12px;
      background: #F1F3F9;
      height: 52px;
      padding: 0px 16px;
      position: relative;
      cursor: pointer;
      display: flex;
      align-items: center;

      transition: 0.2s;

      &:hover {
        opacity: 0.8;
      }
      span {
        color: #2F2B2E;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        display: flex;
        align-items: center;
        &.placeholder {
          color: #7C7F8A;
        }
        img {
          width: 16px;
          margin-right: 8px;
        }
        @media (max-width: 768px) {
          font-size: 14px;
        }
      }
      .select-dropdown__body-selected-icon {
        position: absolute;
        right: 16px;
        top: 0;
        height: 100%;
        bottom: 0;
        display: flex;
        align-items: center;
        img {
          transition: 0.2s;
        }
        &.rotate {
          img {
            transform: rotate(180deg);
          }
        }
      }
    }
    .select-dropdown__body-list {
      width: 100%;
      position: absolute;
      top: calc(100% + 4px);
      left: 0;
      min-width: 200px;
      background: #F1F3F9;
      z-index: 45;
      border-radius: 12px;
      padding: 4px;
      max-height: 300px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 3px;
      }
      &::-webkit-scrollbar-track {
        background: #F1F3F9;
      }
      &::-webkit-scrollbar-thumb {
        background: #DEE0E5;
      }
      .select-dropdown__body-list_item {
        padding: 8px 16px;
        border-radius: 12px;
        color: #7C7F8A;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding-left: 16px;
        background: #fff;
        cursor: pointer;
        display: flex;
        align-items: center;
        transition: 0.2s;
        input {
          width: calc(100% + 12px);
          border: 1px solid rgba(0,0,0, 0.3);
          padding: 12px 16px;
          border-radius: 12px;
          margin-left: -6px;
          margin-right: -6px;
        }
        &:hover {
          opacity: 0.7;
        }
        img {
          width: 16px;
          margin-right: 8px;
        }
      }
      .select-dropdown__body-list_item + .select-dropdown__body-list_item {
        margin-top: 4px;
      }
    }
  }
}
</style>
