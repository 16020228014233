import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: localStorage.getItem('token') || null,
    user: null,
  },
  getters: {
    token: state => state.token,
    user: state => state.user,
  },
  mutations: {
  },
  modules: {
  }
})
