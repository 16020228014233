<script>
import H1Component from "@/components/ui/H1Component.vue";
import DividerComponent from "@/components/ui/DividerComponent.vue";
import TableComponent from "@/components/ui/TableComponent.vue";
import InputComponent from "@/components/ui/InputComponent.vue";

export default {
  data() {
    return {
      columns: [
        {
          key: 'type',
          label: "Тип"
        },
        {
          key: 'cost',
          label: 'Стоимость'
        },
        {
          key: 'duration',
          label: "Длительность (часы)"
        }
      ],
      vip_price: null,
      vip_duration: null,
      once_price_top: null,
      once_duration_top: null,
    }
  },
  methods: {
    save(data) {
      this.$axios.post('config', data)
          .then(() => {
            this.$root.$emit('reloadSettings');

            this.$root.$emit('success', {
              title: "Уведомление",
              text: "Успешно обновлено.",
            });
          })
          .catch(() => {
            this.$root.$emit('error', { title: "Ошибка", text: "Unknown error." });
          })
          .finally(() => {
            this.$emit('reload');
          });
    },
    init() {
      this.vip_price = this.$root.settings.vip_price;
      this.vip_duration = this.$root.settings.vip_duration;
      this.once_price_top = this.$root.settings.once_price_top;
      this.once_duration_top = this.$root.settings.once_duration_top;
    }
  },
  components: {InputComponent, TableComponent, DividerComponent, H1Component},
  mounted() {
    this.init();
  }
}
</script>

<template>
  <div class="settings-boost">
    <H1Component text="Настройки" />

    <DividerComponent />

    <div class="top">
      <h2>Продвижение</h2>
      <div class="back" @click="$router.back()">
        Назад
      </div>
    </div>

    <div class="block">
      <div class="tbl">
        <TableComponent :columns="columns" :items="[
        {
          type: 'Выделение цветом',
          cost: vip_price,
          duration: vip_duration,
        }
      ]" />
      </div>

      <DividerComponent size="16" />

      <div class="input-group">
        <InputComponent v-model="vip_duration" placeholder="Период действия (часы)" label="Период действия (часы)" :required="true" type="number" />
        <InputComponent v-model="vip_price" placeholder="Стоимость" label="Стоимость" :required="true" type="number" />
      </div>

      <DividerComponent size="16" />

      <button @click="save({
        vip_duration: vip_duration,
        vip_price: vip_price,
      })" class="send default-hover">Применить</button>

    </div>
    <div class="block">
      <div class="tbl">
        <TableComponent :columns="columns" :items="[
        {
          type: 'Разовое поднятие',
          cost: once_price_top,
          duration: once_duration_top,
        }
      ]" />
      </div>

      <DividerComponent size="16" />

      <div class="input-group">
        <InputComponent v-model="once_duration_top" placeholder="Период действия (часы)" label="Период действия (часы)" :required="true" type="number" />
        <InputComponent v-model="once_price_top" placeholder="Стоимость" label="Стоимость" :required="true" type="number" />
      </div>

      <DividerComponent size="16" />

      <button @click="save({
        once_duration_top: once_duration_top,
        once_price_top: once_price_top,
      })" class="send default-hover">Применить</button>

    </div>
  </div>
</template>

<style scoped lang="scss">
.tbl {
  width: 100%;
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  ::v-deep {
    .table__body {
      min-width: 500px !important;
    }
  }
}
.settings-boost {
  width: 1300px;
  max-width: 100%;

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h2 {
      color: #2F2B2E;
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      @media (max-width: 768px) {
        font-size: 22px;
      }
    }
    .back {
      color: #B4B8C6;
      cursor: pointer;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 14px */
    }
  }
  .block {
    .input-group {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 16px;
      @media (max-width: 768px) {
        ::v-deep {
          .input {
            label {
              font-size: 12px !important;
            }
          }
        }
      }

    }
    .send {
      width: 100%;
      height: 52px;
      background: $accent;
      border-radius: 16px;
      color: #fff;
      font-size: 16px;
      font-weight: 500;
      @media (max-width: 768px) {
        font-size: 14px;
        height: 44px;
        border-radius: 10px;
      }
    }
  }
  .block + .block {
    margin-top: 32px;
  }
}
</style>