<script>
import H1Component from "@/components/ui/H1Component.vue";
import DividerComponent from "@/components/ui/DividerComponent.vue";
import TableComponent from "@/components/ui/TableComponent.vue";
import AddButtonComponent from "@/components/ui/AddButtonComponent.vue";
import PaginationComponent from "@/components/ui/PaginationComponent.vue";
import CompanyTypeModal from "@/components/company-types/modals/CompanyTypeModal.vue";
export default {
  data() {
    return {
      isLoading: true,
      editObject: null,
      isShowFaqModal: false,
      columns: [
        {
          label: 'Название',
          key: 'name',
        },
        {
          label: 'Зарегистрированных организаций',
          key: 'companies_count',
          styles: {
            color: '#8C12D6'
          },
        },
        {
          label: '',
          key: 'actions'
        }
      ],
      page: 1,
      pages: 1,
      types: [],
    }
  },
  methods: {
    async goDelete(item) {
      const id = item.id;

      const confirmed = await this.$root.$confirm("Вы уверены, что хотите удалить Тип организации? Вы сможете удалить Тип организации только если в нем отсутствуют компании.");

      if (!confirmed) return;

      this.$axios.delete('company-types/' + id).then(() => {

        const index = this.types.findIndex(item => item.id == id);

        if (index > -1)  {
          this.types.splice(index, 1);
        }

        this.$root.$emit('success', {
          title: "Уведомление",
          text: "Тип организации успешно удален"
        });
      }).catch(errors => {
        if (Array.isArray(errors)) {
          for (const item in errors) {
            this.$root.$emit('error', {
              title: "Ошибка",
              text: errors[item]
            });
          }
        } else {
          this.$root.$emit('error', {
            title: "Ошибка",
            text: "Возникла ошибка, попробуйте позже."
          });
        }
      })
    },
    setPage(page) {
      this.page = page;
      this.fetch();
    },
    fetch() {
      this.$axios.get('company-types', {
        params: {
          page: this.page,
          per_page: 30,
        }
      }).then(response => {
        this.types = response.data.data;
        this.page = response.data.current_page;
        this.pages = response.data.last_page;
      }).finally(() => {
        setTimeout(() => {
          this.isLoading = false;
        }, 100)
      })
    }
  },
  mounted() {
    this.fetch();
  },
  components: {
    PaginationComponent,
    AddButtonComponent,
    CompanyTypeModal,
    TableComponent, DividerComponent, H1Component
  }
}
</script>

<template>
  <div class="page users">
    <div class="top">
      <H1Component text="Типы организаций" />

      <AddButtonComponent @click="isShowFaqModal = true" text="Добавить" />
    </div>

    <DividerComponent />

    <div class="tbl">
      <TableComponent
          :columns="columns"
          :items="types"
          :deletable="true"
          :editable="true"
          @edit="editObject = $event, isShowFaqModal = true"
          @delete="goDelete"
          :is-loading="isLoading"
      />
    </div>
    <DividerComponent />

    <PaginationComponent :pages="pages" :page="page" @change="setPage" />

    <CompanyTypeModal :object="editObject" @update="isShowFaqModal = false, editObject = null, fetch()" @close="isShowFaqModal = false, editObject = null" v-if="isShowFaqModal" />
  </div>
</template>

<style scoped lang="scss">
.top {
  @media (max-width: 768px) {
    display: grid !important;
    grid-gap: 16px;
    grid-template-columns: repeat(1, 1fr);
    .buttons {
      width: 100%;
    }
  }
}
.tbl {
  width: 100%;
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  ::v-deep {
    .table__body {
      min-width: 100%;
    }
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
}
.top {
  display: flex;
  justify-content: space-between;
  align-items: center;

}
.filters {
  display: flex;
  grid-gap: 16px;
}
</style>