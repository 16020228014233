<script>
export default {
  props: {
    notification: {
      type: Object,
      required: true
    }
  },
  name: "NotificationItem",
  mounted () {
    setTimeout(() => {
      this.$emit('close');
    }, 7500);
  }
}
</script>

<template>
<div class="notification">
  <div class="notification-shadow" :class="[notification.type]"></div>
  <div class="notification-body">
    <div class="notification-body__status" :class="[notification.type]">
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
        <path d="M25.7866 19.82L24.4533 17.6067C24.1733 17.1134 23.92 16.18 23.92 15.6334V12.26C23.92 9.12669 22.08 6.42002 19.4266 5.15335C18.7333 3.92669 17.4533 3.16669 15.9866 3.16669C14.5333 3.16669 13.2266 3.95335 12.5333 5.19335C9.9333 6.48669 8.1333 9.16669 8.1333 12.26V15.6334C8.1333 16.18 7.87996 17.1134 7.59996 17.5934L6.2533 19.82C5.71996 20.7134 5.59996 21.7 5.9333 22.6067C6.2533 23.5 7.0133 24.1934 7.99996 24.5267C10.5866 25.4067 13.3066 25.8334 16.0266 25.8334C18.7466 25.8334 21.4666 25.4067 24.0533 24.54C24.9866 24.2334 25.7066 23.5267 26.0533 22.6067C26.4 21.6867 26.3066 20.6734 25.7866 19.82Z" fill="#8C12D6"/>
        <path d="M19.7733 27.18C19.2133 28.7267 17.7333 29.8334 16 29.8334C14.9467 29.8334 13.9067 29.4067 13.1733 28.6467C12.7467 28.2467 12.4267 27.7134 12.24 27.1667C12.4133 27.1934 12.5867 27.2067 12.7733 27.2334C13.08 27.2734 13.4 27.3134 13.72 27.34C14.48 27.4067 15.2533 27.4467 16.0267 27.4467C16.7867 27.4467 17.5467 27.4067 18.2933 27.34C18.5733 27.3134 18.8533 27.3 19.12 27.26C19.3333 27.2334 19.5467 27.2067 19.7733 27.18Z" fill="#8C12D6"/>
      </svg>
    </div>
    <div class="notification-body__info">
      <strong>{{ notification.title }}</strong>
      <span>{{ notification.text }}</span>
    </div>
    <div class="notification-body__close default-hover" @click.prevent.stop="$emit('close')">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M17 7L7 17M7 7L17 17" stroke="#918FBB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
  .notification {
    padding: 2px;
    background: #918FBB;
    border-radius: 12px;
    position: relative;
    overflow: hidden;
    .notification-body {
      border-radius: 12px;
      position: relative;
      z-index: 2;
      background: #fff;
      display: flex;
      align-items: center;
      padding: 12px;
      .notification-body__status {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 40px;
        margin-right: 12px;
      }
      .notification-body__info {
        flex-grow: 1;
        strong {
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: -0.24px;
          margin-bottom: 3px;
          display: block;
          color: $accent;
        }
        span {
          display: block;
          width: 100%;
          font-size: 14px;
          font-style: normal;
          line-height: normal;
          letter-spacing: -0.28px;
          padding-right: 40px;

          color: #0D0D0D;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
      .notification-body__close {
        min-width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
      }
    }
    .notification-shadow {
      position: absolute;
      height: 100%;
      border-radius: 12px;
      left: 0;
      width: 1px;
      top: 0;
      animation: fill 7s forwards;
      background: $accent;
    }
    width: 100%;
  }

  @keyframes fill {
    0% {
      width: 1px;
    }
    100% {
      width: 100%;
    }

  }
</style>
