<template>
  <div class="pagination" v-if="pages > 1">
    <div class="pagination__item img" @click="setPage(page - 1)" v-if="page > 1">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M11.2567 11.5297C11.5165 11.7895 11.9375 11.7895 12.1972 11.5297C12.4568 11.2702 12.457 10.8495 12.1978 10.5897L9.61366 8L12.1978 5.41026C12.457 5.15046 12.4568 4.72977 12.1972 4.47026C11.9375 4.21054 11.5165 4.21054 11.2567 4.47026L8.4341 7.29289C8.04357 7.68342 8.04357 8.31658 8.4341 8.70711L11.2567 11.5297Z" fill="#B0B4C3"/>
        <path d="M6.86318 11.5297C7.1229 11.7895 7.54398 11.7895 7.80369 11.5297C8.06321 11.2702 8.06344 10.8495 7.80421 10.5897L5.2201 8L7.80421 5.41026C8.06344 5.15046 8.06321 4.72977 7.80369 4.47026C7.54398 4.21054 7.1229 4.21054 6.86318 4.47026L4.04054 7.29289C3.65002 7.68342 3.65002 8.31658 4.04054 8.70711L6.86318 11.5297Z" fill="#B0B4C3"/>
      </svg>
    </div>

    <div class="pagination__item" @click="setPage(1)" v-if="page > 3">
      1
    </div>

    <div class="pagination__item" v-if="page > 4">...</div>

    <div class="pagination__item"
         v-for="i in getDisplayedPages()"
         :key="i"
         @click="setPage(i)"
         :class="{'active': page === i}">
      {{ i }}
    </div>

    <div class="pagination__item" v-if="page < pages - 3">...</div>

    <div class="pagination__item" @click="setPage(pages)" v-if="page < pages - 2">
      {{ pages }}
    </div>

    <div class="pagination__item img" @click="setPage(page + 1)" v-if="page < pages">
      <!-- SVG for right arrow -->
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M4.27301 4L3.33301 4.94L6.38634 8L3.33301 11.06L4.27301 12L8.27301 8L4.27301 4Z" fill="#B0B4C3"/>
        <path d="M9.13682 4.47026C8.8771 4.21054 8.45602 4.21054 8.19631 4.47026C7.93679 4.72977 7.93656 5.15046 8.19579 5.41026L10.7799 8L8.19579 10.5897C7.93656 10.8495 7.93679 11.2702 8.19631 11.5297C8.45602 11.7895 8.8771 11.7895 9.13682 11.5297L11.9595 8.70711C12.35 8.31658 12.35 7.68342 11.9595 7.29289L9.13682 4.47026Z" fill="#B0B4C3"/>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pages: {
      type: Number,
    },
    page: {
      type: Number,
    }
  },
  methods: {
    setPage (page) {
      if (page > this.pages || page < 1) {
        return;
      }

      window.scrollTo(0, 0);

      this.$emit('change', page);
    },
    getDisplayedPages() {
      const displayedPages = [];
      const maxAdjacent = 1; // Количество страниц, отображаемых рядом с текущей
      const startPage = Math.max(1, this.page - maxAdjacent);
      const endPage = Math.min(this.pages - 1, this.page + maxAdjacent);

      // Добавляем страницы до текущей
      for (let i = startPage; i <= endPage; i++) {
        displayedPages.push(i);
      }


      return displayedPages;
    }
  },
  name: "PaginationComponent"
}
</script>
<style lang="scss" scoped>
.pagination {
  display: flex;
  width: 100%;
  grid-gap: 5px;
  @media (max-width: 768px) {
    margin-top: 24px;
  }
  .pagination__item {
    height: 42px;
    width: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid #F1F3F9;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: #2F2B2E;
    line-height: normal;
    cursor: pointer;
    transition: 0.2s;
    &.rotate {
      svg {
        transform: rotate(180deg);
      }
    }
    &.disabled {
      cursor: default !important;
    }

    &.active, &:hover:not(.disabled) {
      background: $accent;
      color: #fff;
    }
    &.active {
      cursor: default;
    }
    &.img {
      @media (max-width: 450px) {
        display: none;
      }
      &:hover {
        svg {
          path {
            stroke: #fff;
          }
        }
      }
    }
  }
}
</style>
