<script>
export default {
  props: {
    reverse: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
    },
    accentText: {
      type: String,
    }
  }
}
</script>

<template>
  <h2>
    <template v-if="reverse">
      <em v-if="accentText">{{ accentText }}</em>
      {{ text }}
    </template>
    <template v-else>
      {{ text }}
      <em v-if="accentText">{{ accentText }}</em>
    </template>
  </h2>
</template>

<style scoped lang="scss">
  h2, em {
    color: #2F2B2E;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @media (max-width: 768px) {
      font-size: 24px;
    }
    em {
      color: $accent;
    }
  }
</style>