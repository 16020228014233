<script>
import { VueEditor } from 'vue2-quill-editor'
import UiToggler from "@/components/ui/UiToggler.vue";
import InputComponent from "@/components/ui/InputComponent.vue";
import DividerComponent from "@/components/ui/DividerComponent.vue";
export default {
  props: {
    config: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      localConfig: null,
    }
  },
  methods: {
    save(data) {
      this.$axios.post('config', data)
          .then(() => {
            this.$root.$emit('success', {
              title: "Уведомление",
              text: "Успешно обновлено.",
            });
          })
          .catch(() => {
            this.$root.$emit('error', { title: "Ошибка", text: "Unknown error." });
          })
          .finally(() => {
            this.$emit('reload');
          });
    },
  },
  mounted() {
    this.localConfig = JSON.parse(JSON.stringify(this.config));
  },
  components: {
    DividerComponent,
    InputComponent,
    UiToggler,
    VueEditor
  }
}
</script>

<template>
  <div class="seo-texts">
    <UiToggler text="Главная страница">

      <div class="block">
        <InputComponent :placeholder="localConfig.texts_main_h1_1.label" :label="localConfig.texts_main_h1_1.label" v-model="localConfig.texts_main_h1_1.value" />
      </div>
      <div class="block">
        <InputComponent :placeholder="localConfig.texts_main_h1_2.label" :label="localConfig.texts_main_h1_2.label" v-model="localConfig.texts_main_h1_2.value" />
      </div>

      <div class="block">
        <InputComponent :placeholder="localConfig.texts_main_why_1_title.label" :label="localConfig.texts_main_why_1_title.label" v-model="localConfig.texts_main_why_1_title.value" />
      </div>
      <div class="block">
        <InputComponent :placeholder="localConfig.texts_main_why_1_text.label" :label="localConfig.texts_main_why_1_text.label" v-model="localConfig.texts_main_why_1_text.value" />
      </div>

      <div class="block">
        <InputComponent :placeholder="localConfig.texts_main_why_2_title.label" :label="localConfig.texts_main_why_2_title.label" v-model="localConfig.texts_main_why_2_title.value" />
      </div>
      <div class="block">
        <InputComponent :placeholder="localConfig.texts_main_why_2_text.label" :label="localConfig.texts_main_why_2_text.label" v-model="localConfig.texts_main_why_2_text.value" />
      </div>

      <div class="block">
        <InputComponent :placeholder="localConfig.texts_main_why_3_title.label" :label="localConfig.texts_main_why_3_title.label" v-model="localConfig.texts_main_why_3_title.value" />
      </div>
      <div class="block">
        <InputComponent :placeholder="localConfig.texts_main_why_3_text.label" :label="localConfig.texts_main_why_3_text.label" v-model="localConfig.texts_main_why_3_text.value" />
      </div>

      <div class="block">
        <label for="">{{ localConfig.texts_main_bottom_text.label }}</label>
        <vue-editor
            contentType="html"
            ref="main_1"
            v-model="localConfig.texts_main_bottom_text.value"
            toolbar="full"
            theme="snow"
        ></vue-editor>
      </div>

      <DividerComponent />

      <button class="save default-hover"
              @click="
                save({
                  texts_main_h1_1: localConfig.texts_main_h1_1.value,
                  texts_main_h1_2: localConfig.texts_main_h1_2.value,
                  texts_main_why_1_title: localConfig.texts_main_why_1_title.value,
                  texts_main_why_1_text: localConfig.texts_main_why_1_text.value,
                  texts_main_why_2_title: localConfig.texts_main_why_2_title.value,
                  texts_main_why_2_text: localConfig.texts_main_why_2_text.value,
                  texts_main_why_3_title: localConfig.texts_main_why_3_title.value,
                  texts_main_why_3_text: localConfig.texts_main_why_3_text.value,
                  texts_main_bottom_text: localConfig.texts_main_bottom_text.value,
                })
              "
      >Сохранить</button>
    </UiToggler>
    <UiToggler text="Условия предоставления услуг">
      <div class="block">
        <label for="">{{ localConfig.terms_of_service_text.label }}</label>
        <vue-editor
            contentType="html"
            ref="main_1"
            v-model="localConfig.terms_of_service_text.value"
            toolbar="full"
            theme="snow"
        ></vue-editor>
      </div>

      <DividerComponent />

      <button class="save default-hover"
              @click="
                save({
                  terms_of_service_text: localConfig.terms_of_service_text.value,
                })
              "
      >Сохранить</button>
    </UiToggler>
    <UiToggler text="Политика конфиденциальности">
      <div class="block">
        <label for="">{{ localConfig.privacy_policy_text.label }}</label>
        <vue-editor
            contentType="html"
            ref="main_1"
            v-model="localConfig.privacy_policy_text.value"
            toolbar="full"
            theme="snow"
        ></vue-editor>
      </div>

      <DividerComponent />

      <button class="save default-hover"
              @click="
                save({
                  privacy_policy_text: localConfig.privacy_policy_text.value,
                })
              "
      >Сохранить</button>
    </UiToggler>
  </div>
</template>

<style scoped lang="scss">
.save {
  width: 160px;
  height: 52px;
  border-radius: 16px;
  color: #fff;
  background: $accent;
  margin-left: auto;
  display: block;
}
.seo-texts {
  .toggler + .toggler {
    margin-top: 8px;
  }
}
.block {
  label {
    display: block;
    margin-bottom: 8px;
    color: var(--Text-Main-Text-Color, #2F2B2E);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.seo-texts .block + .block {
  margin-top: 18px;
}
</style>