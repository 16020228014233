<script>
import ApplyItem from "@/components/vacancy/ApplyItem.vue";
import EmptyState from "@/components/ui/EmptyState.vue";
import PaginationComponent from "@/components/ui/PaginationComponent.vue";
import DividerComponent from "@/components/ui/DividerComponent.vue";
export default {
  data() {
    return {
      tab: 'created',
      page: 1,
      total_pages: 1,
      applies: [],
      isLoading: false,
    }
  },
  components: {DividerComponent, EmptyState, PaginationComponent, ApplyItem},
  props: {
    id: {
      type: Number,
      required: true,
    }
  },
  watch: {
    tab() {
      this.page = 1;
      this.total_pages = 1;
      this.fetchApplies()
    }
  },
  methods: {
    setPage(page) {
      this.page = page;
      this.fetchApplies(true)
    },
    fetchApplies(withoutLoading = false) {
      if (!withoutLoading) {
        this.isLoading = true;
      }
      this.$axios.get('vacancy/' + this.id + '/applies', {
        params: {
          page: this.page,
          status: this.tab,
          per_page: 5,
        }
      }).then(response => {
        this.applies = response.data.data;
        this.total_pages = response.data.meta.last_page;
        this.page = response.data.meta.current_page;
      }).finally(() => {
        setTimeout(() => {
          this.isLoading = false;
        }, 300)
      })
    }
  },
  mounted() {
    this.fetchApplies();
  }
}
</script>

<template>
  <div class="vacancy-applies">
    <div class="tabs">
      <div class="tab" :class="{'active': tab === 'created'}" @click="tab = 'created'">Ожидают</div>
      <div class="tab" :class="{'active': tab === 'approved'}" @click="tab = 'approved'">Активные</div>
    </div>
    <EmptyState text="Загружаем вакансии..." v-if="isLoading" />
    <EmptyState text="Нет откликов в данном статусе" v-if="(!applies || !applies.length) && !isLoading" />
    <div class="applies-list" v-if="!isLoading">
      <ApplyItem v-for="(apply, i) in applies" :key="i" :item="apply" />
    </div>

    <DividerComponent />

    <PaginationComponent @change="setPage" :pages="total_pages" :page="page" />
  </div>

</template>

<style scoped lang="scss">
.tabs {
  display: flex;
  grid-gap: 8px;
  margin-bottom: 32px;
  .tab {
    padding: 10px 16px;
    border-radius: 8px;
    background: #F1F3F9;
    color: #7C7F8A;
    font-size: 14px;
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    transition: 0.2s;
    &.active, &:hover {
      color: #fff;
      background: $accent;
    }
  }
}
  .applies-list {
    display: grid;
    grid-gap: 16px;
  }
</style>