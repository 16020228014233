<script>

import {defineComponent} from "vue";
import BaseModal from "@/components/ui/BaseModal.vue";
import DividerComponent from "@/components/ui/DividerComponent.vue";
import InputComponent from "@/components/ui/InputComponent.vue";
import CheckboxComponent from "@/components/ui/CheckboxComponent.vue";

export default defineComponent({
  props: {
    object: {
      type: Object,
    }
  },
  data() {
    return {
      duration: null,
      number: 1,
      period: 'месяц',
      cost: 1000,
      discount: false,
      discount_percent: 10,
      cost_with_discount: 900,
      is_accent: false,
    }
  },
  methods: {
    add() {
      const data = {
        duration: this.duration,
        number: this.number,
        period: this.period,
        cost: this.cost,
        is_accent: this.is_accent
      };

      if (this.discount) {
        data.discount = this.discount_percent;
        data.cost_with_discount = this.cost_with_discount;
      }

      let request;

      if (!this.object) {
        request = this.$axios.post('tariffs', data);
      } else {
        request = this.$axios.put('tariffs/' + this.object.id, data)
      }

      request.then((response) => {
        this.$root.$emit('success', {
          title: "Уведомление",
          text: "Тариф успешно " + (this.object ? 'отредактирован' : 'создан')
        });


        if (!this.object) {
          this.$emit('add', response.data);
        } else {
          this.$emit('update', response.data);
        }

      }).catch(errors => {
        if (Array.isArray(errors)) {
          for (const item in errors) {
            this.$root.$emit('error', {
              title: "Ошибка",
              text: errors[item]
            });
          }
        } else {
          this.$root.$emit('error', {
            title: "Ошибка",
            text: "Возникла ошибка, попробуйте позже."
          });
        }
      })
    }
  },
  mounted() {
    if (this.object) {
      this.duration = this.object.duration
      this.number = this.object.number
      this.period = this.object.period
      this.cost = this.object.cost
      this.discount = this.object.discount > 0 ? 1 : 0
      this.discount_percent = this.object.discount
      this.cost_with_discount = this.object.cost_with_discount
      this.is_accent = this.object.is_accent
    }
  },
  components: {CheckboxComponent, InputComponent, DividerComponent, BaseModal}
})
</script>

<template>
  <BaseModal @close="$emit('close')">
    <form @submit.prevent.stop="add" class="tariffs-modal">
      <div class="modal-title">
        <template v-if="object">Редактирование</template>
        <template v-else>Создание</template>
        подписки
      </div>

      <DividerComponent />

      <div class="preview" :class="{'accent': is_accent}">
        <div class="left">
          <strong>{{ number }}</strong>
          <span>{{ period }}</span>
        </div>
        <div class="right">
          <div class="cost-with-discount" v-if="discount">
            {{ Number(cost_with_discount).toLocaleString('ru-RU') }} ₽
          </div>
          <div class="cost">
            {{ Number(cost).toLocaleString('ru-RU') }} ₽
          </div>
          <div class="discount" v-if="discount">
            Выгода {{ discount_percent }}%
          </div>
        </div>
      </div>

      <div class="note">
        ПРЕДПРОСМОТР
      </div>

      <DividerComponent />

      <InputComponent :required="true" v-model="duration" placeholder="Длительность в часах" label="Длительность в часах" type="number" />

      <DividerComponent />

      <InputComponent :required="true" v-model="number" placeholder="Число" label="Число" type="number" />

      <DividerComponent size="16" />

      <InputComponent :required="true" v-model="period" placeholder="Вспомогательный текст" label="Вспомогательный текст" type="text" />

      <DividerComponent size="16" />

      <InputComponent :required="true" v-model="cost" placeholder="Стоимость" label="Стоимость" type="number" />

      <DividerComponent size="16" />

      <CheckboxComponent :checked="discount" @input="discount = $event" label="Скидка" />

      <template v-if="discount">
        <DividerComponent size="16" />
        <InputComponent :required="true" v-model="discount_percent" placeholder="Скидка %" label="Скидка %" type="number" />

        <DividerComponent size="16" />

        <InputComponent :required="true" v-model="cost_with_discount" placeholder="Стоимость со скидкой" label="Стоимость со скидкой" type="number" />
      </template>

      <DividerComponent size="16" />

      <CheckboxComponent :checked="is_accent" @input="is_accent = $event" label="Акцент" />

      <DividerComponent />

      <button type="submit" class="add default-hover">Добавить</button>
    </form>
  </BaseModal>
</template>

<style scoped lang="scss">
.tariffs-modal {
  width: 500px;
  max-width: 100%;
  .add {
    width: 100%;
    height: 52px;
    background: $accent;
    border-radius: 16px;
    font-size: 16px;
    color: #fff;
  }
  .preview {
    display: flex;
    justify-content: space-between;
    height: 125px;
    border-radius: 16px;
    background: #F1F3F9;
    padding: 24px 32px;
    align-items: center;

    .left {
      strong {
        color: #8511CC;
        font-size: 64px;
        font-style: normal;
        font-weight: 400;
        line-height: 50px;
        display: block;
      }
      span {
        color: #2F2B2E;
        display: block;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    .right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      .discount {
        text-align: right;
        color: #F3B030 !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      .cost-with-discount {
        text-align: right;
        color: #B4B8C6 !important;
        text-decoration: line-through;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .cost {
        text-align: right;
        color: #2F2B2E;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }

    &.accent {
      background: $accent;
      * {
        color: #fff !important;
      }
    }
  }
  .note {
    color: #7C7F8A;
    margin-top: 4px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 14px */
  }
}
</style>