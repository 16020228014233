<script>
import PaginationComponent from "@/components/ui/PaginationComponent.vue";
import DividerComponent from "@/components/ui/DividerComponent.vue";
import TableComponent from "@/components/ui/TableComponent.vue";

export default {
  components: {TableComponent, DividerComponent, PaginationComponent},
  props: {
    company: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      columns: [
        {
          label: 'Компания',
          key: 'company',
          formatter: (value) => value.name,
          styles: {
            color: '#8511CC',
          }
        },
        {
          label: 'Соискатель',
          key: 'user',
          formatter: (value) => value.name,
          styles: {
            color: '#8511CC',
          }
        },
        {
          label: 'Текст',
          key: 'text',
          width: 3,
          styles: {
            'font-size': '12px',
            'padding-right': '40px',
            'display': 'block'
          }
        },
        {
          label: 'Оценка',
          key: 'stars'
        },
        {
          label: 'Создан',
          key: 'created_at',
          formatter: (value) => (new Date(value * 1000)).toLocaleString('ru-RU', { timeZone: 'Europe/Samara' }),
        },
      ],

      page: 1,
      pages: 1,
      reviews: [],
    }
  },
  methods: {
    async publish(id)  {
      const confirmed = await this.$root.$confirm("Вы уверены, что хотите опубликовать отзыв?");

      if  (!confirmed) return;

      this.$axios.patch('reviews/' + id).then(() => {

        const index = this.reviews.findIndex(item => item.id == id);

        if (index > -1)  {
          this.reviews[index].status = 'published';
        }

        this.$root.$emit('success', {
          title: "Уведомление",
          text: "Отзыв успешно опубликован"
        });
      }).catch(errors => {
        if (Array.isArray(errors)) {
          for (const item in errors) {
            this.$root.$emit('error', {
              title: "Ошибка",
              text: errors[item]
            });
          }
        } else {
          this.$root.$emit('error', {
            title: "Ошибка",
            text: "Возникла ошибка, попробуйте позже."
          });
        }
      })
    },
    async goDelete(id) {
      const confirmed = await this.$root.$confirm("Вы уверены, что хотите удалить отзыв?");

      if (!confirmed) return;

      this.$axios.delete('reviews/' + id).then(() => {

        const index = this.reviews.findIndex(item => item.id == id);

        if (index > -1)  {
          this.reviews.splice(index, 1);
        }

        this.$root.$emit('success', {
          title: "Уведомление",
          text: "Отзыв успешно удален"
        });
      }).catch(errors => {
        if (Array.isArray(errors)) {
          for (const item in errors) {
            this.$root.$emit('error', {
              title: "Ошибка",
              text: errors[item]
            });
          }
        } else {
          this.$root.$emit('error', {
            title: "Ошибка",
            text: "Возникла ошибка, попробуйте позже."
          });
        }
      })
    },
    setSort(sort) {
      this.sort = sort;
      this.fetch();
    },
    setPage(page) {
      this.page = page;
      this.fetch();
    },
    fetch() {
      this.$axios.get(`manager/${this.company.id}/reviews`).then(response => {
        this.reviews = response.data.data;
        this.pages = response.data.last_page;
        this.page = response.data.current_page;
      })
    }
  },
  mounted() {
    this.fetch();
  }
}
</script>

<template>
  <div class="company-reviews">
    <div class="tbl">
      <TableComponent
          :columns="columns"
          :items="reviews"
      >
        <template #actions="{item}">
          <div class="actions">
            <button v-if="item.status === 'pending'" class="publish default-hover" @click="publish(item.id)">Опубликовать</button>
            <button v-if="item.status === 'published'" disabled class="published default-hover">Опубликован</button>
            <button class="delete default-hover" @click="goDelete(item.id)">Удалить</button>
          </div>
        </template>
      </TableComponent>
    </div>

    <DividerComponent />

    <PaginationComponent @change="setPage" :pages="pages" :page="page" />
  </div>
</template>

<style scoped lang="scss">
.tbl {
  width: 100%;
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  ::v-deep {
    .table__body {
      min-width: 700px;
    }
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
  grid-gap: 16px;
  padding: 12px;
}
.publish {
  width: 200px;
  background: $accent;
  height: 44px;
  border-radius: 12px;
  color: #fff;
  font-size: 14px;
}
.published {
  width: 200px;
  background: $success;
  cursor: default;
  opacity: 0.7;
  height: 44px;
  border-radius: 12px;
  color: #fff;
  font-size: 14px;
}
.delete {
  width: 200px;
  background: $danger;
  height: 44px;
  border-radius: 12px;
  color: #fff;
  font-size: 14px;
}
</style>