export function agePrefix(age) {
    if (age % 100 >= 11 && age % 100 <= 19) {
        return `${age} лет`;
    }

    const lastDigit = age % 10;

    if (lastDigit === 1) {
        return `${age} год`;
    } else if (lastDigit >= 2 && lastDigit <= 4) {
        return `${age} года`;
    } else {
        return `${age} лет`;
    }
}
