<template>
  <div class="modal-overlay">
    <div class="modal">
      <button @click="close(false)" class="close-button">
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5371 0.792893C11.9276 1.18342 11.9276 1.81658 11.5371 2.20711L1.70711 12.0371C1.31658 12.4276 0.683417 12.4276 0.292893 12.0371C-0.0976311 11.6466 -0.0976311 11.0134 0.292893 10.6229L10.1229 0.792893C10.5134 0.402369 11.1466 0.402369 11.5371 0.792893Z" fill="#2F2B2E"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0.292893 0.792893C0.683417 0.402369 1.31658 0.402369 1.70711 0.792893L11.5371 10.6229C11.9276 11.0134 11.9276 11.6466 11.5371 12.0371C11.1466 12.4276 10.5134 12.4276 10.1229 12.0371L0.292893 2.20711C-0.0976311 1.81658 -0.0976311 1.18342 0.292893 0.792893Z" fill="#2F2B2E"/>
        </svg>
      </button>

      <SelectType :has_vip="has_vip" @select="type = $event" v-if="!type" />
      <template v-else>
        <BuyTop @close="close(false)" @back="type = null" :ids="ids" v-if="type === 'top'"></BuyTop>
        <BuyVip @close="close(false)" @back="type = null" :ids="ids" v-if="type === 'vip'"></BuyVip>
      </template>

    </div>
  </div>
</template>

<script>
import SelectType from "./promotion/SelectType.vue";
import BuyTop from "./promotion/BuyTop.vue";
import BuyVip from "./promotion/BuyVip.vue";

export default {
  data() {
    return {
      type: null,
    }
  },
  components: {BuyTop, SelectType, BuyVip},
  props: {
    has_vip: {
      type: Boolean,
      default: false,
    },
    ids: {
      type: Array,
      required: true,
    },
  },
  emits: ['close'],
  methods: {
    close(result) {
      this.$emit('close', result);
    }
  },
  name: "PromotionModal"
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 15px;
  z-index: 9;
  padding-left: 15px;
}
.modal {
  background: white;
  padding: 32px;
  border-radius: 32px;
  width: 450px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  @media (max-width: 768px) {
    padding: 24px 16px;
    border-radius: 16px;
  }
  .close-button {
    position: absolute;
    width: 24px;
    height: 24px;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    right: 32px;
    top: 32px;
    @media (max-width: 768px) {
      top: 27px;
    }
  }
}

</style>
