import Vue from 'vue'
import VueRouter from 'vue-router'
import DashboardView from '../views/DashboardView.vue'
import UsersView from "@/views/UsersView.vue";
import VacanciesVIew from "@/views/VacanciesVIew.vue";
import CitiesView from "@/views/CitiesView.vue";
import SupportView from "@/views/SupportView.vue";
import TicketMessagesView from "@/views/Ticket/TicketMessagesView.vue";
import ReviewsView from "@/views/ReviewsView.vue";
import VacancyView from "@/views/Vacancy/VacancyView.vue";
import VacancyEdit from "@/views/Vacancy/VacancyEdit.vue";
import CompaniesView from "@/views/CompaniesView.vue";
import EntityView from "@/views/EntityView.vue";
import SeoView from "@/views/SeoView.vue";
import FaqView from "@/views/FaqView.vue";
import CompanyTypesView from "@/views/CompanyTypesView.vue";
import CategoriesView from "@/views/CategoriesView.vue";
import SettingsView from "@/views/SettingsView.vue";
import EditContactsView from "@/views/EditContactsView.vue";
import SettingsBoostView from "@/views/SettingsBoostView.vue";
import LoginView from "@/views/LoginView.vue";
import UserView from "@/views/UserView.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: "login",
    component: LoginView
  },
  {
    path: '/',
    name: 'dashboard',
    component: DashboardView,
    meta: {
      requiredAuth: true,
    }
  },
  {
    path: "/users",
    name: 'users',
    component: UsersView,
    meta: {
      requiredAuth: true,
    }
  },
  {
    path: "/users/:id",
    name: 'users.details',
    component: UserView,
    meta: {
      requiredAuth: true,
    }
  },
  {
    path: "/vacancies",
    name: 'vacancies',
    component: VacanciesVIew,
    meta: {
      requiredAuth: true,
    }
  },
  {
    path: "/vacancies/:id",
    name: 'vacancy',
    component: VacancyView,
    meta: {
      requiredAuth: true,
    }
  },
  {
    path: "/vacancies/:id/edit",
    name: 'vacancy-edit',
    component: VacancyEdit,
    meta: {
      requiredAuth: true,
    }
  },
  {
    path: "/cities",
    name: 'cities',
    component: CitiesView,
    meta: {
      requiredAuth: true,
    }
  },
  {
    path: "/support",
    name: 'support',
    component: SupportView,
    meta: {
      requiredAuth: true,
    }
  },
  {
    path: "/support/:id",
    name: 'support-ticket',
    component: TicketMessagesView,
    meta: {
      requiredAuth: true,
    }
  },
  {
    path: '/reviews',
    name: "reviews",
    component: ReviewsView,
    meta: {
      requiredAuth: true,
    }
  },
  {
    path: '/companies',
    name: "companies",
    component: CompaniesView,
    meta: {
      requiredAuth: true,
    }
  },

  {
    path: '/entity/:id',
    name: "entity",
    component: EntityView,
    meta: {
      requiredAuth: true,
    }
  },
  {
    path: '/seo',
    name: 'seo',
    component: SeoView,
    meta: {
      requiredAuth: true,
    }
  },
  {
    path: '/faq',
    name: 'faq',
    component: FaqView,
    meta: {
      requiredAuth: true,
    }
  },
  {
    path: '/company-types',
    name: "company-types",
    component: CompanyTypesView,
    meta: {
      requiredAuth: true,
    }
  },
  {
    path: '/categories',
    name: "categories",
    component: CategoriesView,
    meta: {
      requiredAuth: true,
    }
  },
  {
    path: '/settings',
    name: "settings",
    component: SettingsView,
    meta: {
      requiredAuth: true,
    }
  },
  {
    path: '/settings/contacts',
    name: "settings.contacts",
    component: EditContactsView,
    meta: {
      requiredAuth: true,
    }
  },
  {
    path: '/settings/boost',
    name: "settings.boost",
    component: SettingsBoostView,
    meta: {
      requiredAuth: true,
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  const token = localStorage.getItem("token");

  if (to.meta.requiredAuth && !token) {
    next("/login");
  }

  next();
});

export default router
