<script>
import {defineComponent} from "vue";
import BaseModal from "@/components/ui/BaseModal.vue";
import DividerComponent from "@/components/ui/DividerComponent.vue";
import InputComponent from "@/components/ui/InputComponent.vue";
import TextareaComponent from "@/components/ui/TextareaComponent.vue";

export default defineComponent({
  props: {
    object: {
      type: Object,
    }
  },
  data() {
    return {
      question: null,
      answer: null,
    }
  },
  methods: {
    go() {
      if (!this.answer || !this.question) return;

      const data = {
        question: this.question,
        answer: this.answer
      };

      let request;

      if (!this.object) {
        request = this.$axios.post('/faqs', data)
      } else {
        request = this.$axios.put('/faqs/' + this.object.id, data)
      }

      request.then(() => {
        this.$root.$emit('success', {
          title: "Уведомление",
          text: "FAQ пункт был успешно " + (this.object ? 'отредактирован' : 'создан')
        });
        this.$emit('update');
      }).catch(errors => {
        if (Array.isArray(errors)) {
          for (const item in errors) {
            this.$root.$emit('error', {
              title: "Ошибка",
              text: errors[item]
            });
          }
        } else {
          this.$root.$emit('error', {
            title: "Ошибка",
            text: "Возникла ошибка, попробуйте позже."
          });
        }
      })
    }
  },
  mounted() {
    if (this.object) {
      this.question = this.object.question;
      this.answer = this.object.answer;
    }
  },
  name: "FaqModal",
  components: {TextareaComponent, InputComponent, DividerComponent, BaseModal}
})
</script>

<template>
  <BaseModal @close="$emit('close')">
    <div class="balance">
      <div class="modal-title">
        <template v-if="object">Редактирование</template>
        <template v-else>Создание</template>
         пункта FAQ
      </div>
      <DividerComponent />

      <InputComponent type="text" label="Вопрос" placeholder="Введите вопрос" v-model="question" />

      <DividerComponent />

      <TextareaComponent label="Ответ" placeholder="Ответ" v-model="answer" />

      <DividerComponent />

      <button class="default-hover" @click="go" :disabled="!answer || !question">Создать</button>

    </div>
  </BaseModal>
</template>

<style scoped lang="scss">
.balance {
  width: 500px;
  max-width: 100%;
  button {
    width: 100%;
    height: 52px;
    background: $accent;
    border-radius: 16px;
    color: #fff;
  }
}
</style>