import { render, staticRenderFns } from "./H1Component.vue?vue&type=template&id=4293ecf8&scoped=true"
import script from "./H1Component.vue?vue&type=script&lang=js"
export * from "./H1Component.vue?vue&type=script&lang=js"
import style0 from "./H1Component.vue?vue&type=style&index=0&id=4293ecf8&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4293ecf8",
  null
  
)

export default component.exports