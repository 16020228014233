<script>
import H1Component from "@/components/ui/H1Component.vue";
import SettingsTariffs from "@/components/settings/SettingsTariffs.vue";
import DividerComponent from "@/components/ui/DividerComponent.vue";
import SettingsContacts from "@/components/settings/SettingsContacts.vue";
import SettingsBoost from "@/components/settings/SettingsBoost.vue";
import SettingsSeo from "@/components/settings/SettingsSeo.vue";

export default {
  data() {
    return {
      tech_works: 0,
    }
  },
  methods: {
    async updateConfig(data) {
      if (data.tech_works) {
        const confirmed = await this.$root.$confirm("Вы уверены, что хотите включить Тех.Работы?");

        if (!confirmed) return;
      }

      this.$axios.post('config', data)
          .then(() => {
            this.$root.$emit('reloadSettings');

            this.$root.$emit('success', {
              title: "Уведомление",
              text: "Успешно обновлено.",
            });
            this.tech_works = data.tech_works;
          })
          .catch(() => {
            this.$root.$emit('error', { title: "Ошибка", text: "Unknown error." });
          })
          .finally(() => {
            this.$emit('reload');
          });
    }
  },
  mounted() {
    this.tech_works = this.$root.settings.tech_works;
  },
  components: {SettingsSeo, SettingsBoost, SettingsContacts, DividerComponent, SettingsTariffs, H1Component}

}
</script>

<template>
    <div class="settings">
      <H1Component text="Настройки" />
      <DividerComponent />
      <SettingsContacts />
      <DividerComponent />
      <SettingsTariffs />
      <DividerComponent />
      <SettingsBoost />
      <DividerComponent />
      <SettingsSeo />
      <DividerComponent />
      <DividerComponent />

      <button v-if="!tech_works" class="tech-works danger default-hover" @click="updateConfig({
          tech_works: 1
      })">Включить тех.работы</button>
      <button v-else class="tech-works success default-hover" @click="updateConfig({
          tech_works: 0
      })">Выключить тех.работы</button>
    </div>
</template>

<style scoped lang="scss">
  .settings {
    max-width: 100%;
  }
  .tech-works {
    width: 100%;
    height: 52px;
    border-radius: 18px;
    font-size: 16px;
    font-weight: 500;
    &.danger {
      background: $danger;
      color: #fff;
    }
    &.success {
      background: $success;
      color: #fff;
    }
  }
</style>