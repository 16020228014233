<script>
export default {
  props: {
    value: {
      type: Boolean,
    }
  },
  name: "BooleanBadgeComponent"
}
</script>

<template>
  <div class="boolean-badge">
    <img src="@/assets/icons/boolean-true.svg" v-if="value" alt="">
    <img src="@/assets/icons/boolean-false.svg" v-else alt="">
  </div>
</template>

<style scoped lang="scss">
  .boolean-badge {
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 18px;
    img  {
      max-width: 100%;
      max-height: 100%;
    }
  }
</style>