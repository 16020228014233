var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"settings-boost"},[_c('H1Component',{attrs:{"text":"Настройки"}}),_c('DividerComponent'),_c('div',{staticClass:"top"},[_c('h2',[_vm._v("Продвижение")]),_c('div',{staticClass:"back",on:{"click":function($event){return _vm.$router.back()}}},[_vm._v(" Назад ")])]),_c('div',{staticClass:"block"},[_c('div',{staticClass:"tbl"},[_c('TableComponent',{attrs:{"columns":_vm.columns,"items":[
      {
        type: 'Выделение цветом',
        cost: _vm.vip_price,
        duration: _vm.vip_duration,
      }
    ]}})],1),_c('DividerComponent',{attrs:{"size":"16"}}),_c('div',{staticClass:"input-group"},[_c('InputComponent',{attrs:{"placeholder":"Период действия (часы)","label":"Период действия (часы)","required":true,"type":"number"},model:{value:(_vm.vip_duration),callback:function ($$v) {_vm.vip_duration=$$v},expression:"vip_duration"}}),_c('InputComponent',{attrs:{"placeholder":"Стоимость","label":"Стоимость","required":true,"type":"number"},model:{value:(_vm.vip_price),callback:function ($$v) {_vm.vip_price=$$v},expression:"vip_price"}})],1),_c('DividerComponent',{attrs:{"size":"16"}}),_c('button',{staticClass:"send default-hover",on:{"click":function($event){return _vm.save({
      vip_duration: _vm.vip_duration,
      vip_price: _vm.vip_price,
    })}}},[_vm._v("Применить")])],1),_c('div',{staticClass:"block"},[_c('div',{staticClass:"tbl"},[_c('TableComponent',{attrs:{"columns":_vm.columns,"items":[
      {
        type: 'Разовое поднятие',
        cost: _vm.once_price_top,
        duration: _vm.once_duration_top,
      }
    ]}})],1),_c('DividerComponent',{attrs:{"size":"16"}}),_c('div',{staticClass:"input-group"},[_c('InputComponent',{attrs:{"placeholder":"Период действия (часы)","label":"Период действия (часы)","required":true,"type":"number"},model:{value:(_vm.once_duration_top),callback:function ($$v) {_vm.once_duration_top=$$v},expression:"once_duration_top"}}),_c('InputComponent',{attrs:{"placeholder":"Стоимость","label":"Стоимость","required":true,"type":"number"},model:{value:(_vm.once_price_top),callback:function ($$v) {_vm.once_price_top=$$v},expression:"once_price_top"}})],1),_c('DividerComponent',{attrs:{"size":"16"}}),_c('button',{staticClass:"send default-hover",on:{"click":function($event){return _vm.save({
      once_duration_top: _vm.once_duration_top,
      once_price_top: _vm.once_price_top,
    })}}},[_vm._v("Применить")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }