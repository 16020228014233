<script>
import DividerComponent from "@/components/ui/DividerComponent.vue";
import InputComponent from "@/components/ui/InputComponent.vue";

export default {
  components: {InputComponent, DividerComponent},
  props: {
    item: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      name: null,
    }
  },
  methods: {
    goDelete() {
      this.$axios.delete('cities/' + this.item.id).then(() => {
        this.$root.$emit('error', {
          title: "Уведомление",
          text: "Город успешно удален."
        });

        this.$emit('deleted');
      }).catch(errors => {
        if (Array.isArray(errors)) {
          for (const item in errors) {
            this.$root.$emit('error', {
              title: "Ошибка",
              text: errors[item]
            });
          }
        } else {
          this.$root.$emit('error', {
            title: "Ошибка",
            text: "Возникла ошибка, попробуйте позже."
          });
        }
      }).finally(() => {
        this.$emit('close');
      })
    }
  },
  mounted() {
    this.name = this.item.name;
  }
}
</script>

<template>
  <div class="delete-city">
    <div class="modal-title">
      Удаление города
    </div>
    <span>Вы уверены что хотите удалить город?</span>
    <span class="danger">Все вакансии в этом городе будут также удалены.</span>

    <DividerComponent />

    <InputComponent readonly v-model="name" v-if="name" />

    <DividerComponent />

    <button class="default-hover" @click="goDelete">Удалить</button>
  </div>
</template>

<style scoped lang="scss">
  .delete-city {
    width: 400px;
    max-width: 100%;
    span {
      color: #7C7F8A;
      font-size: 16px;
      font-style: normal;
      margin-top: 4px;
      display: block;
      font-weight: 400;
      line-height: normal;
      &.danger {
        color: $danger
      }
    }
    button {
      height: 52px;
      width: 100%;
      border-radius: 16px;
      background: $danger;
      color: #fff;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
</style>