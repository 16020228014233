var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page users"},[_c('H1Component',{attrs:{"text":"Города"}}),_c('DividerComponent'),_c('div',{staticClass:"grid"},[_c('StatisticBlockComponent',{attrs:{"icon":require('@/assets/icons/stat-vacancies.svg'),"title":"Всего городов","value":_vm.statistics.total.value}}),_c('StatisticBlockComponent',{attrs:{"icon":require('@/assets/icons/stat-companies.svg'),"title":"В среднем объялений в городе","value":_vm.statistics.average.value}})],1),_c('DividerComponent'),_c('div',{staticClass:"filters"},[_c('InputComponent',{attrs:{"placeholder":"Поиск по названию","icon":require('@/assets/icons/input-default.svg')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('SelectComponent',{attrs:{"additionalLabel":"Сортировать по:","value":_vm.sort,"options":[
            {
              id: 'latest',
              name: 'Новым'
            },
            {
              id: 'oldest',
              name: 'Старым',
            },
        ]},on:{"select":_vm.setSort}}),_c('div',{staticClass:"buttons"},[_c('AddButtonComponent',{attrs:{"text":"Добавить город"},on:{"click":function($event){_vm.isShowModal = true}}})],1)],1),_c('DividerComponent'),_c('div',{staticClass:"tbl"},[_c('TableComponent',{attrs:{"columns":_vm.columns,"items":_vm.vacancies,"is-loading":_vm.isLoading,"deletable":true},on:{"delete":_vm.deleteCity}})],1),_c('DividerComponent'),_c('PaginationComponent',{attrs:{"pages":_vm.pages,"page":_vm.page},on:{"change":_vm.setPage}}),(_vm.isShowModal)?_c('BaseModal',{on:{"close":function($event){_vm.isShowModal = false}}},[_c('AddOrEditCityModal',{on:{"close":function($event){_vm.isShowModal = false},"add":function($event){return _vm.setPage(1)}}})],1):_vm._e(),(_vm.deleteCityObject)?_c('BaseModal',{on:{"close":function($event){_vm.deleteCityObject = null}}},[_c('DeleteCityModal',{attrs:{"item":_vm.deleteCityObject},on:{"close":function($event){_vm.deleteCityObject = null},"deleted":function($event){_vm.deleteCityObject = null, _vm.setPage(1)}}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }