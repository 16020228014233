<script>
export default {
  data() {
    return {
      isOpen: false,
    }
  },
  props: {
    title: {
      type: String,
      default: "Collapse"
    }
  },
  name: "CollapseComponent"
}
</script>

<template>
  <div class="collapse">
    <div @click="isOpen = !isOpen" class="collapse-main default-hover">
      {{ title }}
      <img src="@/assets/icons/arrow-down.svg" :class="{'transform': isOpen}" alt="">
    </div>
    <div class="collapse-content" v-show="isOpen">
      <slot></slot>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .collapse {
    border-radius: 8px;

    .collapse-main {
      display: flex;
      background: #F1F3F9;
      align-items: center;
      justify-content: space-between;
      padding: 10px 16px;
      color: #2F2B2E;
      font-size: 16px;
      border-radius: 8px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      overflow: hidden;
      img {
        transition: 0.2s;
        &.transform {
          transform: rotate(180deg);
        }
      }
    }
    .collapse-content {
      margin-top: 8px;
      border-radius: 8px;
      padding: 16px;
      background: #e4e6ee;
    }
  }
</style>