<template>
  <BaseModal v-if="visible" @close="cancel">
    <div class="confirm">
      <div class="modal-title">
        Подтверждение
      </div>

      <span>{{ message }}</span>

      <div class="actions">
        <button @click="cancel" class="cancel default-hover">Отмена</button>
        <button @click="confirm" class="confirm default-hover">Подтвердить</button>
      </div>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from "@/components/ui/BaseModal.vue";

export default {
  components: {BaseModal},
  data() {
    return {
      visible: false,
      message: "",
      resolve: null,
      reject: null,
    };
  },
  methods: {
    open(message) {
      this.message = message;
      this.visible = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    confirm() {
      this.resolve(true);
      this.visible = false;
    },
    cancel() {
      this.reject(false);
      this.visible = false;
    },
  },
};
</script>

<style scoped lang="scss">
.confirm {
  .actions {
    margin-top: 32px;
    display: flex;
    grid-gap: 16px;
    button {
      height: 44px;
      flex: 1;
      border-radius: 12px;
      text-align: center;
      &.cancel {
        background: #F1F3F9;
      }
      &.confirm {
        flex: 1;
        color: #fff;
        background: $accent;
      }
    }
  }
  width: 400px;
  span {
    margin-top: 32px;
    display: block;
    color: #7C7F8A;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
</style>
