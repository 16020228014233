<script>
  export default {
    props: {
      size: {
        type: Number,
        default: 32
      }
    }
  }
</script>

<template>
  <div class="divider" :style="{'height': size + 'px'}" :class="{'px32' : size === 32}"></div>
</template>

<style scoped lang="scss">
  .divider {
    width: 100%;
    @media (max-width: 768px) {
      &.px32 {
        height: 16px !important;
      }
    }
  }
</style>