import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import VueEditor from 'vue2-quill-editor'
import '@/styles/main.scss';
import axiosInstance from "@/plugins/axios";
Vue.component("QuillEditor", VueEditor);

Vue.prototype.$axios = axiosInstance;

Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
