<script>
export default {
  props: {
    text: {
      type: String,
      default: "Тут пока нет записей.",
    }
  },
  name: "EmptyState"
}
</script>

<template>
  <div class="empty-state">
    <img src="@/assets/icons/none.svg" alt="">
    <span>{{ text }}</span>
  </div>
</template>

<style scoped lang="scss">
  .empty-state {
    width: 100%;
    padding: 50px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      margin-right: 14px;
    }
    span {
      font-size: 16px;
      font-weight: 500;
      color: #000;
      @media (max-width: 768px) {
        font-size: 14px;
      }
    }
  }
</style>
