<script>
import InputComponent from "@/components/ui/InputComponent.vue";
import DividerComponent from "@/components/ui/DividerComponent.vue";
import Textarea from "@/components/ui/TextareaComponent.vue";
import CheckboxComponent from "@/components/ui/CheckboxComponent.vue";
import SelectDropdown from "@/components/ui/SelectDropdown.vue";
import SecondTitle from "@/components/ui/second-title.vue";
import UploadFile from "@/components/ui/UploadFile.vue";
export default {
  definePageMeta() {
    return {
      ssr: false
    };
  },
  props: {
    id: {
      type: Number,
      required: false,
    }
  },
  data() {
    return {
      toArchive: false,
      toDraft: false,
      object: null,

      isInitialized: false,
      isShowOptions: false,
      types: [
        {
          id: 'permanently',
          name: "Постоянная"
        },
        {
          id: 'temporary',
          name: "Временная"
        },
        {
          id: 'contract',
          name: "Контрактная"
        }
      ],
      pay_periods: [
        {
          id: 'everyday',
          name: "Ежедневно"
        },
        {
          id: 'weekly',
          name: "Еженедельно"
        },
        {
          id: 'monthly',
          name: "Ежемесячно"
        },
      ],
      working_periods: [
        {
          id: 'day',
          name: "Дневные смены"
        },
        {
          id: 'night',
          name: "Ночные смены"
        },
        {
          id: 'mixed',
          name: "Смешанные"
        }
      ],

      files: [],
      existingFiles: [],
      form: {
        description: null,
        requirements: null,
        conditions: null,
        name: null,
        salary: null,
        is_between_salary: false,
        salary_to: null,
        working_time: null,
        pay_period: null,
        city: null,
        phone: null,
        status: null,
        email: null,
        type: null,
        category: null,
      },

      isLoading: false,

      statuses: [
        {
          id: 'active',
          name: 'Активна'
        },
        {
          id: 'draft',
          name: 'Черновик'
        },
        {
          id: 'on_review',
          name: 'На модерации'
        },
        {
          id: 'archive',
          name: 'В Архиве'
        }
      ],
    }
  },
  methods: {
    deleteFile(id) {
      this.$axios.delete('vacancy/' + this.$route.params.id + '/delete-file/' + id).then(() => {
        const index = this.existingFiles.findIndex(item => item.id === id);

        this.$root.$emit('error', {
          title: "Уведомление",
          text: "Файл был удален из вакансии."
        });

        if (index > -1) {
          this.existingFiles.splice(index, 1);
        }
      }).catch(errors => {
        if (Array.isArray(errors)) {
          for (const item in errors) {
            this.$root.$emit('error', {
              title: "Ошибка",
              text: errors[item]
            });
          }
        } else {
          this.$root.$emit('error', {
            title: "Ошибка",
            text: "Возникла ошибка, попробуйте позже."
          });
        }
      })
    },
    handleOptions() {
      this.isShowOptions = false;
    },
    async send() {
      if (this.isLoading) return;

      const data = new FormData();

      if (!this.form.category) {
        this.$root.$emit('error', {
          title: "Ошибка",
          text: "Выберите категорию (неприм. сферу деятельности или профессию)"
        });
        return;
      }

      data.append('name', this.form.name);
      data.append('description', this.form.description);
      data.append('requirements', this.form.requirements);
      data.append('conditions', this.form.conditions);
      data.append('status', this.form.status.id);
      data.append('working_time', this.form.working_time.id);
      data.append('city_id', this.form.city.id);
      data.append('pay_period', this.form.pay_period.id);
      data.append('is_between_salary', this.form.is_between_salary ? 1 : 0);
      data.append('salary', this.form.salary);
      data.append('salary_to', this.form.salary_to);
      data.append('phone', this.form.phone);
      data.append('email', this.form.email);
      data.append('type', this.form.type.id);
      data.append('category_id', this.form.category.id);
      data.append('to_draft', this.toDraft ? 1 : 0);
      data.append('to_archive', this.toArchive ? 1 : 0);

      for (const item in this.files) {
        data.append('files['+item+']', this.files[item]);
      }

      this.isLoading = true;

      let request = null;

      data.append('_method', 'PUT');
      request = this.$axios.post('vacancy/' + this.$route.params.id, data, {
        headers: {
          'Content-Type' : 'multipart/form-data'
        }
      })

      await request.then(() => {
        this.$root.$emit('success', {
          title: "Уведомление",
          text: 'Вакансия была успешно обновлена'
        });
        this.files = [];
          this.initEditData()
      }).catch(errors => {
        if (Array.isArray(errors)) {
          for (const item in errors) {
            this.$root.$emit('error', {
              title: "Ошибка",
              text: errors[item]
            });
          }
        } else {
          this.$root.$emit('error', {
            title: "Ошибка",
            text: "Возникла ошибка, попробуйте позже."
          });
        }
      }).finally(() => {
        setTimeout(() => {
          this.isLoading = false;
        }, 300)
      });
    },
    async initEditData() {
      this.isInitialized = false;
      await this.$axios.get('vacancy/' + this.$route.params.id).then(response => {
        const vacancy = response.data;
        this.form.name = vacancy.name;
        this.form.description = vacancy.description;
        this.form.requirements = vacancy.requirements;
        this.form.conditions = vacancy.conditions;
        this.form.salary = vacancy.salary;
        this.form.is_between_salary = vacancy.is_between_salary;
        this.form.salary_to = vacancy.salary_to;
        this.form.working_time = this.working_periods.find(item => item.id === vacancy.working_time);
        this.form.pay_period = this.pay_periods.find(item => item.id === vacancy.pay_period);
        this.form.city = this.$root.settings.cities.find(item => item.id === vacancy.city.id);
        this.form.phone = vacancy.phone;
        this.form.status = this.statuses.find(item => item.id == vacancy.status);
        this.form.email = vacancy.email;
        this.form.type = this.types.find(item => item.id === vacancy.type);
        this.form.category = this.$root.settings.categories.find(item => item.id === vacancy.category.id);

        this.object = vacancy;

        this.existingFiles = vacancy.files;

        this.isInitialized = true;
      });
    },
  },
  created() {

  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleOptions)
  },
  async mounted() {
    await this.initEditData();

    this.isInitialized = true;

    document.addEventListener('click', this.handleOptions)
  },
  components: {UploadFile, SecondTitle, SelectDropdown, CheckboxComponent, Textarea, DividerComponent, InputComponent}
}
</script>

<template>
  <div class="create-vacancy dashboard-container">
    <div class="top">
      <second-title text="Редактирование" accent-text="вакансии" />
      <span class="default-hover" @click="$router.back()">
        Назад
      </span>
    </div>

    <DividerComponent />

    <form ref="form" v-if="isInitialized" @submit.prevent.stop="send" class="form">
      <SelectDropdown label="Статус" :options="statuses" :value="form.status" @selected="form.status = $event" />

      <DividerComponent size="16" />
      <DividerComponent size="16" />
      <InputComponent v-model="form.name" :required="true" label="Название вакансии" placeholder="Введите название вакансии" type="text" />

      <DividerComponent size="16" />

      <Textarea label="Описание" v-model="form.description" :required="true" placeholder="Описание" />
      <div class="note">От 500 до 5 000 символов</div>

      <DividerComponent size="16" />

      <Textarea label="Требования" v-model="form.requirements" :required="true" placeholder="Требования" />
      <DividerComponent size="16" />

      <Textarea label="Условия" :required="true" v-model="form.conditions" placeholder="Описание условий труда и предоставляемых преимуществ." />
      <DividerComponent size="16" />

      <InputComponent v-model="form.salary" :required="true" label="Заработная плата" placeholder="Заработная плата" type="number" v-if="!form.is_between_salary" />
      <div class="input-group" v-else>
        <InputComponent v-model="form.salary" :required="true" label="Заработная плата" placeholder="от" type="number" />
        <InputComponent v-model="form.salary_to" :required="true" placeholder="до" type="number" />

      </div>

      <DividerComponent size="8" />
      <CheckboxComponent v-model="form.is_between_salary" :checked="form.is_between_salary" label="Диапозон зарплаты" />

      <DividerComponent size="16" />

      <SelectDropdown label="График работы" :options="working_periods" :value="form.working_time" @selected="form.working_time = $event" />

      <DividerComponent size="16" />

      <SelectDropdown label="Частота выплат" :options="pay_periods" :value="form.pay_period" @selected="form.pay_period = $event" />

      <DividerComponent size="16" />

      <SelectDropdown label="Тип вакансии" :options="types" :value="form.type" @selected="form.type = $event" />

      <DividerComponent size="16" />

      <SelectDropdown :is-searchable="true" label="Город" :options="$root.settings.cities" v-if="$root.settings?.cities" :value="form.city" @selected="form.city = $event" />

      <DividerComponent size="16" />

      <SelectDropdown :use-first="false" :is-searchable="true" label="Категория" placeholder="Выберите сферу деятельности, наприм. Официант" :options="$root.settings.categories" v-if="$root.settings?.categories" :value="form.category" @selected="form.category = $event" />

      <DividerComponent size="16" />

      <InputComponent
          :required="true"
          v-model="form.phone"
          masked="+42 ### ###-##-##"
          label="Номер телефона"
          placeholder="+42" />

      <DividerComponent size="16" />

      <InputComponent
          :required="true"
          v-model="form.email"
          label="Email"
          type="email"
          placeholder="E-mail" />

      <DividerComponent size="16" />

      <UploadFile @delete-file="deleteFile" :existingFiles="existingFiles" @file-selected="files = $event" />

      <DividerComponent />

      <div class="actions">
        <button type="submit" class="send default-hover" @click="toDraft = false" :class="{'loading': isLoading, 'disabled': isLoading}" :disabled="isLoading">
          Сохранить
        </button>
      </div>

    </form>
  </div>
</template>

<style scoped lang="scss">
.create-vacancy {
  width: 1000px;
  max-width: 100%;

  @media (max-width: 1200px) {
    padding: 32px 0;
  }
  @media (max-width: 768px) {
    padding: 16px 0;
  }
  .note {
    margin-top: 4px;
    color: #7C7F8A;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 14px */
  }
  .form {
    .input-group {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      align-items: flex-end;
      grid-gap: 8px;

    }
    .actions {
      display: flex;
      grid-gap: 16px;
      @media (max-width: 768px) {
        grid-gap: 8px;
        flex-wrap: wrap;
      }
      button {
        height: 52px;
        border-radius: 16px;
      }
      .reset {
        width: 117px;
        min-width: 117px;
        background: #F1F3F9;
        color: #2F2B2E;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        @media (max-width: 768px) {
          font-size: 16px;
        }
        @media (max-width: 768px) {
          order: 2;
          width: 100%;
        }
      }
      .options {
        @media (max-width: 768px) {
          order: 1;
        }
        width: 52px;
        min-width: 52px;
        background: #F1F3F9;
        display: flex;
        border-radius: 16px;
        align-items: center;
        justify-content: center;
        position: relative;
        .options-wrapper {
          right: 0;
          position: absolute;
          bottom: calc(100% + 16px);
          border-radius: 16px;
          border: 1px solid #DEE0E5;
          background: #F1F3F9;
          padding: 16px;
          width: fit-content;
          button {
            display: flex;
            cursor: pointer;
            align-items: center;
            img {
              width: 18px;
              margin-right: 4px;
            }
            color: #7C7F8A;
            font-size: 16px;
            height: auto;
            font-style: normal;
            font-weight: 400;
            white-space: nowrap;
            line-height: normal;
            transition: 0.2s;
            &:hover {
              opacity: 0.6;
            }
          }
          button + button {
            margin-top: 16px;
          }
        }
      }
      .send {
        flex-grow: 1;
        background: #74CD63;
        display: flex;
        align-items: center;
        @media (max-width: 768px) {
          grid-column: 1 / -1;
          order: 0;
        }
        img {
          margin-right: 8px;
          width: 24px;
        }
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        color: #fff;
        justify-content: center;
        line-height: normal;
        @media (max-width: 768px) {
          font-size: 16px;
        }
      }
    }
  }
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      color: #B4B8C6;
      cursor: pointer;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 14px */
      &:hover {
        color: $accent;
      }
    }
  }

}
</style>