<script>
export default {
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
    }
  },
  name: "CheckboxComponent",
  methods: {
    onChange(event) {
      this.$emit('update:modelValue', !!event.target.checked);
      this.$emit('input', !!event.target.checked);
    }
  },
  mounted() {
    if (this.checked) {
      this.$emit('update:modelValue', true);
      this.$emit('input', true);
    }
  },
  data () {
    return {
      id: Math.floor(Math.random() * 10000),
    }
  },
}
</script>

<template>

  <div class="agree-checkbox">
    <input :checked="checked" @change="onChange" type="checkbox" :id="`checkbox-${id}`">
    <label :for="`checkbox-${id}`">
      <span class="square"></span>
      <span class="agree-checkbox__label">
            {{ label }}
      </span>
    </label>
  </div>

</template>

<style scoped lang="scss">
.agree-checkbox {
  input {
    display: none;
  }
  label {
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    transition: 0.2s;
    &:hover {
      opacity: 0.8;
    }
    .square {
      width: 20px;
      position: relative;
      height: 20px;
      border-radius: 4px;
      border: 1px solid #B0B4C3;
      margin-right: 8px;
      min-width: 20px;
      &::after {
        position: absolute;
        left: 3px;
        transition: 0.2s;
        top: 3px;
        height: calc(100% - 6px);
        width: calc(100% - 6px);
        background: $accent;
        content: "";
        opacity: 0;
        display: block;
        border-radius: 3px;
      }
    }
    .agree-checkbox__label {
      color: #0D0D0D;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 1px;
    }
  }
  input:checked + label {
    .square {
      &::after {
        opacity: 1;
      }
    }
  }
}
</style>
