<script>
import H1Component from "@/components/ui/H1Component.vue";
import DividerComponent from "@/components/ui/DividerComponent.vue";

export default {
  components: {DividerComponent, H1Component},
  data() {
    return {
      message: null,
      messages: [],
      ticket: null
    }
  },
  methods: {
    closeTicket() {
      this.$axios.patch('tickets/' + this.$route.params.id + '/close').then(() => {
        this.$root.$emit('success', {
          title: "Уведомление",
          text: "Тикет успешно закрыт"
        });

        this.fetch();
      }).catch(errors => {
        if (Array.isArray(errors)) {
          for (const item in errors) {
            this.$root.$emit('error', {
              title: "Ошибка",
              text: errors[item]
            });
          }
        } else {
          this.$root.$emit('error', {
            title: "Ошибка",
            text: "Возникла ошибка, попробуйте позже."
          });
        }
      });
    },
    fetch() {
      this.$axios.get('tickets/' + this.$route.params.id).then(response => {
        this.ticket = response.data.ticket;
        this.messages = response.data.messages;
        setTimeout(() => {
          this.scroll();
        }, 2)
      })
    },
    scroll() {
      const scrollableBlock = this.$refs.list;
      if (scrollableBlock) {
        scrollableBlock.scrollTop = scrollableBlock.scrollHeight;
      }
    },
    send() {
      if (!this.message) return;

      this.$axios.post('tickets/' + this.$route.params.id + '/message', {
        message: this.message
      }).then(response => {
        this.messages.push(response.data);
        this.$root.$emit('success', {
          title: "Уведомление",
          text: "Сообщение успешно отправлено."
        });
        this.message = null;
      }).catch(errors => {
        if (Array.isArray(errors)) {
          for (const item in errors) {
            this.$root.$emit('error', {
              title: "Ошибка",
              text: errors[item]
            });
          }
        } else {
          this.$root.$emit('error', {
            title: "Ошибка",
            text: "Возникла ошибка, попробуйте позже."
          });
        }
      })
    }
  },
  mounted() {
    this.fetch();
  }
}
</script>

<template>
  <div class="ticket-messages">
    <div class="top">
      <H1Component text="Поддержка" />
      <span class="default-hover" @click="$router.back()">Назад</span>
    </div>

    <div class="messages" v-if="ticket">
      <div class="messages-top">
        <div class="left">
          <strong>{{ ticket.subject }}</strong>
          <span :class="[ticket.status]">
                <template v-if="ticket.status === 'active'">Открыт</template>
                <template v-else-if="ticket.status === 'closed'">Закрыт</template>
              </span>
        </div>
        <div class="right">
          {{ (new Date(ticket.created_at * 1000)).toLocaleString('ru-RU', { timeZone: 'Europe/Samara' }) }}
        </div>
      </div>
      <div class="messages-list" ref="list">
        <div class="messages-list__body" >
          <div v-for="(item, i) in messages" :key="i" :class="{'admin': item.user_id === ticket.user_id}" class="messages-list__body-message">
            {{ item.message }}
            <div class="icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 20 14" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.97179 0H20C20 7.73199 13.732 14 6 14H0V13.6713C1.43129 12.47 2.61605 10.9873 3.47409 9.30334C4.59654 7.10041 4.89332 4.39663 4.97179 0Z" fill="#8C12D6"/>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div class="messages-input">
        <input v-model="message" v-if="ticket.status === 'active'" @keydown.enter="send" type="text" placeholder="Введите ответ">
        <input readonly v-else type="text" value="Тикет закрыт">
        <button class="default-hover" @click="send" v-if="ticket.status === 'active'">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M16.1391 2.96004L7.10914 5.96004C1.03914 7.99004 1.03914 11.3 7.10914 13.32L9.78914 14.21L10.6791 16.89C12.6991 22.96 16.0191 22.96 18.0391 16.89L21.0491 7.87004C22.3891 3.82004 20.1891 1.61004 16.1391 2.96004ZM16.4591 8.34004L12.6591 12.16C12.5091 12.31 12.3191 12.38 12.1291 12.38C11.9391 12.38 11.7491 12.31 11.5991 12.16C11.3091 11.87 11.3091 11.39 11.5991 11.1L15.3991 7.28004C15.6891 6.99004 16.1691 6.99004 16.4591 7.28004C16.7491 7.57004 16.7491 8.05004 16.4591 8.34004Z" fill="white"/>
          </svg>
        </button>
        <button class="default-hover disabled" disabled v-else>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M16.1391 2.96004L7.10914 5.96004C1.03914 7.99004 1.03914 11.3 7.10914 13.32L9.78914 14.21L10.6791 16.89C12.6991 22.96 16.0191 22.96 18.0391 16.89L21.0491 7.87004C22.3891 3.82004 20.1891 1.61004 16.1391 2.96004ZM16.4591 8.34004L12.6591 12.16C12.5091 12.31 12.3191 12.38 12.1291 12.38C11.9391 12.38 11.7491 12.31 11.5991 12.16C11.3091 11.87 11.3091 11.39 11.5991 11.1L15.3991 7.28004C15.6891 6.99004 16.1691 6.99004 16.4591 7.28004C16.7491 7.57004 16.7491 8.05004 16.4591 8.34004Z" fill="white"/>
          </svg>
        </button>
      </div>
    </div>
    <DividerComponent />
    <button class="close-ticket default-hover" @click="closeTicket" v-if="ticket && ticket.status === 'active'">
      Закрыть тикет
    </button>

  </div>
</template>

<style scoped lang="scss">
.ticket-messages {
  max-width: 1000px;
}
.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {

    font-weight: 500;
    cursor: pointer;
    color: #7C7F8A
  }
}
.messages {
  margin-top: 32px;
  border-radius: 16px;
  border: 1px solid #DEE0E5;
  padding: 16px;

  .messages-top {
    padding-bottom: 16px;
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 768px) {
      flex-wrap: wrap;
    }
    .left {
      flex-grow: 1;
      padding-right: 30px;
      display: flex;
      align-items: center;
      @media (max-width: 768px) {
        padding-right: 0;
        width: 100%;
        margin-bottom: 8px;
      }
      strong {
        color: #2F2B2E;
        display: block;
        margin-right: 10px;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        @media (max-width: 768px) {
          flex-grow: 1;
          font-size: 14px;
        }
      }
      span {
        color: #74CD63;
        display: block;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        @media (max-width: 768px) {
          font-size: 14px;
        }
        &.closed {
          color: $danger
        }
      }
    }
    .right {
      color: #7C7F8A;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 12px */
    }
  }
  .messages-list {
    height: 50vh;
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    .messages-list__body {
      display: grid;
      padding-right: 10px;
      padding-left: 10px;
      grid-gap: 16px;
      .messages-list__body-message {
        max-width: 75%;
        width: fit-content;
        border-radius: 16px;
        background: #F1F3F9;
        padding: 12px;
        color: #2F2B2E;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 14px */
        margin-left: auto;

        .icon {
          position: absolute;
          right: -5px;
          bottom: -2px;
          transform: rotate(180deg) scale(1, -1);
          svg {
            path {
              fill: #F1F3F9;
            }
          }
        }

        position: relative;
        &.admin {
          margin-left: 0;
          margin-right: 0;
          background: $accent;
          color: #fff;
          .icon {
            position: absolute;
            right: unset;
            left: -5px;
            bottom: -2px;
            transform: rotate(372deg);
            svg {
              path {
                fill: $accent;
              }
            }
          }
        }
      }
    }
  }
  .messages-input {
    padding-top: 16px;
    display: flex;
    overflow: hidden;
    grid-gap: 8px;
    input {
      flex-grow: 1;
      overflow: hidden;
      border-radius: 16px;
      background: #F1F3F9;
      padding: 0 16px;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      color: black;
      line-height: normal;
      &::placeholder {
        color: #7C7F8A;
      }
      @media (max-width: 768px) {
        font-size: 14px;
        border-radius: 12px;
      }
    }
    button {
      width: 44px;
      height: 44px;
      border-radius: 16px;
      background: $accent;
      display: flex;
      align-items: center;
      justify-content: center;

    }
  }
}
.close-ticket {
  height: 52px;
  border-radius: 16px;
  background: #F1F3F9;
  color: #2F2B2E;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
  line-height: normal;
  margin-left: auto;
  display: block;
  width: 100%;
}

</style>