<script>
import AddButtonComponent from "@/components/ui/AddButtonComponent.vue";
import TableComponent from "@/components/ui/TableComponent.vue";
import DividerComponent from "@/components/ui/DividerComponent.vue";
import TariffsModal from "@/components/settings/modals/TariffsModal.vue";
import CheckboxComponent from "@/components/ui/CheckboxComponent.vue";

export default {
  data() {
    return {
      editObject: null,
      items: [],
      isInit: false,
      isShowAddModal: false,
      columns: [
        {
          key: 'number',
          label: "Число",
        },
        {
          key: 'period',
          label: "Текст"
        },
        {
          key: 'duration',
          label: "Длительность в часах",
        },
        {
          key: 'cost',
          label: "Стоимость",
          formatter: (item) => Number(item).toLocaleString('ru-RU', {
            currency: "RUB"
          }) + ' ₽'
        },
        {
          key: "discount",
          label: "Скидка",
          formatter: (item) => item ? item + '%' : '-'
        },
        {
          key: "cost_with_discount",
          label: "Цена со скидкой",
          formatter: (item) => item ? Number(item).toLocaleString('ru-RU', {
            currency: "RUB"
          }) + ' ₽' : '-'
        },
        {
          key: 'is_accent',
          label: "Акцент",
          type: 'boolean',
        },
        {
          key: 'buyes_count',
          label: "Покупок",
        },
        {
          key: "actions",
          label: ''
        }
      ],
    }
  },
  methods: {
    async goDelete(item) {
      const confirmed = await this.$root.$confirm("Вы уверены, что хотите удалить тариф?");

      if (!confirmed) return;

      this.$axios.delete('tariffs/' + item.id).then(() => {
        const index = this.items.findIndex(i => i.id == item.id);

        if (index > -1) {
          this.items.splice(index, 1);
        }

        this.$root.$emit('success', {
          title: "Уведомление",
          text: "Тариф успешно удален."
        });
      }).catch(errors => {
        if (Array.isArray(errors)) {
          for (const item in errors) {
            this.$root.$emit('error', {
              title: "Ошибка",
              text: errors[item]
            });
          }
        } else {
          this.$root.$emit('error', {
            title: "Ошибка",
            text: "Возникла ошибка, попробуйте позже."
          });
        }
      })
    },
    updateConfig(data) {
      if (!this.isInit) return;

      this.$axios.post('config', data)
          .then(() => {
            this.$root.$emit('reloadSettings');

            this.$root.$emit('success', {
              title: "Уведомление",
              text: "Успешно обновлено.",
            });
          })
          .catch(() => {
            this.$root.$emit('error', { title: "Ошибка", text: "Unknown error." });
          })
          .finally(() => {
            this.$emit('reload');
          });
    },
    updateItem(item) {
      const id = item.id;

      const index = this.items.findIndex(item => item.id === id);
      console.log('index', index)
      if (index > -1) {
        this.isInit = false;

        const newItems = JSON.parse(JSON.stringify(this.items));
        newItems[index] = item;

        this.$set(this, 'items', newItems);
        setTimeout(() => {
          this.isInit = true;
        }, 1)
      }
    }
  },
  mounted() {
    this.items = this.$root.settings?.tariffs ?? [];
    setTimeout(() => {
      this.isInit = true;
    }, 200)
  },
  name: "SettingsTariffs",
  components: {CheckboxComponent, TariffsModal, DividerComponent, TableComponent, AddButtonComponent}
}
</script>

<template>
  <div class="tariffs">
    <div class="top">
      <h2>Подписки</h2>
      <AddButtonComponent @click="isShowAddModal = true" text="Добавить подписку" />
    </div>

    <DividerComponent />

    <CheckboxComponent @input="updateConfig({activation_fee: $event})" label="Показывать вакансии бесплатно" :checked="$root.settings.activation_fee" />

    <DividerComponent />

    <div class="table">
      <TableComponent :editable="true" @edit="editObject = $event, isShowAddModal = true" @delete="goDelete" :deletable="true" :columns="columns" :items="items" />
    </div>

    <TariffsModal v-if="isShowAddModal" @update="updateItem($event), editObject = null, isShowAddModal = null" @add="items.unshift($event), editObject = null, isShowAddModal = false" :object="editObject" @close="isShowAddModal = false, editObject = null" />
  </div>
</template>

<style scoped lang="scss">

.tariffs {
  width: 100%;
}
.top {
  width: 100%;
  h2 {
    color: var(--Text-Main-Text-Color, #2F2B2E);
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @media (max-width: 768px) {
      font-size: 22px;
    }
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
}

::v-deep {
  .table__body {
    min-width: unset !important;
  }
}

.table {
  width: 100%;
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  ::v-deep {
    .table__body {
      min-width: 800px !important;
    }
  }
}
</style>