<script>
import DividerComponent from "@/components/ui/DividerComponent.vue";

export default {
  data() {
    return {
      isOpenSidebar: false,
    }
  },
  watch: {
    '$route': function () {
      this.isOpenSidebar = false;
    }
  },
  components: {DividerComponent},
  mounted() {
    this.$root.$on('toggleSidebar', () => {
      this.isOpenSidebar = !this.isOpenSidebar;
    })
  }
}
</script>

<template>
  <div class="sidebar" :class="{'open': isOpenSidebar}">
    <a href="" class="logo">
      <img src="@/assets/icons/logo.svg" alt="">
      <span>Админ-панель</span>
    </a>

    <DividerComponent />

    <div class="nav">
      <router-link to="/" :class="{'active': $route.name === 'dashboard'}" class="nav__item">
        <span class="nav__item-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M20.0383 6.82L14.2783 2.79C12.7083 1.69 10.2983 1.75 8.78828 2.92L3.77828 6.83C2.77828 7.61 1.98828 9.21 1.98828 10.47V17.37C1.98828 19.92 4.05828 22 6.60828 22H17.3883C19.9383 22 22.0083 19.93 22.0083 17.38V10.6C22.0083 9.25 21.1383 7.59 20.0383 6.82ZM12.7483 18C12.7483 18.41 12.4083 18.75 11.9983 18.75C11.5883 18.75 11.2483 18.41 11.2483 18V15C11.2483 14.59 11.5883 14.25 11.9983 14.25C12.4083 14.25 12.7483 14.59 12.7483 15V18Z" fill="#7C7F8A"/>
          </svg>
        </span>
        <span class="nav__item-title">
          Главная
        </span>
      </router-link>
      <router-link to="/users" active-class="active" class="nav__item">
        <span class="nav__item-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M17.5291 7.77C17.4591 7.76 17.3891 7.76 17.3191 7.77C15.7691 7.72 14.5391 6.45 14.5391 4.89C14.5391 3.3 15.8291 2 17.4291 2C19.0191 2 20.3191 3.29 20.3191 4.89C20.3091 6.45 19.0791 7.72 17.5291 7.77Z" fill="#B0B4C3"/>
            <path d="M20.7916 14.7C19.6716 15.45 18.1016 15.73 16.6516 15.54C17.0316 14.72 17.2316 13.81 17.2416 12.85C17.2416 11.85 17.0216 10.9 16.6016 10.07C18.0816 9.87 19.6516 10.15 20.7816 10.9C22.3616 11.94 22.3616 13.65 20.7916 14.7Z" fill="#B0B4C3"/>
            <path d="M6.44016 7.77C6.51016 7.76 6.58016 7.76 6.65016 7.77C8.20016 7.72 9.43016 6.45 9.43016 4.89C9.43016 3.29 8.14016 2 6.54016 2C4.95016 2 3.66016 3.29 3.66016 4.89C3.66016 6.45 4.89016 7.72 6.44016 7.77Z" fill="#B0B4C3"/>
            <path d="M6.55109 12.85C6.55109 13.82 6.76109 14.74 7.14109 15.57C5.73109 15.72 4.26109 15.42 3.18109 14.71C1.60109 13.66 1.60109 11.95 3.18109 10.9C4.25109 10.18 5.76109 9.89 7.18109 10.05C6.77109 10.89 6.55109 11.84 6.55109 12.85Z" fill="#B0B4C3"/>
            <path d="M12.1208 15.87C12.0408 15.86 11.9508 15.86 11.8608 15.87C10.0208 15.81 8.55078 14.3 8.55078 12.44C8.56078 10.54 10.0908 9 12.0008 9C13.9008 9 15.4408 10.54 15.4408 12.44C15.4308 14.3 13.9708 15.81 12.1208 15.87Z" fill="#B0B4C3"/>
            <path d="M8.87078 17.94C7.36078 18.95 7.36078 20.61 8.87078 21.61C10.5908 22.76 13.4108 22.76 15.1308 21.61C16.6408 20.6 16.6408 18.94 15.1308 17.94C13.4208 16.79 10.6008 16.79 8.87078 17.94Z" fill="#B0B4C3"/>
          </svg>
        </span>
        <span class="nav__item-title">
          Пользователи
        </span>
      </router-link>

      <router-link to="/companies" active-class="active" class="nav__item">
        <span class="nav__item-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M21.091 6.98C20.241 6.04 18.821 5.57 16.761 5.57H16.521V5.53C16.521 3.85 16.521 1.77 12.761 1.77H11.241C7.48101 1.77 7.48101 3.86 7.48101 5.53V5.58H7.24101C5.17101 5.58 3.76101 6.05 2.91101 6.99C1.92101 8.09 1.95101 9.57 2.05101 10.58L2.06101 10.65L2.13847 11.4633C2.15273 11.6131 2.2334 11.7483 2.35929 11.8307C2.59909 11.9877 3.00044 12.2464 3.24101 12.38C3.38101 12.47 3.53101 12.55 3.68101 12.63C5.39101 13.57 7.27101 14.2 9.18101 14.51C9.27101 15.45 9.68101 16.55 11.871 16.55C14.061 16.55 14.491 15.46 14.561 14.49C16.601 14.16 18.571 13.45 20.351 12.41C20.411 12.38 20.451 12.35 20.501 12.32C20.8977 12.0958 21.3093 11.8195 21.6845 11.5489C21.7975 11.4673 21.8698 11.3413 21.8852 11.2027L21.901 11.06L21.951 10.59C21.961 10.53 21.961 10.48 21.971 10.41C22.051 9.4 22.031 8.02 21.091 6.98ZM13.091 13.83C13.091 14.89 13.091 15.05 11.861 15.05C10.631 15.05 10.631 14.86 10.631 13.84V12.58H13.091V13.83ZM8.91101 5.57V5.53C8.91101 3.83 8.91101 3.2 11.241 3.2H12.761C15.091 3.2 15.091 3.84 15.091 5.53V5.58H8.91101V5.57Z" fill="#B0B4C3"/>
            <path d="M20.8733 13.7342C21.2269 13.5659 21.6342 13.8462 21.5988 14.2362L21.2398 18.19C21.0298 20.19 20.2098 22.23 15.8098 22.23H8.18984C3.78984 22.23 2.96984 20.19 2.75984 18.2L2.41913 14.4522C2.38409 14.0667 2.78205 13.7867 3.13468 13.9463C4.2741 14.4618 6.37724 15.3764 7.67641 15.7167C7.84072 15.7597 7.97361 15.8773 8.04556 16.0312C8.65253 17.3293 9.96896 18.02 11.8698 18.02C13.752 18.02 15.085 17.3027 15.694 16.0014C15.766 15.8474 15.8991 15.7298 16.0635 15.6866C17.443 15.3236 19.6816 14.3012 20.8733 13.7342Z" fill="#B0B4C3"/>
          </svg>
        </span>
        <span class="nav__item-title">
          Компании
        </span>
      </router-link>

      <DividerComponent />

      <router-link to="/vacancies" active-class="active" class="nav__item">
        <span class="nav__item-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.19C2 19.83 4.17 22 7.81 22H16.19C19.83 22 22 19.83 22 16.19V7.81C22 4.17 19.83 2 16.19 2ZM9.97 14.9L7.72 17.15C7.57 17.3 7.38 17.37 7.19 17.37C7 17.37 6.8 17.3 6.66 17.15L5.91 16.4C5.61 16.11 5.61 15.63 5.91 15.34C6.2 15.05 6.67 15.05 6.97 15.34L7.19 15.56L8.91 13.84C9.2 13.55 9.67 13.55 9.97 13.84C10.26 14.13 10.26 14.61 9.97 14.9ZM9.97 7.9L7.72 10.15C7.57 10.3 7.38 10.37 7.19 10.37C7 10.37 6.8 10.3 6.66 10.15L5.91 9.4C5.61 9.11 5.61 8.63 5.91 8.34C6.2 8.05 6.67 8.05 6.97 8.34L7.19 8.56L8.91 6.84C9.2 6.55 9.67 6.55 9.97 6.84C10.26 7.13 10.26 7.61 9.97 7.9ZM17.56 16.62H12.31C11.9 16.62 11.56 16.28 11.56 15.87C11.56 15.46 11.9 15.12 12.31 15.12H17.56C17.98 15.12 18.31 15.46 18.31 15.87C18.31 16.28 17.98 16.62 17.56 16.62ZM17.56 9.62H12.31C11.9 9.62 11.56 9.28 11.56 8.87C11.56 8.46 11.9 8.12 12.31 8.12H17.56C17.98 8.12 18.31 8.46 18.31 8.87C18.31 9.28 17.98 9.62 17.56 9.62Z" fill="#B0B4C3"/>
          </svg>
        </span>
        <span class="nav__item-title">
          Вакансии
        </span>
      </router-link>

      <router-link to="/cities" active-class="active" class="nav__item">
        <span class="nav__item-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M10.6 4.15C10.6 4.34 10.44 4.5 10.25 4.5H9.12C6.96 4.5 5.2 6.26 5.2 8.42V17.65C5.2 17.84 5.04 18 4.85 18H4.15C2.96 18 2 17.04 2 15.85V4.15C2 2.96 2.96 2 4.15 2H8.45C9.64 2 10.6 2.96 10.6 4.15Z" fill="#B0B4C3"/>
            <path d="M21.9984 4.15V15.85C21.9984 17.04 21.0384 18 19.8484 18H19.2184C19.0284 18 18.8684 17.84 18.8684 17.65V8.42C18.8684 6.26 17.1084 4.5 14.9484 4.5H13.7484C13.5584 4.5 13.3984 4.34 13.3984 4.15C13.3984 2.96 14.3584 2 15.5484 2H19.8484C21.0384 2 21.9984 2.96 21.9984 4.15Z" fill="#B0B4C3"/>
            <path d="M14.9492 6H9.11922C7.77922 6 6.69922 7.08 6.69922 8.42V19.58C6.69922 20.92 7.77922 22 9.11922 22H10.7492C11.0292 22 11.2492 21.78 11.2492 21.5V19C11.2492 18.59 11.5892 18.25 11.9992 18.25C12.4092 18.25 12.7492 18.59 12.7492 19V21.5C12.7492 21.78 12.9692 22 13.2492 22H14.9592C16.2892 22 17.3692 20.92 17.3692 19.59V8.42C17.3692 7.08 16.2892 6 14.9492 6ZM13.9992 14.75H9.99922C9.58922 14.75 9.24922 14.41 9.24922 14C9.24922 13.59 9.58922 13.25 9.99922 13.25H13.9992C14.4092 13.25 14.7492 13.59 14.7492 14C14.7492 14.41 14.4092 14.75 13.9992 14.75ZM13.9992 11.75H9.99922C9.58922 11.75 9.24922 11.41 9.24922 11C9.24922 10.59 9.58922 10.25 9.99922 10.25H13.9992C14.4092 10.25 14.7492 10.59 14.7492 11C14.7492 11.41 14.4092 11.75 13.9992 11.75Z" fill="#B0B4C3"/>
          </svg>
        </span>
        <span class="nav__item-title">
          Города
        </span>
      </router-link>

      <DividerComponent />

      <router-link to="/support" active-class="active" class="nav__item">
        <span class="nav__item-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M17 2.42999H7C4 2.42999 2 4.42999 2 7.42999V13.43C2 16.43 4 18.43 7 18.43V20.56C7 21.36 7.89 21.84 8.55 21.39L13 18.43H17C20 18.43 22 16.43 22 13.43V7.42999C22 4.42999 20 2.42999 17 2.42999ZM12 14.6C11.58 14.6 11.25 14.26 11.25 13.85C11.25 13.44 11.58 13.1 12 13.1C12.42 13.1 12.75 13.44 12.75 13.85C12.75 14.26 12.42 14.6 12 14.6ZM13.26 10.45C12.87 10.71 12.75 10.88 12.75 11.16V11.37C12.75 11.78 12.41 12.12 12 12.12C11.59 12.12 11.25 11.78 11.25 11.37V11.16C11.25 9.99999 12.1 9.42999 12.42 9.20999C12.79 8.95999 12.91 8.78999 12.91 8.52999C12.91 8.02999 12.5 7.61999 12 7.61999C11.5 7.61999 11.09 8.02999 11.09 8.52999C11.09 8.93999 10.75 9.27999 10.34 9.27999C9.93 9.27999 9.59 8.93999 9.59 8.52999C9.59 7.19999 10.67 6.11999 12 6.11999C13.33 6.11999 14.41 7.19999 14.41 8.52999C14.41 9.66999 13.57 10.24 13.26 10.45Z" fill="#B0B4C3"/>
          </svg>
        </span>
        <span class="nav__item-title">
          Поддержка
        </span>

        <span class="nav__item-badge" v-if="$root.settings.active_tickets > 0">
          {{ $root.settings.active_tickets }}
        </span>
      </router-link>

      <router-link to="/reviews" active-class="active" class="nav__item">
        <span class="nav__item-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M17.2903 4.14001L17.2203 7.93001C17.2103 8.45001 17.5403 9.14001 17.9603 9.45001L20.4403 11.33C22.0303 12.53 21.7703 14 19.8703 14.6L16.6403 15.61C16.1003 15.78 15.5303 16.37 15.3903 16.92L14.6203 19.86C14.0103 22.18 12.4903 22.41 11.2303 20.37L9.47027 17.52C9.15027 17 8.39027 16.61 7.79027 16.64L4.45027 16.81C2.06027 16.93 1.38027 15.55 2.94027 13.73L4.92027 11.43C5.29027 11 5.46027 10.2 5.29027 9.66001L4.27027 6.42001C3.68027 4.52001 4.74027 3.47001 6.63027 4.09001L9.58027 5.06001C10.0803 5.22001 10.8303 5.11001 11.2503 4.80001L14.3303 2.58001C16.0003 1.39001 17.3303 2.09001 17.2903 4.14001Z" fill="#B0B4C3"/>
            <path d="M21.4403 20.47L18.4103 17.44C18.1203 17.15 17.6403 17.15 17.3503 17.44C17.0603 17.73 17.0603 18.21 17.3503 18.5L20.3803 21.53C20.5303 21.68 20.7203 21.75 20.9103 21.75C21.1003 21.75 21.2903 21.68 21.4403 21.53C21.7303 21.24 21.7303 20.76 21.4403 20.47Z" fill="#B0B4C3"/>
          </svg>
        </span>
        <span class="nav__item-title">
          Отзывы
        </span>
      </router-link>

      <DividerComponent />


      <router-link to="/faq" active-class="active" class="nav__item">
        <span class="nav__item-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M17 22.75H7C6.59 22.75 6.25 22.41 6.25 22C6.25 21.59 6.59 21.25 7 21.25H17C17.41 21.25 17.75 21.59 17.75 22C17.75 22.41 17.41 22.75 17 22.75Z" fill="#B0B4C3"/>
            <path d="M18 2H6C3.79 2 2 3.79 2 6V15C2 17.21 3.79 19 6 19H18C20.21 19 22 17.21 22 15V6C22 3.79 20.21 2 18 2ZM9.99 7.32C10.55 7.32 11 7.77 11 8.32C11 8.87 10.55 9.32 10 9.32C9.45 9.32 9 8.87 9 8.32C9 7.77 9.44 7.32 9.99 7.32ZM6.84 7.32C7.4 7.32 7.85 7.77 7.85 8.32C7.85 8.87 7.4 9.32 6.85 9.32C6.3 9.32 5.85 8.87 5.85 8.32C5.85 7.77 6.28 7.32 6.84 7.32ZM17.26 14.86H6.76C6.34 14.86 6 14.52 6 14.11C6 13.7 6.33 13.36 6.74 13.36H17.27C17.68 13.36 18.02 13.7 18.02 14.11C18.02 14.52 17.68 14.86 17.26 14.86ZM17.26 9.07H13.58C13.17 9.07 12.83 8.73 12.83 8.32C12.83 7.91 13.17 7.57 13.58 7.57H17.26C17.67 7.57 18.01 7.91 18.01 8.32C18.01 8.73 17.68 9.07 17.26 9.07Z" fill="#B0B4C3"/>
          </svg>
        </span>
        <span class="nav__item-title">
          FAQ
        </span>
      </router-link>

      <router-link to="/company-types" active-class="active" class="nav__item">
        <span class="nav__item-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M17 22.75H7C6.59 22.75 6.25 22.41 6.25 22C6.25 21.59 6.59 21.25 7 21.25H17C17.41 21.25 17.75 21.59 17.75 22C17.75 22.41 17.41 22.75 17 22.75Z" fill="#B0B4C3"/>
            <path d="M18 2H6C3.79 2 2 3.79 2 6V15C2 17.21 3.79 19 6 19H18C20.21 19 22 17.21 22 15V6C22 3.79 20.21 2 18 2ZM9.99 7.32C10.55 7.32 11 7.77 11 8.32C11 8.87 10.55 9.32 10 9.32C9.45 9.32 9 8.87 9 8.32C9 7.77 9.44 7.32 9.99 7.32ZM6.84 7.32C7.4 7.32 7.85 7.77 7.85 8.32C7.85 8.87 7.4 9.32 6.85 9.32C6.3 9.32 5.85 8.87 5.85 8.32C5.85 7.77 6.28 7.32 6.84 7.32ZM17.26 14.86H6.76C6.34 14.86 6 14.52 6 14.11C6 13.7 6.33 13.36 6.74 13.36H17.27C17.68 13.36 18.02 13.7 18.02 14.11C18.02 14.52 17.68 14.86 17.26 14.86ZM17.26 9.07H13.58C13.17 9.07 12.83 8.73 12.83 8.32C12.83 7.91 13.17 7.57 13.58 7.57H17.26C17.67 7.57 18.01 7.91 18.01 8.32C18.01 8.73 17.68 9.07 17.26 9.07Z" fill="#B0B4C3"/>
          </svg>
        </span>
        <span class="nav__item-title">
          Типы организаций
        </span>
      </router-link>

      <router-link to="/categories" active-class="active" class="nav__item">
        <span class="nav__item-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M17 22.75H7C6.59 22.75 6.25 22.41 6.25 22C6.25 21.59 6.59 21.25 7 21.25H17C17.41 21.25 17.75 21.59 17.75 22C17.75 22.41 17.41 22.75 17 22.75Z" fill="#B0B4C3"/>
            <path d="M18 2H6C3.79 2 2 3.79 2 6V15C2 17.21 3.79 19 6 19H18C20.21 19 22 17.21 22 15V6C22 3.79 20.21 2 18 2ZM9.99 7.32C10.55 7.32 11 7.77 11 8.32C11 8.87 10.55 9.32 10 9.32C9.45 9.32 9 8.87 9 8.32C9 7.77 9.44 7.32 9.99 7.32ZM6.84 7.32C7.4 7.32 7.85 7.77 7.85 8.32C7.85 8.87 7.4 9.32 6.85 9.32C6.3 9.32 5.85 8.87 5.85 8.32C5.85 7.77 6.28 7.32 6.84 7.32ZM17.26 14.86H6.76C6.34 14.86 6 14.52 6 14.11C6 13.7 6.33 13.36 6.74 13.36H17.27C17.68 13.36 18.02 13.7 18.02 14.11C18.02 14.52 17.68 14.86 17.26 14.86ZM17.26 9.07H13.58C13.17 9.07 12.83 8.73 12.83 8.32C12.83 7.91 13.17 7.57 13.58 7.57H17.26C17.67 7.57 18.01 7.91 18.01 8.32C18.01 8.73 17.68 9.07 17.26 9.07Z" fill="#B0B4C3"/>
          </svg>
        </span>
        <span class="nav__item-title">
          Профессии
        </span>
      </router-link>

      <DividerComponent />

      <router-link to="/seo" active-class="active" class="nav__item">
        <span class="nav__item-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M17 22.75H7C6.59 22.75 6.25 22.41 6.25 22C6.25 21.59 6.59 21.25 7 21.25H17C17.41 21.25 17.75 21.59 17.75 22C17.75 22.41 17.41 22.75 17 22.75Z" fill="#B0B4C3"/>
            <path d="M18 2H6C3.79 2 2 3.79 2 6V15C2 17.21 3.79 19 6 19H18C20.21 19 22 17.21 22 15V6C22 3.79 20.21 2 18 2ZM9.99 7.32C10.55 7.32 11 7.77 11 8.32C11 8.87 10.55 9.32 10 9.32C9.45 9.32 9 8.87 9 8.32C9 7.77 9.44 7.32 9.99 7.32ZM6.84 7.32C7.4 7.32 7.85 7.77 7.85 8.32C7.85 8.87 7.4 9.32 6.85 9.32C6.3 9.32 5.85 8.87 5.85 8.32C5.85 7.77 6.28 7.32 6.84 7.32ZM17.26 14.86H6.76C6.34 14.86 6 14.52 6 14.11C6 13.7 6.33 13.36 6.74 13.36H17.27C17.68 13.36 18.02 13.7 18.02 14.11C18.02 14.52 17.68 14.86 17.26 14.86ZM17.26 9.07H13.58C13.17 9.07 12.83 8.73 12.83 8.32C12.83 7.91 13.17 7.57 13.58 7.57H17.26C17.67 7.57 18.01 7.91 18.01 8.32C18.01 8.73 17.68 9.07 17.26 9.07Z" fill="#B0B4C3"/>
          </svg>
        </span>
        <span class="nav__item-title">
          SEO
        </span>
      </router-link>

      <router-link to="/settings" active-class="active" class="nav__item">
        <span class="nav__item-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M20.1 9.22C18.29 9.22 17.55 7.94 18.45 6.37C18.97 5.46 18.66 4.3 17.75 3.78L16.02 2.79C15.23 2.32 14.21 2.6 13.74 3.39L13.63 3.58C12.73 5.15 11.25 5.15 10.34 3.58L10.23 3.39C9.78 2.6 8.76 2.32 7.97 2.79L6.24 3.78C5.33 4.3 5.02 5.47 5.54 6.38C6.45 7.94 5.71 9.22 3.9 9.22C2.86 9.22 2 10.07 2 11.12V12.88C2 13.92 2.85 14.78 3.9 14.78C5.71 14.78 6.45 16.06 5.54 17.63C5.02 18.54 5.33 19.7 6.24 20.22L7.97 21.21C8.76 21.68 9.78 21.4 10.25 20.61L10.36 20.42C11.26 18.85 12.74 18.85 13.65 20.42L13.76 20.61C14.23 21.4 15.25 21.68 16.04 21.21L17.77 20.22C18.68 19.7 18.99 18.53 18.47 17.63C17.56 16.06 18.3 14.78 20.11 14.78C21.15 14.78 22.01 13.93 22.01 12.88V11.12C22 10.08 21.15 9.22 20.1 9.22ZM12 15.25C10.21 15.25 8.75 13.79 8.75 12C8.75 10.21 10.21 8.75 12 8.75C13.79 8.75 15.25 10.21 15.25 12C15.25 13.79 13.79 15.25 12 15.25Z" fill="#B0B4C3"/>
          </svg>
        </span>
        <span class="nav__item-title">
          Настройки
        </span>
      </router-link>

    </div>
  </div>
</template>

<style scoped lang="scss">
  .sidebar {
    width: 286px;
    min-width: 286px;
    background: #F1F3F9;
    min-height: 100vh;
    padding: 32px;
    @media (max-width: 1024px) {
      transform: translateX(-286px);
      position: fixed;
      left: 0;
      flex-direction: column;
      display: flex;
      background: #fff;
      height: calc(100% - 70px);
      z-index: 9;
      top: 0;
      overflow: hidden;
      padding-bottom: 200px;
      padding-top: 85px !important;
      &::-webkit-scrollbar {
        display: none;
      }
      &.open {
        transform: none;
        box-shadow: 0px 10px 5px 5px rgba(0,0,0, 0.1);
      }
    }
    @media (max-width: 768px) {
      padding: 16px;
    }
    .logo {
      width: 100%;
      display: grid;
      img {
        width: 142px;
      }
      span {
        width: 100%;
        display: block;
        color: $gray;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    .nav {
      @media (max-width: 1024px) {
        flex-grow: 1;
        overflow-y: auto;
        padding-bottom: 200px;
        ::-webkit-scrollbar {
          display: none;
        }
      }
      .nav__item {
        transition: 0.2s;
        display: flex;
        padding: 10px 16px;
        border-radius: 16px;
        align-items: center;
        .nav__item-icon {
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            max-width: 100%;
            max-height: 100%;
            path {
              transition: 0.2s;
            }
          }

          margin-right: 8px;
        }
        .nav__item-title {
          display: flex;
          align-items: center;
          color: $gray;
          transition: 0.2s;
          font-size: 16px;
          font-style: normal;
          flex-grow: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 500;
          line-height: normal;
        }
        .nav__item-badge {
          width: 24px;
          min-width: 24px;
          height: 24px;
          border-radius: 100px 100px 100px 16px;
          background: $danger;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%; /* 12px */
        }
        &:hover, &.active {
          background: $accent;
          .nav__item-icon {
            svg {
              path {
                fill: #fff;
              }
            }
          }
          .nav__item-title {
            color: #fff;
          }
        }
      }
      .nav__item + .nav__item {
        margin-top: 8px;
        @media (max-width: 768px) {
          margin-top: 4px;
        }
      }
    }
  }
</style>