<script>
import H1Component from "@/components/ui/H1Component.vue";
import CheckboxComponent from "@/components/ui/CheckboxComponent.vue";
import DividerComponent from "@/components/ui/DividerComponent.vue";
import InputComponent from "@/components/ui/InputComponent.vue";

export default {
  components: {InputComponent, DividerComponent, CheckboxComponent, H1Component},
  data() {
    return {
      isInit: false,
      password: null,
      is_admin: false,
      user: null,
      roles: [
        {
          name: 'Админ',
          id: 'admin'
        }
      ]
    }
  },
  computed: {
    role() {
      if (!this.user) return '';

      return this.user.role === 'applicant' ? 'Соискатель' : 'Работодатель';
    }
  },
  methods: {
    update(data) {
      if (!this.isInit) return;

      this.$axios.put(`users/${this.$route.params.id}`, data).then(() => {
        this.$root.$emit('success', {
          title: "Уведомление",
          text: "Успешно обновлено"
        });
        this.password = null;
      }).catch(errors => {
        if (Array.isArray(errors)) {
          for (const item in errors) {
            this.$root.$emit('error', {
              title: "Ошибка",
              text: errors[item]
            });
          }
        } else {
          this.$root.$emit('error', {
            title: "Ошибка",
            text: "Возникла ошибка, попробуйте позже."
          });
        }
      })
    },
    fetch() {
      this.$axios.get('users/' + this.$route.params.id).then(response => {
        this.user = response.data.data;
        setTimeout(() => {
          this.isInit = true;
        }, 200)
      })
    }
  },
  mounted() {
    this.fetch();
  }
}
</script>

<template>
  <div class="user-page">
    <H1Component text="Пользователь - Глобальные настройки" />

    <DividerComponent />

    <InputComponent readonly v-model="user.email" label="Email" />
    <DividerComponent />

    <InputComponent readonly v-model="role" label="Роль" />

    <DividerComponent />

    <div v-if="user" class="body">
      <CheckboxComponent @input="user.is_admin = $event, update({
        is_admin: $event
      })" label="Админ" :checked="!!user.is_admin" />

      <DividerComponent />

      <InputComponent v-model="password" label="Новый пароль" placeholder="*******" type="password" />
      <DividerComponent size="16"/>

      <button class="default-hover" :disabled="!password" @click="update({
        password: password,
      })">Обновить пароль</button>

      <DividerComponent />

      <router-link class="default-hover" :to="`/entity/${user.id}`" href="">Перейти к управлению контентом пользователя</router-link>
    </div>
  </div>
</template>

<style scoped lang="scss">
button {

}
a {
  width: 100%;
  padding: 20px;
  background: #F1F3F9;
  font-size: 16px;
  font-weight: 500;
  display: block;
  border-radius: 18px;
  text-align: center;
}
.user-page {
  width: 1000px;
  max-width: 100%;
  button {
    height: 52px;
    background: $accent;
    border-radius: 18px;
    color: #fff;
    font-weight: 500;
    width: 100%;
  }
}
</style>