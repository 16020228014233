<script>
import NotificationItem from "./NotificationItem.vue";
export default {
  data () {
    return {
      notifications: []
    }
  },
  methods: {
    close (id) {
      const index = this.notifications.findIndex(item => item.id === id)

      if (index > -1) {
        this.notifications.splice(index, 1);
      }
    },
    addNewNotification (type, data)
    {
      this.notifications.push({
        id: (Math.random() + 1).toString(36).substring(7),
        text: data.text,
        title: data.title,
        type
      })
    }
  },
  mounted() {
    this.$root.$on('success', (data) => this.addNewNotification('success', data));
    this.$root.$on('error', (data) => this.addNewNotification('error', data));
    this.$root.$on('info', (data) => this.addNewNotification('info', data));
  },
  name: "NotificationsWrapper",
  components: {NotificationItem}
}
</script>

<template>
  <div class="notifications-wrapper">
    <NotificationItem @close="close(notification.id)" v-for="(notification) in notifications" :key="notification.id" :notification="notification" />
  </div>
</template>

<style scoped lang="scss">
  .notifications-wrapper {
    position: fixed;
    top: 50px;
    z-index: 9999999;
    display: flex;
    flex-direction: column;
    align-items: center;
    .notification + .notification {
      margin-top: 10px;
    }
    width: 360px;
    margin: 0 auto;
    left: calc(50% - 180px);
  }
</style>
