<script>
import BooleanBadgeComponent from "@/components/ui/BooleanBadgeComponent.vue";
import EmptyState from "@/components/ui/EmptyState.vue";

export default {
  components: {EmptyState, BooleanBadgeComponent},
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    to: {
      type: Function,
    },
    deletable: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  name: "TableComponent",
}
</script>

<template>
  <div class="table">
    <div class="table__body">
      <div class="table__head">
        <div v-for="(item, i) in columns" :key="i" :style="{'flex': item.width ? item.width : '1'}">{{ item.label }}</div>
      </div>
      <div class="loader" v-if="isLoading"></div>
      <template v-else>
        <EmptyState v-if="!items.length" text="Записей нет" />
        <div class="table__items" v-else>
          <div class="table__item-wrapper" @click="to ? to(item) : ''" :class="{'pointer': !!to}" v-for="(item, i) in items" :key="i">
            <div class="table__item" >
              <div
                  :class="[column.hasOwnProperty('classFormatter') ? column.classFormatter(item[column.key]) : '']"
                  :style="Object.assign({}, {'flex': column.width ? column.width : '1'}, column.styles ?? {})"
                  v-for="(column, c) in columns"
                  :key="c">
                <div class="actions" v-if="column.key === 'actions'">
                  <button @click="$emit('edit', item)" v-if="editable">
                    <img src="@/assets/icons/edit.svg" alt="">
                  </button>
                  <button @click="$emit('delete', item)" v-if="deletable">
                    <img src="@/assets/icons/delete.svg" alt="">
                  </button>

                  <slot name="item_actions" :item="item"></slot>
                </div>
                <template v-else>
                  <template v-if="column?.type === 'boolean'">
                    <BooleanBadgeComponent :value="Boolean(item[column.key])" />
                  </template>
                  <template v-else>
                    <template v-if="column.hasOwnProperty('formatter')">
                      {{ column.formatter(item[column.key]) }}
                    </template>
                    <template v-else>
                      {{ item[column.key] }}
                    </template>
                  </template>
                </template>
              </div>
            </div>

            <div class="table__item-slots">
              <slot name="actions" :item="item"></slot>
            </div>

          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .table {
    .table__body {
      min-width: 1300px;
      overflow-y: auto;
    }
    .table__items {
      .table__item-wrapper {
        border-radius: 16px;
        @media (max-width: 768px) {
          border-radius: 10px;
        }
        &.pointer {
          cursor: pointer;
          transition: 0.2s;
          &:hover {
            opacity: 0.7;
          }
        }
        background: #F1F3F9;
      }
      .table__item {

        &.pointer {
          cursor: pointer;
        }
        padding: 16px 32px;
        display: flex;

        @media (max-width: 768px) {
          padding: 16px 12px;
        }

        div {
          flex: 1;
          color: #7C7F8A;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          text-overflow: ellipsis;
          overflow: hidden;
          line-height: normal;
          @media (max-width: 768px) {
            font-size: 12px;
          }
          &.danger {
            color: $danger;
          }
          &.success {
            color: $success;
          }
        }
        .actions {
          display: flex;
          grid-gap: 12px;
          justify-content: flex-end;
          & > button {
            transition: 0.2s;
            cursor: pointer;
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              max-height: 100%;
              max-width: 100%;
            }
            &:hover {
              opacity: 0.7;
            }
          }
        }
      }
      .table__item-slots {
        width: 100%;
      }
      .table__item-wrapper + .table__item-wrapper {
        margin-top: 8px;
      }
    }
    .table__head {
      padding: 16px 32px;
      display: flex;
      @media (max-width: 768px) {
        padding: 16px 12px;
      }
      div {
        flex: 1;
        color: #B4B8C6;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        text-overflow: ellipsis;
        overflow: hidden;
        line-height: 100%;
        @media (max-width: 768px) {
          font-size: 12px;
        }
      }
    }
  }
</style>