<script>
export default {
  props: {
    additionalLabel: {
      type: String,
    },
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Number || Object,
    }
  },
  data() {
    return {
      selected: null,
      isOpen: false,
    }
  },
  methods: {
    close() {
      this.isOpen = false;
    }
  },
  beforeDestroy() {
    document.removeEventListener('click', this.close)
  },
  mounted() {
    if (this.value) {
      if (typeof this.value === 'object') {
        this.selected = this.value;
      } else {
        this.selected = this.options.find(item => item.id == this.value);
      }
    } else {
      this.selected = this.options[0] ?? null;
    }

    document.addEventListener('click', this.close);
  },
  name: "SelectComponent"
}
</script>

<template>
  <div class="select">
    <div class="select-selected default-hover" @click.prevent.stop="isOpen = !isOpen">
      <div class="select-selected__value">
        <div class="additional" v-if="additionalLabel">
          {{ additionalLabel }}
        </div>
        {{ selected?.name ?? 'Выберите из списка' }}
      </div>
      <div class="select-selected__arrow" :class="{'rotate': isOpen}">
        <img src="@/assets/icons/arrow-down.svg" alt="">
      </div>
    </div>

    <div class="select-dropdown" v-show="isOpen">
      <div class="select-dropdown__items">
        <div class="select-dropdown__item default-hover" @click="selected = item, $emit('select', item)" v-for="(item, i) in options" :key="i">
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .select {
    min-width: 290px;
    position: relative;
    .select-selected {
      display: flex;
      align-items: center;
      border-radius: 16px;
      background: #F1F3F9;
      padding: 0 16px;
      height: 52px;
      cursor: pointer;
      overflow: hidden;
      @media (max-width: 768px) {
        border-radius: 10px;
      }

      .select-selected__value {
        color: $black;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        flex-grow: 1;
        display: flex;
        align-items: center;
        @media (max-width: 768px) {
          font-size: 14px;
        }
        .additional {
          color: #7C7F8A;
          font-size: 16px;
          margin-right: 4px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          @media (max-width: 768px) {
            font-size: 14px;
          }
        }
      }
      .select-selected__arrow {
        margin-left: auto;
        min-width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.2s;
        &.rotate {
          transform: rotate(180deg);
        }
        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
    .select-dropdown {
      position: absolute;
      left: 0;
      top: calc(100% + 8px);
      width: 100%;
      border-radius: 16px;
      background: #F1F3F9;
      padding: 10px 12px;
      .select-dropdown__items {
        max-height: 20vh;
        overflow-y: auto;
        &::-webkit-scrollbar {
          display: none;
        }
        .select-dropdown__item {
          width: 100%;
          padding: 10px 16px;
          color: $black;
          font-size: 14px;
          font-style: normal;
          cursor: pointer;
          font-weight: 500;
          line-height: normal;
          background: #fff;
          border-radius: 6px;
          @media (max-width: 768px) {
            font-size: 12px;
          }
        }
        .select-dropdown__item + .select-dropdown__item {
          margin-top: 6px;
        }
      }
    }
  }
</style>