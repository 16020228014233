<script>
import H1Component from "@/components/ui/H1Component.vue";
import DividerComponent from "@/components/ui/DividerComponent.vue";
import StatisticBlockComponent from "@/components/global/StatisticBlockComponent.vue";
import InputComponent from "@/components/ui/InputComponent.vue";
import SelectComponent from "@/components/ui/SelectComponent.vue";
import TableComponent from "@/components/ui/TableComponent.vue";
import PaginationComponent from "@/components/ui/PaginationComponent.vue";
import AddButtonComponent from "@/components/ui/AddButtonComponent.vue";
import BaseModal from "@/components/ui/BaseModal.vue";
import AddOrEditCityModal from "@/components/city/modals/AddOrEditCityModal.vue";
import DeleteCityModal from "@/components/city/modals/DeleteCityModal.vue";

export default {
  data() {
    return {
      isShowModal: false,
      isLoading: true,
      columns: [
        {
          label: 'Наименование',
          key: 'name',
        },
        {
          label: 'Slug',
          key: 'slug',
        },
        {
          label: 'Регион',
          key: 'region',
        },
        {
          label: 'Таймзона',
          key: 'tzid'
        },
        {
          label: 'Координаты',
          key: 'coordinates'
        },
        {
          label: 'Население',
          key: 'population'
        },
        {
          label: 'Вакансий',
          key: 'vacancies'
        },
        {
          label: 'Дата создания',
          key: 'created_date'
        },
        {
          label: '',
          key: 'actions'
        },
      ],
      vacancies: [],
      page: 1,
      pages: 1,
      sort: {
        id: 'latest',
        name: 'Новым'
      },
      search: null,

      statistics: {
        total: {
          value: 0,
        },
        average: {
          value: 0,
        }
      },

      deleteCityObject: null,
    }
  },
  watch: {
    search() {
      this.page = 1;
      this.pages = 1;
      this.fetch()
    },
    sort() {
      this.page = 1;
      this.pages = 1;
      this.fetch()
    },
  },
  methods: {
    deleteCity(object) {
      this.deleteCityObject = object;
    },
    setSort(sort) {
      this.sort = sort;
      this.fetch();
    },
    setPage(page) {
      this.page = page;
      this.fetch();
    },
    fetchStatistics() {
      this.$axios.get('cities/statistics').then(response => {
        this.statistics = response.data;
      })
    },
    fetch() {
      this.$axios.get('cities', {
        params: {
          page: this.page,
          per_page: 50,
          search: this.search,
          sort: this.sort.id,
        }
      }).then(response => {
        this.vacancies = response.data.data;
        this.pages = response.data.meta.last_page;
        this.page = response.data.meta.current_page;
      }).finally(() => {
        setTimeout(() => {
          this.isLoading = false;
        }, 100)
      })
    }
  },
  mounted() {
    this.fetchStatistics();
    this.fetch();
  },
  components: {
    DeleteCityModal,
    AddOrEditCityModal,
    BaseModal,
    AddButtonComponent,
    PaginationComponent,
    TableComponent, SelectComponent, InputComponent, StatisticBlockComponent, DividerComponent, H1Component
  }
}
</script>

<template>
  <div class="page users">
    <H1Component text="Города" />

    <DividerComponent />

    <div class="grid">
      <StatisticBlockComponent
          :icon="require('@/assets/icons/stat-vacancies.svg')"
          title="Всего городов"
          :value="statistics.total.value"
      />

      <StatisticBlockComponent
          :icon="require('@/assets/icons/stat-companies.svg')"
          title="В среднем объялений в городе"
          :value="statistics.average.value"
      />
    </div>

    <DividerComponent />

    <div class="filters">
      <InputComponent
          v-model="search"
          placeholder="Поиск по названию"
          :icon="require('@/assets/icons/input-default.svg')"
      />

      <SelectComponent
          additionalLabel="Сортировать по:"
          :value="sort"
          @select="setSort"
          :options="[
              {
                id: 'latest',
                name: 'Новым'
              },
              {
                id: 'oldest',
                name: 'Старым',
              },
          ]"
      />

      <div class="buttons">
        <AddButtonComponent @click="isShowModal = true" text="Добавить город" />
      </div>
    </div>

    <DividerComponent />

    <div class="tbl">
      <TableComponent
          :columns="columns"
          :items="vacancies"
          :is-loading="isLoading"
          :deletable="true"
          @delete="deleteCity"
      />
    </div>

    <DividerComponent />

    <PaginationComponent @change="setPage" :pages="pages" :page="page" />

    <BaseModal v-if="isShowModal" @close="isShowModal = false">
      <AddOrEditCityModal @close="isShowModal = false" @add="setPage(1)" />
    </BaseModal>

    <BaseModal v-if="deleteCityObject" @close="deleteCityObject = null">
      <DeleteCityModal :item="deleteCityObject" @close="deleteCityObject = null" @deleted="deleteCityObject = null, setPage(1)" />
    </BaseModal>
  </div>
</template>

<style scoped lang="scss">
.tbl {
  width: 100%;
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  ::v-deep {
    .table__body {
      min-width: 900px;
    }
  }
}
.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 8px;
  }
}
.filters {
  display: flex;
  grid-gap: 16px;
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 8px;
    display: grid;
  }
  .input {
    width: 370px;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  .buttons {
    margin-left: auto;
    @media (max-width: 768px) {
      margin-left: unset;
      button {
        width: 100% !important;
        margin-left: unset;
      }
    }
  }
}
</style>