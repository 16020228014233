<script>
export default {
  props: ['percentage']
}
</script>

<template>
  <div class="progress">
    <div class="progress-left" :class="{'full': percentage > 99}">
      <div v-if="percentage < 100" class="circle"></div>
      <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M20 6L9 17L4 12" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
    <div class="progress-bar">
      <div class="progress-bar-text">
        <strong>{{ percentage }}%</strong>
        <span v-if="percentage < 100">В процессе заполнения</span>
        <span v-else>Заполнено</span>
      </div>
      <div class="progress-bar-line">
        <div class="progress-bar-line-fill" :class="{'full': percentage > 99}" :style="{'width': `${percentage}%`}"></div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .progress {
    display: flex;
    align-items: center;
    .progress-left {
      width: 34px;
      margin-right: 8px;
      display: flex;
      align-items: center;
      justify-content: center;

      .circle {
        width: 5px;
        height: 5px;
        border-radius: 100%;
        background: $accent;
      }
      height: 34px;
      border-radius: 100%;
      border: 1px solid $accent;
      &.full {
        background: #74CD63;
        border-color: #74CD63;
        .circle {
          display: none;
        }
      }
    }
    .progress-bar {
      .progress-bar-text {
        display: flex;
        align-items: center;
        strong {
          color: #2F2B2E;
          margin-right: 8px;
          display: block;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        span {
          color: #7C7F8A;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%; /* 14px */
        }
      }
      .progress-bar-line {
        margin-top: 4px;
        width: 286px;
        overflow: hidden;
        height: 8px;
        border-radius: 64px;
        background: #F1F3F9;
        .progress-bar-line-fill {
          height: 100%;
          background: $accent;
          border-top-right-radius: 64px;
          border-bottom-right-radius: 64px;
          &.full {
            background: #74CD63;
          }
        }

      }
    }
  }
</style>