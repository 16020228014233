<script>
import VacancyAppliesList from "@/components/vacancy/VacancyAppliesList.vue";
import PromotionModal from "@/components/vacancy/PromotionModal.vue";
export default {
  data() {
    return {
      isShowPromotionModal: false,
      vacancy: null,
    }
  },
  components: {VacancyAppliesList, PromotionModal},
  methods: {
    fetch() {
      this.$axios.get('vacancy/' + this.$route.params.id).then(response => {
        this.vacancy = response.data;
      })
    }
  },
  mounted() {
    this.fetch();
  }
}
</script>

<template>
  <div class="view-vacancy">
    <div class="dashboard-container">
      <div class="body">
        <div class="top" v-if="vacancy">
          <h2><em>{{ vacancy.name }}</em></h2>
          <span class="default-hover" @click="$router.back()">
            Назад
          </span>
        </div>

        <div class="badges">
          <div class="vip-badge" v-if="!!vacancy.active_top">
            <div class="dabg">
              Поднятие в топ
            </div>
            <span>до {{ vacancy.active_top.to_date }}</span>
          </div>

          <div class="vip-badge" v-if="!!vacancy.active_vip">
            <div class="dabg">
              VIP
            </div>
            <span>до {{ vacancy.active_top.to_date }}</span>
          </div>
        </div>

        <div class="vacancy-bottom">
          <button class="edit default-hover" @click="$router.push(`/vacancies/${vacancy.id}/edit`)">
            <img src="@/assets/icons/edit.svg" alt="">
            Редактировать
          </button>
          <button @click="isShowPromotionModal = true" class="upgrade default-hover">
            Продвижение
          </button>
        </div>

        <div class="applies">
          <div class="title">
            Отклики
          </div>
          <VacancyAppliesList v-if="$route.params?.id" :id="$route.params.id" />
        </div>
      </div>
    </div>

    <PromotionModal @close="isShowPromotionModal = false, fetch()" :ids="[vacancy.id]" :has_vip="!!vacancy.active_vip" v-if="isShowPromotionModal"></PromotionModal>
  </div>
</template>
<style scoped lang="scss">
.view-vacancy {
  width: 1000px;
  max-width: 100%;
}
.view-vacancy {
  .badges {
    display: grid;
    grid-gap: 8px;
    margin-top: 32px;
    @media (max-width: 768px) {
      margin-top: 14px;
    }
    .vip-badge {
      border-radius: 8px;
      width: fit-content;
      border: 1px solid $accent;
      display: flex;
      .dabg {
        padding: 4.5px 12px;
        border-radius: 6px;
        background: $accent;
        color: #FFF;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        @media (max-width: 768px) {
          font-size: 12px;
        }
      }
      span {
        padding: 0 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ADADAD;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        @media (max-width: 768px) {
          font-size: 12px;
        }
      }
    }
  }

  .body {
    .vacancy-bottom {
      display: flex;
      margin-top: 24px;
      grid-gap: 16px;
      @media (max-width: 768px) {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 8px;
      }
      button {
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 16px;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        img {
          margin-right: 8px;
          width: 24px;
        }
      }
      .edit {
        border: 1px solid #8C12D6;
        flex: 1;
        color: #2F2B2E;

      }
      .upgrade {
        background: $accent;
        color: #fff;
        flex: 2;
      }
    }
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h2 {
        color: #2F2B2E;
        font-size: 28px;
        padding-right: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        em {
          font-style: normal;
          color: $accent;
          font-size: 32px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
      span {
        color: #B4B8C6;
        cursor: pointer;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 14px */
        &:hover {
          color: $accent;
        }
      }
    }
    .applies {
      margin-top: 32px;
      .title {
        color: #2F2B2E;
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 32px;
      }
    }
  }
}
</style>