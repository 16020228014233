<script>
export default {
  data() {
    return {
      isOpen: false,
    }
  },
  props: {
    text: {
      type: String,
      default: "Toggler 1"
    }
  },
  name: "UiToggle"
}
</script>

<template>
  <div class="toggler" :class="{'open': isOpen}">
    <div class="toggler-top" @click="isOpen = !isOpen" :class="{'open': isOpen}">
      {{ text }}

      <div :class="{'rotate': isOpen}" class="arrow">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M17 10L12 15L7 10" stroke="#7C7F8A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    </div>
    <div class="toggler-content" v-if="isOpen">
      <slot></slot>
    </div>
  </div>
</template>

<style scoped lang="scss">
.toggler {
  border: 1px solid transparent;
  &.open {
    border-radius: 16px;
    border: 1px solid var(--BG-In-Islands, #DEE0E5);
    background: var(--BG-Canvas, #FFF);
  }
  .toggler-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 16px;
    background: #F1F3F9;
    padding: 14px 32px;
    cursor: pointer;
    color: #2F2B2E;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    .arrow {
      display: flex;
      align-items: center;
      transition: 0.2s;
      &.rotate {
        transform: rotate(180deg);
      }
    }
    &.open {
      background: transparent;
    }
  }
  .toggler-content {
    padding: 16px;
    padding-bottom: 16px;
  }
}
</style>