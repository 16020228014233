<script>
import TableComponent from "@/components/ui/TableComponent.vue";
import PaginationComponent from "@/components/ui/PaginationComponent.vue";
import DividerComponent from "@/components/ui/DividerComponent.vue";

export default {
  components: {DividerComponent, PaginationComponent, TableComponent},
  props: {
    company: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      columns: [
        {
          label: 'Id',
          key: 'id'
        },
        {
          label: 'Тип покупки',
          key: 'type',
          formatter: (item) => {
            if (item === 'buy_top') {
              return 'Покупка поднятия вакансии'
            } else if (item === 'buy_vip') {
              return 'Покупка выделения цветом'
            } else if (item === 'buy_activate') {
              return 'Покупка подписки'
            }

            return 'Другое';
          },
          styles: {
            color: '#8C12D6'
          }
        },
        {
          label: "Сумма",
          key: "amount",
          formatter: (item) => item + ' ₽'
        },
        {
          label: "Дата",
          key: "created_at",
          formatter: (value) => (new Date(value * 1000)).toLocaleString('ru-RU', { timeZone: 'Europe/Samara' }),
        },
      ],
      page: 1,
      pages: 1,
      transactions: [],
    }
  },
  methods: {
    setPage(page) {
      this.page = page;
      this.fetch();
    },
    fetch() {
      this.$axios.get(`manager/${this.company.id}/transactions`).then(response => {
        this.transactions = response.data.data;
        this.pages = response.data.last_page;
        this.page = response.data.current_page;
      })
    }
  },
  mounted() {
    this.fetch();
  }
}
</script>

<template>
  <div class="company-buyes">
    <div class="tbl">
      <TableComponent
          :columns="columns"
          :items="transactions"
      />
    </div>

    <DividerComponent />

    <PaginationComponent @change="setPage" :pages="pages" :page="page" />
  </div>
</template>

<style scoped lang="scss">
  .company-buyes {

  }
  .tbl {
    width: 100%;
    overflow-y: auto;
    ::-webkit-scrollbar {
      display: none;
    }
    ::v-deep {
      .table__body {
        min-width: 600px;
      }
    }
  }
</style>