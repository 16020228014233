<script>
export default {
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: ''
    },
    pattern: {
      type: String,
    },
    icon: {
      type: String,
    },
    label: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "Введите что-то"
    },
    type: {
      type: String,
      default: 'text'
    }
  },
  data() {
    return {
      id: Math.floor(Math.random() * 100),
    }
  },
  methods: {
    onInput(event) {
      this.$emit('input', event.target.value);
    },
  },
  name: "InputComponent"
}
</script>

<template>
  <div class="input">
    <label :for="`input-${id}`" v-if="label">{{ label }}</label>
    <div class="input__body">
      <div v-if="icon" class="input-icon">
        <img :src="icon" alt="">
      </div>

      <div class="input-field">
        <input :readonly="readonly" v-if="pattern" :pattern="pattern" :required="required" :id="`input-${id}`" :value="value" @input="onInput" @change="$emit('change', $event.target.value)" :type="type" :placeholder="placeholder">
        <input :readonly="readonly" v-else :required="required" :id="`input-${id}`" :value="value" @input="onInput" @change="$emit('change', $event.target.value)" :type="type" :placeholder="placeholder">
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .input {
    overflow: hidden;
    width: 100%;
    label {
      display: block;
      margin: 4px;
      color: #2F2B2E;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      @media (max-width: 768px) {
        font-size: 14px;
      }
    }
    .input__body {
      border-radius: 16px;
      height: 52px;
      background: #F1F3F9;
      padding: 0 16px;
      display: flex;
      align-items: center;
      @media (max-width: 768px) {
        border-radius: 10px;
      }
    }
    .input-icon {
      width: 24px;
      height: 24px;
      min-width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-width: 100%;
        max-height: 100%;
      }

      margin-right: 8px;
    }

    .input-field {
      overflow: hidden;
      flex-grow: 1;
      display: flex;
      input {
        height: 52px;
        flex-grow: 1;
        color: $black;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        @media (max-width: 768px) {
          font-size: 14px;
        }
        line-height: normal;
        &[readonly="readonly"] {
          cursor: default;
        }
        &::placeholder {
          color: #7C7F8A;
        }
      }
    }
  }
</style>