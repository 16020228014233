<script>

import {defineComponent} from "vue";
import InputComponent from "@/components/ui/InputComponent.vue";
import DividerComponent from "@/components/ui/DividerComponent.vue";
import H1Component from "@/components/ui/H1Component.vue";

export default defineComponent({
  data() {
    return {
      email: null,
      password: null,
    }
  },
  methods: {
    login() {
      this.$axios.post('login', {
        email: this.email,
        password: this.password,
      }).then((response) => {
        localStorage.setItem('token', response.data.access_token);

        this.$router.push('/');
        window.location.reload();
      }).catch(errors => {
        if (Array.isArray(errors)) {
          for (const item in errors) {
            this.$root.$emit('error', {
              title: "Ошибка",
              text: errors[item]
            });
          }
        } else {
          this.$root.$emit('error', {
            title: "Ошибка",
            text: "Возникла ошибка, попробуйте позже."
          });
        }
      })
    }
  },
  components: {H1Component, DividerComponent, InputComponent}
})
</script>

<template>

  <div class="login">
    <form @submit.prevent.stop="login">
      <H1Component text="Вход" />
      <DividerComponent />
      <InputComponent v-model="email" type="email" label="Email" placeholder="Email" :required="true" />
      <DividerComponent />

      <InputComponent v-model="password" type="password" label="Password" placeholder="*****" :required="true" />

      <DividerComponent />

      <button class="default-hover">Войти</button>
    </form>
  </div>

</template>

<style scoped lang="scss">
  .login {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20vh;
    form {
      display: block;
      width: 700px;

      button {
        width: 100%;
        font-size: 16px;
        height: 52px;
        background: $accent;
        border-radius: 18px;
        color: #fff;
        font-weight: 500;
      }
    }
  }
</style>