<script>
import BaseModal from "@/components/ui/BaseModal.vue";
import DividerComponent from "@/components/ui/DividerComponent.vue";
import InputComponent from "@/components/ui/InputComponent.vue";

export default {
  props: {
    object: {
      type: Object,
    }
  },
  data() {
    return {
      name: null,
      title: null,
      description: null,
      keywords: null,
      pattern: null,
    }
  },
  methods: {
    create() {
      let request;

      const data = {
        name: this.name,
        title: this.title,
        description: this.description,
        pattern: this.pattern,
        keywords: this.keywords,
      };

      if (!this.object) {
        request = this.$axios.post('seo-filters/create', data);
      } else {
        request = this.$axios.put('seo-filters/' + this.object.id, data);
      }

      request.then(() => {
        this.$root.$emit('success', {
          title: "Уведомление",
          text: "SEO-фильтр успешно " + (this.object ? 'отредактирован' : 'создан')
        });
        this.$emit('reload');
      }).catch(errors => {
        if (Array.isArray(errors)) {
          for (const item in errors) {
            this.$root.$emit('error', {
              title: "Ошибка",
              text: errors[item]
            });
          }
        } else {
          this.$root.$emit('error', {
            title: "Ошибка",
            text: "Возникла ошибка, попробуйте позже."
          });
        }
      })
    },
  },
  mounted() {
    if (this.object) {
      this.name = this.object.name;
      this.title = this.object.title;
      this.description = this.object.description;
      this.pattern = this.object.pattern;
      this.keywords = this.object.keywords;
    }
  },
  name: "SeoFilterModal",
  components: {InputComponent, DividerComponent, BaseModal}
}
</script>

<template>
  <BaseModal @close="$emit('close')">
    <form @submit.prevent.stop="create" class="seo-template">
      <div class="modal-title">
        <template v-if="!object">
          Создание
        </template>
        <template v-else>Редактирование</template>
        SEO-фильтра
      </div>

      <DividerComponent />

      <span class="note">Скопируйте часть ссылки после знака "?": <br> например: ?cities=2330&payouts=everyday</span>

      <InputComponent v-model="pattern" :required="true" placeholder="Pattern" label="Pattern" />
      <DividerComponent size="16" />
      <InputComponent v-model="name" :required="true" placeholder="Название" label="Название" />
      <DividerComponent size="16" />
      <InputComponent v-model="title" :required="true" placeholder="Title" label="Title" />
      <DividerComponent size="16" />
      <InputComponent v-model="description" placeholder="Description" label="Description" />
      <DividerComponent size="16" />
      <InputComponent v-model="keywords" placeholder="Keywords" label="Keywords" />
      <DividerComponent />

      <button class="default-hover">Добавить</button>
    </form>
  </BaseModal>
</template>

<style scoped lang="scss">
.seo-template {
  width: 500px;
  max-width: 100%;
  .note {
    padding: 10px;
    border-radius: 12px;
    display: block;
    color: #fff;
    margin-bottom: 8px;
    background: #74CD63;
  }
  button {
    width: 100%;
    height: 52px;
    border-radius: 16px;
    color: #fff;
    background: $accent;
  }
  h4 {
    color: #2F2B2E;
    margin-bottom: 4px;
    display: block;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .vars {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 4px;
    .var {
      border-radius: 100px;
      background: rgba(133, 17, 204, 0.20);
      padding: 4px 8px;
      color: #8511CC;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 12px */
    }
  }
}
</style>