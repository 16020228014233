var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page users"},[_c('H1Component',{attrs:{"text":"Отзывы"}}),_c('DividerComponent'),_c('div',{staticClass:"filters"},[_c('InputComponent',{attrs:{"placeholder":"Поиск по тексту отзыва","icon":require('@/assets/icons/input-default.svg')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('SelectComponent',{attrs:{"additionalLabel":"Сортировать по:","value":_vm.sort,"options":[
            {
              id: 'latest',
              name: 'Новым'
            },
            {
              id: 'oldest',
              name: 'Старым',
            },
        ]},on:{"select":_vm.setSort}})],1),_c('DividerComponent'),_c('div',{staticClass:"tbl"},[_c('TableComponent',{attrs:{"columns":_vm.columns,"items":_vm.reviews,"is-loading":_vm.isLoading},scopedSlots:_vm._u([{key:"actions",fn:function({item}){return [_c('div',{staticClass:"actions"},[(item.status === 'pending')?_c('button',{staticClass:"publish default-hover",on:{"click":function($event){return _vm.publish(item.id)}}},[_vm._v("Опубликовать")]):_vm._e(),(item.status === 'published')?_c('button',{staticClass:"published default-hover",attrs:{"disabled":""}},[_vm._v("Опубликован")]):_vm._e(),_c('button',{staticClass:"delete default-hover",on:{"click":function($event){return _vm.goDelete(item.id)}}},[_vm._v("Удалить")])])]}}])})],1),_c('DividerComponent'),_c('PaginationComponent',{attrs:{"pages":_vm.pages,"page":_vm.page},on:{"change":_vm.setPage}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }