<script>
export default {
  props: {
    label: {
      type: String
    },
    height: {
      type: Number,
      default: 100,
    },
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    autofocus: Boolean,
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isFocus: false,
      id: Math.floor(Math.random() * 100),
    }
  },
  name: "TextareaComponent",
  methods: {
    onInput(event) {
      this.$emit('input', event.target.value);
    },
  },
}
</script>

<template>
  <div class="input">
    <label :for="`input-${id}`" v-if="label">{{ label }}</label>
    <div class="ui-input" :class="{'focus': isFocus}">
      <textarea
          :style="{'height': `${height}px`}"
          @focusin="isFocus = true"
          @focusout="isFocus = false"
          :required="required"
          :disabled="disabled"
          :ref="`input-${id}`"
          :id="`input-${id}`"
          :autofocus="autofocus"
          :value="value"
          @input="onInput"
          @change="$emit('change', $event.target.value)"
          type="text"
          :placeholder="placeholder"></textarea>
    </div>
  </div>
</template>

<style scoped lang="scss">
.input {
  label {
    display: block;
    color: #2F2B2E;
    margin-bottom: 4px;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }

  .ui-input {
    display: flex;
    align-items: center;
    border-radius: 16px;
    background: #F1F3F9;
    border: 2px solid transparent;
    transition: 0.2s;
    position: relative;
    &.focus {
      border-color: $accent;
    }
    &.withButton {
      padding-right: 0;
    }
    button {
      height: 100%;
      padding: 0 32px;
      border-radius: 16px;
      border: 2px solid transparent;
      transition: 0.2s;
      margin-right: -2px;
    }
    &.focus {
      button {
        border-color: $accent
      }
    }
    .right-icon {
      position: absolute;
      right: 16px;
      display: flex;
      align-items: center;
      top: 0;
      height: 100%;
    }
    .icon {
      margin-right: 8px;
      width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
      }
    }
    textarea {
      flex-grow: 1;
      overflow: hidden;
      max-width: 100%;
      color: black;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      border-radius: 16px;
      padding: 12px 16px;
      line-height: normal;
      outline: none;
      border: none;
      @media (max-width: 768px) {
        padding: 12px;
        font-size: 14px;
      }
      &::placeholder {
        color: #7C7F8A;
        font-weight: 400;
        font-size: 16px;
        @media (max-width: 768px) {
          font-size: 14px;
        }
      }
    }
  }
}
</style>