<script>

import {defineComponent} from "vue";
import TableComponent from "@/components/ui/TableComponent.vue";
import SeoFilterModal from "@/components/seo/modals/SeoFilterModal.vue";
export default defineComponent({

  data() {
    return {
      editObject: null,
      isShowCreateModal: false,
      columns: [
        {
          key: 'name',
          label: 'Название'
        },
        {
          key: 'pattern',
          label: 'Паттерн',
          width: 2,
        },
        {
          key: 'title',
          label: 'Title'
        },
        {
          label: 'Description',
          key: 'description'
        },
        {
          label: 'Keywords',
          key: 'keywords',
        },
        {
          label: '',
          key: 'actions'
        }
      ],
      templates: [],
    }
  },
  methods: {
    async goDelete(item) {
      const confirmed = await this.$root.$confirm("Вы уверены, что хотите удалить SEO-фильтр?");

      if (!confirmed) return;

      this.$axios.delete('seo-filters/' + item.id).then(() => {
        const index = this.templates.findIndex(i => i.id == item.id);

        if (index > -1) {
          this.templates.splice(index, 1);
        }

        this.$root.$emit('success', {
          title: "Уведомление",
          text: "SEO фильтр успешно удален."
        });
      }).catch(errors => {
        if (Array.isArray(errors)) {
          for (const item in errors) {
            this.$root.$emit('error', {
              title: "Ошибка",
              text: errors[item]
            });
          }
        } else {
          this.$root.$emit('error', {
            title: "Ошибка",
            text: "Возникла ошибка, попробуйте позже."
          });
        }
      })
    },
    fetch() {
      this.$axios.get('seo-filters').then(response => {
        this.templates = response.data;
      })
    }
  },
  mounted() {
    this.fetch();
  },
  components: {SeoFilterModal, TableComponent}
})
</script>

<template>
  <div class="seo-filters">
    <div class="block">
      <h3>SEO Фильтры</h3>

      <button class="default-hover" @click="isShowCreateModal = true">Создать</button>
    </div>

   <div class="tbl">
     <TableComponent
         :columns="columns"
         :items="templates"
         :editable="true"
         :deletable="true"
         @edit="editObject = $event, isShowCreateModal = true"
         @delete="goDelete"
     />
   </div>

    <SeoFilterModal :object="editObject" @reload="isShowCreateModal = false, editObject = null, fetch()" @close="isShowCreateModal = false, editObject = null" v-if="isShowCreateModal" />
  </div>
</template>

<style scoped lang="scss">
.tbl {
  width: 100%;
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  ::v-deep {
    .table__body {
      min-width: 800px;
    }
  }
}
.block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  h3 {
    color: #2F2B2E;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  button {
    height: 44px;
    width: 130px;
    border-radius: 18px;
    background: $accent;
    color: #fff;
  }

  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 8px;
    h3 {
      font-size: 22px;
    }
    button {
      width: 100%;
      border-radius: 10px;
    }
  }
}
</style>