<script>
import {defineComponent} from "vue";
import BaseModal from "@/components/ui/BaseModal.vue";
import DividerComponent from "@/components/ui/DividerComponent.vue";
import InputComponent from "@/components/ui/InputComponent.vue";

export default defineComponent({
  data() {
    return {
      balance: 100,
    }
  },
  props: {
    userId: {
      type: Number,
      required: true,
    }
  },
  methods: {
    go() {
      if (!this.balance) return;

      this.$axios.post('/manager/' + this.userId + '/balance', {
        balance: this.balance,
      }).then(() => {
        this.$root.$emit('success', {
          title: "Уведомление",
          text: "Баланс был начислен."
        });
        this.$emit('update');
      }).catch(errors => {
        if (Array.isArray(errors)) {
          for (const item in errors) {
            this.$root.$emit('error', {
              title: "Ошибка",
              text: errors[item]
            });
          }
        } else {
          this.$root.$emit('error', {
            title: "Ошибка",
            text: "Возникла ошибка, попробуйте позже."
          });
        }
      })
    }
  },
  components: {InputComponent, DividerComponent, BaseModal}
})
</script>

<template>
  <BaseModal @close="$emit('close')">
    <div class="balance">
      <div class="modal-title">
        Начисление баланса
      </div>
      <DividerComponent />

      <InputComponent type="number" label="Сумма" placeholder="Введите сумму начисления" v-model="balance" />

      <DividerComponent />

      <button class="default-hover" @click="go" :disabled="!balance">Начислить</button>

    </div>
  </BaseModal>
</template>

<style scoped lang="scss">
.balance {
  width: 500px;
  max-width: 100%;
  button {
    width: 100%;
    height: 52px;
    background: $accent;
    border-radius: 16px;
    color: #fff;
  }
}
</style>