import { render, staticRenderFns } from "./CompanyReviews.vue?vue&type=template&id=29ebd2ab&scoped=true"
import script from "./CompanyReviews.vue?vue&type=script&lang=js"
export * from "./CompanyReviews.vue?vue&type=script&lang=js"
import style0 from "./CompanyReviews.vue?vue&type=style&index=0&id=29ebd2ab&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29ebd2ab",
  null
  
)

export default component.exports