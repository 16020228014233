<script>
export default {
  props: {
    icon: {
      type: String,
    },
    title: {
      type: String,
    },
    value: {
      type: String,
      default: "Value",
    },
    stat: {
      type: String,
    },
    statColor: {
      type: String,
      default: 'success',
    }
  },
  name: "StatisticBlockComponent"
}
</script>

<template>
  <div class="statistic-block">
    <div class="statistic-block__img">
      <img v-if="icon" :src="icon" alt="">
    </div>
    <div class="statistic-block__text">
      <div v-if="title" class="statistic-block__title">
        {{ title }}
      </div>
      <div class="statistic-block__value">
        {{ value }}
      </div>

      <div v-if="stat" :class="[statColor]" class="statistic-block__stat success">
        {{ stat }}
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .statistic-block {
    border-radius: 32px;
    background: #F1F3F9;
    padding: 32px;
    display: flex;
    overflow: hidden;
    align-items: center;
    @media (max-width: 768px) {
      padding: 16px;
      border-radius: 12px;
    }
    .statistic-block__img {
      width: 96px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 96px;
      min-width: 96px;
      border-radius: 100%;
      background: rgba(140, 18, 214, 0.20);
      margin-right: 32px;
      @media (max-width: 768px) {
        height: 50px;
        width: 50px;
        min-width: 50px;
        margin-right: 16px;
        img {
          max-width: 50%;
        }
      }
    }
    .statistic-block__text {
      flex-grow: 1;
      .statistic-block__stat {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        @media (max-width: 768px) {
          font-size: 14px;
        }
        &.success {
          color: $success;
        }
        &.danger {
          color: $danger;
        }
      }
      .statistic-block__title {
        color: $gray;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        @media (max-width: 768px) {
          font-size: 14px;
        }
      }
      .statistic-block__value {
        margin: 4px 0;
        color: #2F2B2E;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        @media (max-width: 768px) {
          font-size: 24px;
        }
      }
    }
  }
</style>