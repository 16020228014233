<script>
import DividerComponent from "@/components/ui/DividerComponent.vue";
import TableComponent from "@/components/ui/TableComponent.vue";

export default {
  data() {
    return {
      columns: [
        {
          key: 'type',
          label: "Тип"
        },
        {
          key: 'cost',
          label: 'Стоимость'
        },
        {
          key: 'duration',
          label: "Длительность (часы)"
        }
      ],
      items: [
        {
          type: 'Выделение цветом',
          cost: 1190,
          duration: 24,
        },
        {
          type: 'Разовое поднятие',
          cost: 1190,
          duration: 24,
        },
      ]
    }
  },
  name: "SettingsBoost",
  components: {TableComponent, DividerComponent}
}
</script>

<template>
  <div class="settings-boost">
    <div class="top">
      <h2>Продвижение</h2>

      <button class="default-hover" @click="$router.push(`/settings/boost`)">Редактировать</button>
    </div>

    <DividerComponent />

    <div class="tbl">
      <TableComponent :columns="columns" :items="items" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.tbl {
  width: 100%;
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  ::v-deep {
    .table__body {
      min-width: 500px !important;
    }
  }
}
.settings-boost {
  max-width: 100%;
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h2 {
      color: var(--Text-Main-Text-Color, #2F2B2E);
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      @media (max-width: 768px) {
        font-size: 20px;
      }
    }
    button {
      height: 52px;
      width: 100%;
      border-radius: 16px;
      width: 200px;
      background: #F1F3F9;
      font-size: 16px;
      font-weight: 500;
      @media (max-width: 768px) {
        font-size: 14px;
        height: 44px;
      }
    }
  }
}
</style>